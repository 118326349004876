<template>
  <div id="app-container" :class="getMenuType">
    <top-nav />
    <sidebar />
    <main>
      <div class="container-fluid">
        <router-view />
      </div>
    </main>
  </div>
</template>
<script>
import Sidebar from "@/containers/sidebar";
import TopNav from "@/containers/topNav";
import { mapGetters } from "vuex";

export default {
  data() {
    return { show: false };
  },
  components: {
    TopNav,
    Sidebar,
  },
  computed: {
    ...mapGetters(["getMenuType"]),
  },
};
</script>
