import Vue from "vue";
import BootstrapVue from "bootstrap-vue";
import VueAuth from "@websanova/vue-auth";
import axios from "axios";
import VueAxios from "vue-axios";
import routes from "./router";
import { store } from "./store";
import App from "./App";
import en from "@/locales/en.json";
import es from "@/locales/es.json";
import VueI18n from "vue-i18n";
import { defaultLocale, localeOptions } from "@/constants/config";

import "@/assets/css/vendor/dropzone.min.css";
import "@/assets/css/vendor/bootstrap.min.css";

import theme from "@/assets/css/style.scss";
Vue.use(theme)

import VeeValidate from "vee-validate";

Vue.use(VeeValidate);

import FileUpload from "vue-upload-component";
Vue.component("file-upload", FileUpload);

import VueRouter from "vue-router";
import VModal from "vue-js-modal";

Vue.use(VueRouter);
Vue.use(VModal);

console.log("!!!routes", routes);
const router = new VueRouter({
  linkActiveClass: "active",
  routes,
  mode: "history",
});
Vue.router = router;

Vue.use(BootstrapVue);
Vue.use(VueAxios, axios);
Vue.use(VueAuth, {
  auth: require("./pages/user/Drivers/bearer.js"),
  http: require("@websanova/vue-auth/drivers/http/axios.1.x.js"),
  router: require("@websanova/vue-auth/drivers/router/vue-router.2.x.js"),
  refreshData: {
    url: "auth/refresh",
    method: "GET",
    enabled: false,
    interval: 30,
  },
  fetchData: { url: "auth/user", method: "GET", enabled: false },
  loginData: { redirect: "" },
  // notFoundRedirect: {path: '/Error'},
  //   rolesVar: 'roles',
  //   parseUserData:function(response) {
  //         return JSON.parse(localStorage.getItem('publisher')) //since manual fetch of user on each reload is disabled, localstorage value is used
  // 	}
});
Vue.use(VueI18n);
const messages = { en: en, es: es };
const locale =
  localStorage.getItem("currentLanguage") &&
  localeOptions.filter((x) => x.id == localStorage.getItem("currentLanguage"))
    .length > 0
    ? localStorage.getItem("currentLanguage")
    : defaultLocale;
const i18n = new VueI18n({
  locale: locale,
  fallbackLocale: "en",
  messages,
});

import Notifications from "@/components/Common/Notification";
Vue.use(Notifications);

import vuePerfectScrollbar from "vue-perfect-scrollbar";
Vue.component("vue-perfect-scrollbar", vuePerfectScrollbar);

import Breadcrumb from "@/components/Common/Breadcrumb";
Vue.component("piaf-breadcrumb", Breadcrumb);

import RefreshButton from "@/components/Common/RefreshButton";
Vue.component("b-refresh-button", RefreshButton);

import Colxx from "@/components/Common/Colxx";
Vue.component("b-colxx", Colxx);

import contentmenu from "v-contextmenu";
Vue.use(contentmenu);

import VCalendar from "v-calendar";
import "v-calendar/lib/v-calendar.min.css";

Vue.use(VCalendar, {
  firstDayOfWeek: 2, // ...other defaults,
  formats: {
    title: "MMM YY",
    weekdays: "WW",
    navMonths: "MMMM",
    input: ["L", "YYYY-MM-DD", "YYYY/MM/DD"],
    dayPopover: "L",
  },
  datePickerShowDayPopover: false,
  popoverExpanded: true,
  popoverDirection: "bottom",
});

import VueScrollTo from "vue-scrollto";
Vue.use(VueScrollTo);

export default new Vue({
  el: "#app",
  i18n,
  router,
  store,
  render: (h) => h(App),
});
