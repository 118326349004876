import { store } from "../store";

export default (to, from, next) => {
  if (
    localStorage.getItem("default_auth_token") != null &&
    localStorage.getItem("default_auth_token").length > 0 &&
    localStorage.getItem("publisher") != null &&
    localStorage.getItem("publisher").length > 0
  ) {
    next();
  } else {
    localStorage.removeItem("default_auth_token");
    localStorage.removeItem("publisher");
    next("/user/login");
  }
};
