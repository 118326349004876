<style scoped>
.div-inline {
  display: inline !important;
  margin: 2px;
  padding: 0px;
}

.p-x-4 {
  padding-left: 11px;
}

.p-x-24 {
  padding-left: 24px;
}

.table-container-loading {
  position: relative;
  overflow: scroll;
  opacity: 0.2 !important;
}

.table-container {
  position: relative;
  overflow: scroll;
}

tr th:first-child {
  box-shadow: 1px 0 2px -2px #888;
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  width: 15px;
  left: 0;
  position: -webkit-sticky;
  position: sticky;
  z-index: 1;
  background: #ffffff;
}

thead th {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

tbody th {
  position: -webkit-sticky;
  position: sticky;
  left: 0;
}

thead th:first-child {
  background: #ffffff !important;
  box-shadow: 1px 0 2px -2px #888;
  left: 0;
  z-index: 1;
}

thead th:last-child {
  background: #ffffff !important;
  box-shadow: -2px 0 2px -2px #888;
  right: 0;
  z-index: 1;
}

tr td:first-child {
  box-shadow: 1px 0 2px -2px #888;
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  width: 15px;
  left: 0;
  position: -webkit-sticky;
  position: sticky;
  z-index: 1;
  background: #ffffff;
}

tr td:last-child {
  box-shadow: -2px 0 2px -2px #888;
  content: "";
  top: 0;
  bottom: 0;
  width: 15px;
  right: 0;
  position: -webkit-sticky;
  position: sticky;
  z-index: 1;
  background: #ffffff;
}

.atd-revenue-report {
  display: flex;
  justify-content: space-between;
}

.atd-report-download, .atd-title-text {
  font-size: 26px;
  line-height: 42px;
  border-radius: 200px;
  background: #fff;
  border: 1px solid rgb(83, 82, 82);
  position: fixed;
  z-index: 999;
  right: 15px;
  bottom: 50px;
  box-shadow: 0 1px 15px rgba(0, 0, 0, 0.04), 0 1px 6px rgba(0, 0, 0, 0.04) !important;
  background-color: #845ec2;
  color: #f3f3f3;
  padding: 6px 12px;
}

.atd-title-text {
  font-size: 13px;
  bottom: 130px;
  padding: 5px 10px;
  border-radius: 0;
  right: 10px;
  line-height: 1.5;
  cursor: pointer;
  display: none;
}


.p-x-37 {
  padding-left: 37px;
}

.atd-report-download:hover .atd-title-text {
  display: block;
}

body th.atd-col-md {
  padding: 5px;
}

div.mx-datepicker-range {
  width: 213px;
}

.chart-container-loading {
  opacity: 0.2 !important;
}

</style>
<template>
  <div v-if="impressionRevenueTableData.length>0">
    <JsonCSV :advancedOptions=tsting() class="btn btn-default atd-report-download" :data="csvData" :name=reportName
             style="background-color:#0da1f5; border:none;-moz-box-shadow:2px 2px 4px #000000;-webkit-box-shadow:2px 2px 4px #000000;box-shadow:2px 2px 4px #000000;"
             v-if="impressionRevenueReportData.length>0 && !isLoading">
      <i style="color: #fff; font-weight: bold; " class="iconsmind-Data-Download"></i>
      <div class="atd-title-text">Download</div>
    </JsonCSV>
    <b-row>
      <b-colxx xxs="12" lg="12" md="12">
        <b-colxx xxs="8" lg="8" md="8" style="display:inline-block;">
					<span>
						<h1><i class="iconsmind-Money-Bag mr-3"/><span
                class="align-middle d-inline-block p-l-5 mr-2"> Revenue </span></h1>
						<span v-for="(domain, index) in domainName" :key="index">
							<span v-if="domain.domain_id == domainId ">
								{{ domainNameFormatter(domain.domain_name) }}
							</span>
						</span>
						<span v-if="!domainId ">
								All Domains
						</span>
					</span>
        </b-colxx>
        <b-colxx xxs="3" lg="3" md="3" style="float:right; display:inline-block;">
						<span>
							<h1><i class="iconsmind-Calendar-4 mr-3" style="vertical-align:sub;"/>
							<span class="align-middle d-inline-block" style="width:150px "><date-picker :lang='lang'
                                                                                          style="min-width:180px;"
                                                                                          :shortcuts="shortcuts"
                                                                                          mode="range"
                                                                                          v-model="selectedValueRange"
                                                                                          range
                                                                                          :input-props="{ class: 'form-control', placeholder: $t('form-components.date-range')}"
                                                                                          @change="(selectedValueRange[0]!=null && selectedValueRange[1]!=null)?getRevenueImpressionReports():false "
                                                                                          :not-after='endDate'></date-picker></span>
							</h1>
						</span>
        </b-colxx>
        <div class="separator mb-5"></div>
      </b-colxx>
    </b-row>
    <b-row>
      <b-colxx xl="12" lg="12">
        <b-row>
          <b-colxx md="12" class="mb-4">
            <div class="loading" v-if="isLoading"></div>
            <b-card v-bind:class="(isLoading)?'chart-container-loading':''">

              <reports-chart v-if="impressionRevenueReportData.length > 0"
                             :data="impressionRevenueReportData"></reports-chart>
            </b-card>
          </b-colxx>
          <b-colxx md="12" class="mb-4">
            <b-card>

              <div v-bind:class="(isLoading)?'table-container-loading':'table-container'">
                <table class="table table-sm">
                  <thead>
                  <tr>
                    <th>
                      <b-row style="width: 120px;" class="text-center text-uppercase">
                        <b-colxx md="12" lg="12" sm="12">
                          <span>Date</span>
                        </b-colxx>
                      </b-row>
                    </th>
                    <th v-for="(bidder, index) in tableBidderName" :key="index">
                      <b-row style="width: 240px;">
                        <b-colxx md="12" lg="12" sm="12">
                          <b-colxx md="12" lg="12" sm="12" class="text-center text-uppercase">
                            <span>{{ bidder }}</span>
                          </b-colxx>
                          <b-colxx md="12" lg="12" sm="12" class="atd-revenue-report">
                            <b-colxx md="5" lg="5" sm="5" class="div-inline">
                              <span>Impressions</span>
                            </b-colxx>
                            <b-colxx md="4" lg="4" sm="4" class="div-inline">
                              <span>Revenue</span>
                            </b-colxx>
                            <b-colxx md="3" lg="3" sm="3" class="div-inline">
                              <span>eCPM</span>
                            </b-colxx>
                          </b-colxx>
                        </b-colxx>
                      </b-row>
                    </th>
                    <th>
                      <b-row style="width: 240px;">
                        <b-colxx md="12" lg="12" sm="12">
                          <b-colxx md="12" lg="12" sm="12" class="text-center">
                            <span>Total</span>
                          </b-colxx>
                          <b-colxx md="12" lg="12" sm="12" class="atd-revenue-report">
                            <b-colxx md="5" lg="5" sm="5" class="div-inline">
                              <span>Impressions </span>
                            </b-colxx>
                            <b-colxx md="4" lg="4" sm="4" class="div-inline">
                              <span>Revenue</span>
                            </b-colxx>
                            <b-colxx md="3" lg="3" sm="3" class="div-inline">
                              <span>eCPM</span>
                            </b-colxx>
                          </b-colxx>
                        </b-colxx>
                      </b-row>
                    </th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(revenue, parentIndex) in impressionRevenueTableData" :key="parentIndex">
                    <td>
                      <span class="font-weight-medium">{{ dateFormater(revenue.date) }}</span>
                    </td>
                    <td v-for="(bidder, childindex) in tableBidderName" :key="childindex"
                        class="text-center atd-col-md">
                      <b-row style="width: 220px;">
                        <b-colxx md="12" lg="12" sm="12" class="atd-revenue-report">
                          <b-colxx md="5" lg="5" sm="5"
                                   :class="(addCommas(getBidderTotalRevenueAndImpression(parentIndex, bidder, 'impression')).length>8)?'div-inline text-right':'div-inline p-x-24'">
                            <span v-if="dateFormater(revenue.date)>latestReportDate">-</span>
                            <span
                                v-else>{{ addCommas(getBidderTotalRevenueAndImpression(parentIndex, bidder, 'impression')) }}</span>
                          </b-colxx>
                          <b-colxx md="4" lg="4" sm="4"
                                   :class="(addCommas(getBidderTotalRevenueAndImpression(parentIndex, bidder, 'revenue')).length>5)?'div-inline text-right':'div-inline p-x-4'">
                            <span v-if="dateFormater(revenue.date)>latestReportDate">-</span>
                            <span v-else>
																<span
                                    v-if="getBidderTotalRevenueAndImpression(parentIndex, bidder, 'revenue')!=0">$</span><span>{{ addCommas(getBidderTotalRevenueAndImpression(parentIndex, bidder, 'revenue')) }}</span>
															</span>
                          </b-colxx>
                          <b-colxx md="3" lg="3" sm="3" class="div-inline ">
                            <span v-if="dateFormater(revenue.date)>latestReportDate">-</span>
                            <span v-else>
																<span
                                    v-if="getBidderTotalRevenueAndImpression(parentIndex, bidder, 'impression')!=0">$</span><span>{{ getBidderTotalRevenueAndImpression(parentIndex, bidder, 'ecpm') }}</span>
															</span>
                          </b-colxx>
                        </b-colxx>
                      </b-row>
                    </td>
                    <td class="text-center atd-col-md">
                      <b-row style="width: 220px;">
                        <b-colxx md="12" lg="12" sm="12" class="atd-revenue-report">
                          <b-colxx md="5" lg="5" sm="5"
                                   :class="(addCommas(revenue.impression).length>8)?'div-inline text-right':'div-inline p-x-24'">
                            <span v-if="dateFormater(revenue.date)>latestReportDate">-</span>
                            <span v-else>{{ addCommas(revenue.impression) }}</span>
                          </b-colxx>
                          <b-colxx md="4" lg="4" sm="4"
                                   :class="(addCommas(revenue.revenue).length>5)?'div-inline text-right':'div-inline p-x-4'">
                            <span v-if="dateFormater(revenue.date)>latestReportDate">-</span>
                            <span v-else><span v-if="revenue.revenue!=0">$</span>{{ addCommas(revenue.revenue) }}</span>
                          </b-colxx>
                          <b-colxx md="3" lg="3" sm="3" class="div-inline ">
                            <span v-if="dateFormater(revenue.date)>latestReportDate">-</span>
                            <span v-else><span v-if="revenue.ecpm!=0">$</span>{{ revenue.ecpm }}</span>
                          </b-colxx>
                        </b-colxx>
                      </b-row>
                    </td>
                  </tr>
                  </tbody>
                  <tfoot>
                  <tr>
                  <tr>
                    <th>
                      <b-row style="width: 120px;" class="text-center text-uppercase">
                        <b-colxx md="12" lg="12" sm="12">
                          <span>Total</span>
                        </b-colxx>
                      </b-row>
                    </th>
                    <td v-for="(bidder, childindex) in tableBidderName" :key="childindex"
                        class="text-center atd-col-md">
                      <b-row style="width: 220px;">
                        <b-colxx md="12" lg="12" sm="12" class="atd-revenue-report">
                          <b-colxx md="5" lg="5" sm="5"
                                   :class="(addCommas(getColumnTotal(bidder,'impression')).length>8)?'div-inline text-right':'div-inline p-x-24'">
                            <span>{{ (addCommas(getColumnTotal(bidder, 'impression')).length > 11) ? (makeReadable(getColumnTotal(bidder, 'impression'))) : (addCommas(getColumnTotal(bidder, 'impression'))) }}</span>
                          </b-colxx>
                          <b-colxx md="4" lg="4" sm="4"
                                   :class="(addCommas(getColumnTotal(bidder,'revenue')).length>5)?'div-inline text-right':'div-inline p-x-4'">
															<span style="overflow-wrap:normal;">
															<span>$</span>{{ (addCommas(getColumnTotal(bidder, 'revenue'))).length > 9 ? ((addCommas(getColumnTotal(bidder, 'revenue'))).substr(0, 9).charAt(8) == 0 ? (addCommas(getColumnTotal(bidder, 'revenue'))).substr(0, 7) : (addCommas(getColumnTotal(bidder, 'revenue'))).substr(0, 9)) : (addCommas(getColumnTotal(bidder, 'revenue'))) }}</span>
                          </b-colxx>
                          <b-colxx md="3" lg="3" sm="3" class="div-inline ">
                            <span>$</span><span>{{ getColumnCPM(bidder) }}</span>
                          </b-colxx>
                        </b-colxx>
                      </b-row>
                    </td>
                    <td class="text-center atd-col-md">
                      <b-row style="width: 220px;">
                        <b-colxx md="12" lg="12" sm="12" class="atd-revenue-report">
                          <b-colxx md="5" lg="5" sm="5"
                                   :class="(addCommas(getColumnTotal('total','impression')).length>8)?'div-inline text-right':'div-inline p-x-24'">
                            <span>{{ addCommas(getColumnTotal('total', 'impression')).length > 11 ? makeReadable((getColumnTotal('total', 'impression'))) : addCommas(getColumnTotal('total', 'impression')) }}</span>
                          </b-colxx>
                          <b-colxx md="4" lg="4" sm="4"
                                   :class="(addCommas(getColumnTotal('total','revenue')).length>5)?'div-inline text-right':'div-inline'">
															<span
                                  style="overflow-wrap:normal;"><span>$</span>{{ (addCommas(getColumnTotal('total', 'revenue'))).length > 9 ? ((addCommas(getColumnTotal('total', 'revenue'))).substr(0, 9).charAt(8) == 0 ? (addCommas(getColumnTotal('total', 'revenue'))).substr(0, 7) : (addCommas(getColumnTotal('total', 'revenue'))).substr(0, 9)) : (addCommas(getColumnTotal('total', 'revenue'))) }}
																</span>
                          </b-colxx>
                          <b-colxx md="3" lg="3" sm="3" class="div-inline ">
															<span><span>$</span>{{ getColumnCPM('total') }}
																</span>
                          </b-colxx>
                        </b-colxx>
                      </b-row>
                    </td>
                  </tr>
                  </tr>
                  </tfoot>
                </table>
              </div>
            </b-card>
          </b-colxx>
        </b-row>
      </b-colxx>
    </b-row>
  </div>
  <div class="text-center d-flex justify-content-between" v-else>
    <div class="loading"></div>
  </div>
</template>
<script>
import axios from 'axios';
import VueAxios from 'vue-axios';

import ReportsChart from '@/components/ReportsChart.vue';

import {mapGetters, mapMutations, mapActions} from "vuex";
import JsonCSV from 'vue-json-csv'
import moment from 'moment'
import DatePicker from 'vue2-datepicker'
import Humanize from 'humanize-plus'

// var humanize = require('humanize-plus')
export default {
  name: "Revenue",
  data() {
    return {
      isLoading: false,
      publisherId: undefined,
      domainId: (this.$route.params.domainId) ? parseInt(this.$route.params.domainId) : undefined,
      impressionRevenueReportData: [],
      impressionRevenueTableData: [],
      selectedValueRange: [
        new Date(),
        new Date()
      ],
      selectedStartDate: undefined,
      selectedEndDate: undefined,
      today: undefined,
      endDate: undefined,
      startDate: undefined,
      tableBidderName: [],
      csvData: [],
      allDomains: [],
      reportName: "AutomatadReports_" + moment(new Date()).format('YYYY-MM-DD') + ".csv",
      lang: {
        days: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
        months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        pickers: ['next 7 days', 'next 30 days', 'previous 7 days', 'previous 30 days'],
        placeholder: {
          date: 'Select Date',
          dateRange: 'Select Date Range'
        }
      },
      shortcuts: [
        {
          text: 'Month-to-date',
          onClick: () => {
            this.selectedValueRange = [new Date(new Date().getFullYear(), new Date().getMonth(), 1), new Date()]
            this.getRevenueImpressionReports()
          }
        },
        {
          text: 'Last Month',
          onClick: () => {
            this.selectedValueRange = [new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1), new Date(new Date().getFullYear(), new Date().getMonth(), 0)]
            this.getRevenueImpressionReports()
          }
        },
        {
          text: 'Last Six Months',
          onClick: () => {
            this.selectedValueRange = [new Date(new Date().getFullYear(), new Date().getMonth() - 6, 1), new Date(new Date().getFullYear(), new Date().getMonth(), 0)]
            this.getRevenueImpressionReports()
          }
        }


      ],
      totalColECPM: 0,
      totalColImpression: 0,
      totalColRevenue: 0
    }
  },
  components: {
    ReportsChart,
    JsonCSV,
    DatePicker
  },
  computed: {
    ...mapGetters({
      currentUser: "currentUser",
      domainName: 'allDomains',
      latestReportDate: 'latestReportDate'
    })
  },
  watch: {
    latestReportDate: function (newVal, prevVal) {
      return newVal
    }
  },
  created() {
    axios.post(process.env.VUE_APP_BASE_URL + 'reports/v1/domain-day-revenue/', {'publisherId': this.publisherId}).then((resp) => {
      this.latestReportDateMutation(resp.data.data.latest_report_date)
    }).catch((err) => console.log(err))
  },
  mounted() {
    this.publisherId = this.publisherId = (this.currentUser) ? this.currentUser[0]['id'] : JSON.parse(localStorage.getItem('publisher'))[0]['id'];
    this.today = new Date();
    let tday = this.today.getDate();
    //  let tday = 2;
    var firstStartDate = '';
    let lastReportDay = moment.utc(this.latestReportDate).date()


    // console.log("tday",tday,lastReportDay)

    if ((tday == 1 || tday == 2) && lastReportDay > 2) {
      // console.log('hit')

      firstStartDate = new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1)
      this.endDate = new Date(new Date().getFullYear(), new Date().getMonth(), 0)

    } else {
      firstStartDate = new Date(new Date().getFullYear(), new Date().getMonth(), 1)
      this.endDate = new Date();

    }
    this.startDate = firstStartDate;
    this.selectedValueRange[0] = this.startDate;
    this.selectedValueRange[1] = this.endDate;
    this.getBiddernames();
    this.getDomain(this.domainId)
    this.getRevenueImpressionReports();
    this.getPublisherDomainList();

  },
  methods: {
    ...mapMutations(["latestReportDateMutation"]),
    makeReadable(num) {
      return Humanize.compactInteger(num, 3)
    },
    addCommas(number, minDecimalPlaces = 0, maxDecimalPlaces = Math.max(3, minDecimalPlaces)) {
      const options = {};
      options.maximumFractionDigits = maxDecimalPlaces;
      options.minimumFractionDigits = minDecimalPlaces;
      return Intl.NumberFormat('en-US', options).format(number);
    },
    tsting() {
      return {'transform': this.transformEachLine}
    },
    transformEachLine(value, columnNumber) {
      if (value == '') {
        value = 0
      }
      return value;
    },
    getDomain(domain) {
      // axios.post(process.env.VUE_APP_BASE_URL + 'reports/v1/pub-get-dash-info/', {'publisherId' : this.publisherId})
      // .then(response => {
      // 	if (response.data.status) {
      if (domain == null) {
        this.allDomains = "".concat(this.domainName.map(k => " " + k.domain_name))
        // console.log(this.allDomains)
      } else {
        this.allDomains = "".concat(this.domainName.filter(k => {
          return domain == k.domain_id
        })[0].domain_name)
        // console.log(this.allDomains)
      }
      return this.allDomains
      // })
      // .catch(e => {
      // 	console.log(e);
      // });
    },
    getColumnCPM(bidder) {
      var revenue = 0
      var impression = 0
      var arr = 0

      if (bidder == 'total') {
        this.impressionRevenueReportData.map((k) => {
          if (k.hasOwnProperty('impression')) {
            revenue += k['revenue']
            impression += k['impression']
          }
          // var arr1=(revenue/impression)*1000
          // this.totalColECPM=(Math.round((arr1+ Number.EPSILON )*100))/100

        })
      } else {
        this.impressionRevenueReportData.map((k) => {
          if (k.bidderImpressionRevenue.hasOwnProperty(bidder)) {
            revenue += (k.bidderImpressionRevenue[bidder]['revenue'])
            impression += (k.bidderImpressionRevenue[bidder]['impression'])
          }
        })
      }

      if (impression != 0) {
        arr = (revenue / impression) * 1000
        return (Math.round((arr + Number.EPSILON) * 100)) / 100
      }
      return arr
    },
    fillMissingObj(object) {
      var defaults = {};
      var list = [];

      function addToList(obj) {
        // Create our entry backed by the defaults
        var entry = Object.create(defaults);

        // Loop through the new object's keys...
        Object.keys(obj).forEach(function (key) {
          // ...adding a default if it's a new key
          if (!defaults.hasOwnProperty(key)) {
            defaults[key] = "default for " + key;
          }
          // ...and grabbing the value
          entry[key] = obj[key];
        });

        // Put it on the list
        list.push(entry);
      }

      addToList({a: "a0", b: "b0"}); // Has a and b
      addToList({a: "a1", c: "c1"}); // Has a and c
      addToList({b: "b2", d: "d2"}); // Has b and d

      list.forEach(function (entry, index) {
        snippet.log("Object #" + index + ":");
        Object.keys(defaults).forEach(function (key) {
          snippet.log(key + " = " + entry[key]);
        });
      });
    }, dict_reverse(obj) {
      var new_obj = {}
      var rev_obj = ['impression', 'revenue', 'ecpm'];
      rev_obj.forEach(function (i) {
        new_obj[i] = obj[i];
      })
      return new_obj;
    },
    reverseObject(object) {
      // console.log(object)
      var newObject = {};
      var keys = [];

      for (var key in object) {
        keys.push(key);
      }

      for (var i = keys.length - 1; i >= 0; i--) {
        var value = object[keys[i]];
        newObject[keys[i]] = value;
      }
      return newObject;
    },
    getColumnTotal(bidder, property) {
      var arr = 0

      if (bidder == 'total') {
        this.impressionRevenueReportData.map((k) => {
          if (k.hasOwnProperty(property)) {
            arr += (k[property])
          }
        })
        // if(property=='revenue'){
        // 		this.totalColRevenue=arr
        // 	}else{
        // 		this.totalColImpression=arr
        // 	}
      } else {
        this.impressionRevenueReportData.map((k) => {
          if (k.bidderImpressionRevenue.hasOwnProperty(bidder)) {
            arr += (k.bidderImpressionRevenue[bidder][property])
          }
        })
      }
      if (property == 'revenue') {
        return (Math.round((arr + Number.EPSILON) * 100)) / 100
      }
      return arr

    },
    getRevenueImpressionReports() {

      // console.log("@@@@selectedValueRange in getRevenueImpressionReports",this.selectedValueRange);
      var diff = (this.selectedValueRange[0] != null && this.selectedValueRange[1] != null) ? (this.selectedValueRange[1].getTime() - this.selectedValueRange[0].getTime()) : 0
      var maxDate = Math.floor(diff / (1000 * 60 * 60 * 24))
      // console.log('kk',this.selectedValueRange[0].getTime())
      if (maxDate <= 186) {
        this.isLoading = true;
        axios.post(process.env.VUE_APP_BASE_URL + 'reports/v1/get-impression-revenue-reports/',
            {
              'publisherId': this.publisherId,
              'domainId': this.domainId,
              'selectedDateRange': {
                'start': moment.utc(this.selectedValueRange[0]).local().format('DD/MM/YYYY'),
                'end': moment.utc(this.selectedValueRange[1]).local().format('DD/MM/YYYY')
              }
            })
            .then(response => {
              if (response.data.status) {
                this.isLoading = false;
                this.impressionRevenueReportData = response.data.data;
                this.impressionRevenueTableData = this.impressionRevenueReportData.reverse();
                this.getDomain(this.domainId)
                var bidderTotal = {};
                var sites = {}
                var dateRange = {}
                var count = 0
                this.csvData = this.impressionRevenueReportData.map(({
                                                                       bidderImpressionRevenue,
                                                                       currency,
                                                                       ...attrs
                                                                     }, index) => {
                  var l = {};
                  for (var i in this.tableBidderName) {
                    let bidr = this.tableBidderName[i]
                    let bidObj = (bidderImpressionRevenue.hasOwnProperty(bidr)) ? this.dict_reverse(bidderImpressionRevenue[bidr]) : this.dict_reverse({
                      impression: 0,
                      ecpm: 0,
                      revenue: 0
                    })
                    for (var m in bidObj) {
                      if (m == 'revenue' || m == 'ecpm') {
                        l[bidr + ' ' + m + '($)'] = (bidderImpressionRevenue.hasOwnProperty(bidr)) ? this.addCommas(bidderImpressionRevenue[bidr][m]) : 0
                      } else {
                        l[bidr + ' ' + m] = (bidderImpressionRevenue.hasOwnProperty(bidr)) ? this.addCommas(bidderImpressionRevenue[bidr][m]) : 0
                      }

                    }
                  }


                  for (var i in bidderImpressionRevenue) {
                    for (var m in this.dict_reverse(bidderImpressionRevenue[i])) {
                      if (count == 0) {
                        sites[i + ' ' + m] = this.allDomains
                        dateRange[i + ' ' + m] = moment(this.selectedValueRange[0]).format('YYYY-MM-DD') + ' to ' + moment(this.selectedValueRange[1]).format('YYYY-MM-DD')
                      }
                      count += 1
                      if (m == 'revenue' || m == 'ecpm') {
                        if (m == 'revenue') {
                          bidderTotal[i + ' ' + m + '($)'] = this.addCommas(this.getColumnTotal(i, m))
                        } else {
                          bidderTotal[i + ' ' + m + '($)'] = this.getColumnCPM(i)
                        }
                      } else {
                        bidderTotal[i + ' ' + m] = this.addCommas(this.getColumnTotal(i, m))
                      }
                    }
                  }

                  return Object.assign({'Date': moment(attrs.date).format('ll')}, l, {
                    ' Ad Impressions': attrs.impression,
                    'Estimated Revenue($)': attrs.revenue,
                    'ECPM($)': attrs.ecpm
                  })
                })

                var dateT = ''
                var siteT = ''

                if (Object.keys(dateRange).length == 0) {
                  dateT = moment(this.selectedValueRange[0]).format('YYYY-MM-DD') + ' to ' + moment(this.selectedValueRange[1]).format('YYYY-MM-DD')
                } else {
                  dateT = ''
                }

                if (Object.keys(sites).length == 0) {
                  siteT = this.allDomains
                } else {
                  siteT = ''
                }
// console.log(this.csvData)

                this.csvData = [...this.csvData, Object.assign({'Date': 'Total'}, bidderTotal, {
                  ' Ad Impressions': this.addCommas(this.getColumnTotal('total', 'impression')),
                  'Estimated Revenue($)': this.addCommas(this.getColumnTotal('total', 'revenue')),
                  'ECPM($)': this.getColumnCPM('total')
                }), {
                  'Date': '',
                  ' Ad Impressions': '',
                  'Estimated Revenue($)': ''
                }, Object.assign({'Date': 'Domain:'}, sites, {
                  ' Ad Impressions': siteT,
                  'Estimated Revenue($)': ''
                }), Object.assign({'Date': 'Date Range:'}, dateRange, {
                  ' Ad Impressions': dateT,
                  'Estimated Revenue($)': ''
                })]
              }
            })
            .catch(e => {
              console.warn(e);
            });
      } else {
        this.$notify('warning', 'Date Range', "Selected Date range should be less than or equal to 6 months.", {
          duration: 5000,
          permanent: false
        });
        // console.log('@@@@eee1',this.selectedValueRange)

        this.selectedValueRange = [
          this.selectedValueRange[0],
          this.selectedValueRange[0]
        ]
        // console.log('@@@@eee2',this.selectedValueRange)
        if (this.selectedValueRange[0] != null && this.selectedValueRange[1] != null) {
          this.getRevenueImpressionReports()
        }
      }
    },
    domainNameFormatter(domainName) {
      return domainName.replace('http://', '').replace('https://', '').replace('/', '');
    },
    getPublisherDomainList() {
      return this.domainName
      // this.domainName = this.allDomains
      // axios.post(process.env.VUE_APP_BASE_URL + 'reports/v1/pub-get-dash-info/', {'publisherId' : this.publisherId})
      // .then(response => {
      // 	if (response.data.status) {
      // 		this.domainName = response.data.data;
      // 		console.log(this.domainName)
      // 	}
      // })
      // .catch(e => {
      // 	this.unauthorizedAccess();
      // });
    },
    getBiddernames() {
      axios.post(process.env.VUE_APP_BASE_URL + 'reports/v1/get-bidder-names/', {
        'publisherId': this.publisherId,
        'domainId': this.domainId
      })
          .then(response => {
            if (response.data.status) {
              this.tableBidderName = response.data.data;
            }
          })
          .catch(e => {
            console.warn(e);
          });
    },
    getBidderTotalRevenueAndImpression(idx, bidderNm, impresssionOrRevenue) {
      var impressionRevenue = 0;
      if (this.impressionRevenueReportData[idx]['bidderImpressionRevenue'].hasOwnProperty(bidderNm)) {
        impressionRevenue = this.impressionRevenueReportData[idx]['bidderImpressionRevenue'][bidderNm][impresssionOrRevenue];
        // console.log(impressionRevenue)
      }
      return impressionRevenue;
    },
    dateFormater(dt) {
      return dt.toString().replace('T00:00:00', '')
    }
  }
}
</script>


