<style scoped>
.error-msg {
  color: red;
}
</style>
<template>
  <b-row class="h-100">
    <b-colxx xxs="12" md="10" class="mx-auto my-auto">
      <b-card class="auth-card" no-body>
        <div class="position-relative image-side">
          <p class="text-white h2">
            {{ $t("dashboards.magic-is-in-the-details") }}
          </p>
          <p class="white mb-0">
            Please Enter your new password <br />New password and confirm
            password should be same
          </p>
        </div>
        <div class="form-side">
          <router-link tag="a" to="/"><span class="logo-single" /></router-link>
          <h6 class="mb-4">Reset password</h6>
          <b-form @submit.prevent="submit">
            <label class="form-group has-float-label mb-4">
              <input
                type="password"
                class="form-control"
                v-model="newpassword"
              />
              <span>NewPassword</span>
            </label>
            <label class="form-group has-float-label mb-4">
              <input
                type="password"
                class="form-control"
                v-model="confirmpassword"
              />
              <span>ConfirmPassword</span>
            </label>
            <div class="d-flex justify-content-end align-items-center">
              <b-button
                type="submit"
                variant="primary"
                size="lg"
                class="btn-shadow"
                :disabled="!formValid"
                >{{ $t("user.reset-password-button") }}</b-button
              >
            </div>
            <div class="error-msg">
              <p>{{ error }}</p>
            </div>
          </b-form>
        </div>
      </b-card>
    </b-colxx>
  </b-row>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      newpassword: "",
      confirmpassword: "",
      error: "",
    };
  },
  methods: {
    submit() {
      axios
        .post(process.env.VUE_APP_BASE_URL + "v1/auth/password/reset/confirm/", {
          uid: this.$route.params.id,
          token: this.$route.params.resetID,
          new_password1: this.newpassword,
          new_password2: this.confirmpassword,
        })

        .then((response) => {
          this.$notify(
            "success",
            "success",
            "Password has been changed successfully.",
            { duration: 3000, permanent: false }
          );
          setTimeout(() => {
            this.$router.push("/login/");
          }, 1500);
        })
        .catch((error) => {
          this.$notify(
            "Warning",
            "Warning",
            "Password has not been changed, please check if the passwords entered are same.",
            { duration: 3000, permanent: false }
          );
        });
    },
  },
  computed: {
    formValid() {
      if (this.newpassword && this.newpassword != this.confirmpassword) {
        this.error = "Error, the two passwords do not match.";
      } else {
        this.error = "";
      }
      return this.newpassword == this.confirmpassword;
    },
  },
};
</script>
