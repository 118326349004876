<style>
.atd-alert-position.alert-danger {
  position: absolute;
  right: 58px;
  top: 100px;
  background-color: rgb(189, 55, 70);
  color: #fff;
}

.atd-alert-position.alert-danger button.close:hover {
  color: #fff;
  opacity: 0.9;
}

.dropdown-item {
  padding: 0px !important;
}

.dropdown-update {
  padding: 10px;
  display: block;
  width: 100%;
  color: inherit;
}

.dropdown-update a {
  transition: none;
}

.dropdown-update:focus {
  color: #922c88;
}

.dropdown-item:active .dropdown-update {
  color: #fff;
  transition: none;
}

.dropdown-menu.show {
  /* overflow-y: scroll; */
  /* height: 331px; */
}
</style>
<template>
  <nav class="navbar fixed-top">
    <a
      href="#"
      class="menu-button d-none d-md-block"
      @click.prevent="toggleSideMenu()"
    >
      <menu-icon />
    </a>
    <a
      href="#"
      class="menu-button-mobile d-xs-block d-sm-block d-md-none"
      @click.prevent="changeSideMenuForMobile(menuType)"
    >
      <mobile-menu-icon />
    </a>
    <div class="header-icons d-inline-block">
      <div v-if="$route.matched[1].path != '/app/AmpSetup'">
        <div v-if="$route.matched[1].path != '/app/contract'">
          <div v-if="$route.matched[1].path != '/app/accountDetails'">
            <b-dropdown
              id="ddown5"
              :text="selectedDomain"
              size="sm"
              variant="outline-primary"
              @shown="dropdownOverflowAdjust"
              class="top-right-button top-right-button-single nivid-site-dropdown"
              no-fade="true"
            >
              <b-dropdown-item
                v-if="
                  $route.matched[1].path == '/app/revenue' && pubInfo.length > 0
                "
                @click="selectDomain('All Domain')"
              >
                <router-link
                  :to="$route.matched[1].path"
                  class="dropdown-update"
                >
                  All Domains
                </router-link>
              </b-dropdown-item>
              <b-dropdown-item
                v-if="
                  $route.matched[1].path == '/app/dashboards' &&
                  pubInfo.length > 0
                "
                @click="selectDomain('All Domain')"
              >
                <router-link
                  :to="$route.matched[1].path"
                  class="dropdown-update"
                >
                  All Domains
                </router-link>
              </b-dropdown-item>
              <b-dropdown-item
                v-if="pubInfo.length == 0"
                class="dropdown-update"
                >No Domains Found</b-dropdown-item
              >

              <b-dropdown-item
                v-for="(pubs, index) of pubInfo"
                :key="index"
                @click="
                  selectDomain(
                    pubs.domain_name,
                    pubs.domain_id,
                    pubs.assertive_yield_id
                  )
                "
              >
                <router-link
                  v-if="pubs.assertive_yield_id != null"
                  :to="
                    $route.matched[1].path +
                    '/default/' +
                    pubs.domain_id +
                    '/' +
                    pubs.assertive_yield_id
                  "
                  class="dropdown-update"
                >
                  {{ pubs.domain_name }}
                </router-link>

                <router-link
                  v-else
                  :to="$route.matched[1].path + '/default/' + pubs.domain_id"
                  class="dropdown-update"
                >
                  {{ pubs.domain_name }}
                </router-link>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </div>
      </div>
    </div>
    <router-link class="navbar-logo" tag="a" to="/app">
      <span class="logo d-none d-xs-block"></span>
      <span class="logo-mobile d-block d-xs-none"></span>
    </router-link>

    <div class="ml-auto">
      <div class="header-icons d-inline-block align-middle">
        <b-button
          variant="empty"
          class="header-icon d-none d-sm-inline-block"
          @click="toggleFullScreen"
        >
          <i
            :class="{
              'd-block': true,
              'simple-icon-size-actual': fullScreen,
              'simple-icon-size-fullscreen': !fullScreen,
            }"
          />
        </b-button>
      </div>
      <div class="user d-inline-block">
        <b-dropdown
          class="dropdown-menu-right"
          right
          variant="empty"
          toggle-class="p-0"
          menu-class="mt-3"
          no-caret
        >
          <template slot="button-content" v-if="userInfo">
            <span class="name mr-1">{{ userInfo["name"] }}</span>
            <span>
              <avatar
                :username="userInfo['name']"
                :size="35"
                :customStyle="{ display: 'inline-block' }"
              ></avatar>
            </span>
          </template>
          <b-dropdown-item class="dropdown-update" @click="logout"
            >Sign out</b-dropdown-item
          >
        </b-dropdown>
      </div>
    </div>
    <b-alert
      v-model="showDismissibleAlert"
      variant="danger"
      dismissible
      class="atd-alert-position"
      v-if="reportStatus != undefined && reportStatus.message !== 'available'"
    >
      {{ reportStatus.message }}
    </b-alert>
  </nav>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import { MenuIcon, MobileMenuIcon } from "@/components/Svg";

import Avatar from "vue-avatar";
import axios from "axios";

import {
  searchPath,
  menuHiddenBreakpoint,
  localeOptions,
  // eslint-disable-next-line no-unused-vars
  buyUrl,
} from "@/constants/config";
import jquery from "jquery";

export default {
  components: {
    MenuIcon,
    MobileMenuIcon,
    Avatar,
  },
  data() {
    return {
      showDismissibleAlert: true,
      selectedParentMenu: "",
      searchKeyword: "",
      isMobileSearch: false,
      isSearchOver: false,
      fullScreen: false,
      menuHiddenBreakpoint,
      searchPath,
      localeOptions,
      userInfo: undefined,
      domainId: undefined,
      asrtId: undefined,
      reportStatus: undefined,
      selected: null,
      selectedDomain: undefined,
    };
  },
  created() {},
  methods: {
    ...mapMutations(["changeSideMenuStatus", "changeSideMenuForMobile"]),
    ...mapActions(["setLang", "signOut", "allDomainsAction"]),
    searchClick() {
      if (window.innerWidth < this.menuHiddenBreakpoint) {
        if (!this.isMobileSearch) {
          this.isMobileSearch = true;
        } else {
          this.search();
          this.isMobileSearch = false;
        }
      } else {
        this.search();
      }
    },
    handleDocumentforMobileSearch() {
      if (!this.isSearchOver) {
        this.isMobileSearch = false;
        this.searchKeyword = "";
      }
    },
    changeLocale(locale) {
      this.setLang(locale);
    },
    logout() {
      this.signOut().then(() => {
        localStorage.removeItem("default_auth_token");
        localStorage.removeItem("publisher");
        localStorage.removeItem("ltRD");
        this.$router.push("/user/login");
      });
    },
    toggleFullScreen() {
      const isInFullScreen = this.isInFullScreen();

      var docElm = document.documentElement;
      if (!isInFullScreen) {
        if (docElm.requestFullscreen) {
          docElm.requestFullscreen();
        } else if (docElm.mozRequestFullScreen) {
          docElm.mozRequestFullScreen();
        } else if (docElm.webkitRequestFullScreen) {
          docElm.webkitRequestFullScreen();
        } else if (docElm.msRequestFullscreen) {
          docElm.msRequestFullscreen();
        }
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
      }
      this.fullScreen = !isInFullScreen;
    },
    isInFullScreen() {
      return (
        (document.fullscreenElement && document.fullscreenElement !== null) ||
        (document.webkitFullscreenElement &&
          document.webkitFullscreenElement !== null) ||
        (document.mozFullScreenElement &&
          document.mozFullScreenElement !== null) ||
        (document.msFullscreenElement && document.msFullscreenElement !== null)
      );
    },
    toggleSideMenu() {
      if (
        jquery("#app-container").hasClass("main-hidden") &&
        jquery("#app-container").hasClass("sub-hidden")
      ) {
        jquery("#app-container")
          .removeClass("main-hidden")
          .removeClass("sub-hidden");
      } else {
        jquery("#app-container").addClass("main-hidden").addClass("sub-hidden");
      }
    },

    // eslint-disable-next-line no-unused-vars
    selectDomain(domainName, domId, astId) {
      this.selectedDomain = domainName;
    },
    getReportSyncStatus() {
      axios
        .get(process.env.VUE_APP_BASE_URL + "reports/v1/get-report-sync-status/")
        .then((response) => {
          if (response.data.status) {
            this.reportStatus = response.data;
          }
        })
          // eslint-disable-next-line no-unused-vars
        .catch((e) => {
          ////console.log(e);
        });
    },
    getPubDashInfo() {
      // console.log(this.pubInfo)
      // this.pubNameInfo = this.allDomains
      // axios.post(process.env.VUE_APP_BASE_URL + 'reports/v1/pub-get-dash-info/')
      //     .then(response => {
      //         if (response.data.status) {
      //             this.pubInfo = response.data.data;
      //             for (var i = 0; i < response.data.data.length; i++) {
      //                 this.pubNameInfo.push(response.data.data[i])
      //             }
      //             console.log('l',this.pubInfo, this.pubNameInfo)
      //         }
      //         this.generateDropdownName();
      //     })
      //     .catch(e => {
      //         ////console.log(e);
      //     });
    },
    generateDropdownName() {
      // this.selectedDomain = "Select Domain";
      if (this.$route.params.domainId) {
        for (var pub of this.pubInfo) {
          if (pub.domain_id == this.$route.params.domainId) {
            this.selectedDomain = pub.domain_name;
          }
        }
      } else {
        console.log("@@else part", this.$route.path);
        if (
          this.$route.path == "/app/revenue/default" ||
          this.$route.path == "/app/revenue/default/" ||
          this.$route.path == "/app/dashboards/default" ||
          this.$route.path == "/app/dashboards/default"
        ) {
          this.selectedDomain = "All Domains";
        } else if (
          this.$route.path == "/app/insights/default" ||
          this.$route.path == "/app/insights/default/" ||
          this.$route.path == "/app/ads.txt/default/" ||
          this.$route.path == "/app/BrandSafety/default/" ||
          this.$route.path == "/app/BlockAxt/default"
        ) {
          this.selectedDomain = "Select Domain";
        }
      }
    },
    dropdownOverflowAdjust() {
      if (this.pubInfo.length > 4) {
        jquery(".nivid-site-dropdown .dropdown-menu.show").css({
          overflowY: "scroll",
          height: "331px",
        });
      }
    },
  },
  updated() {
    this.generateDropdownName();
  },
  mounted() {
    this.userInfo = this.currentUser
      ? this.currentUser[0]
      : JSON.parse(localStorage.getItem("publisher"))[0];
    this.getReportSyncStatus();
    this.allDomainsAction();
    // this.getPubDashInfo();
    this.generateDropdownName();
  },
  computed: {
    ...mapGetters({
      currentUser: "currentUser",
      menuType: "getMenuType",
      menuClickCount: "getMenuClickCount",
      pubInfo: "allDomains",
    }),
  },

  beforeDestroy() {
    document.removeEventListener("click", this.handleDocumentforMobileSearch);
  },
  watch: {
    "$i18n.locale"(to, from) {
      if (from != to) {
        this.$router.go(this.$route.path);
      }
    },
    isMobileSearch(val) {
      if (val) {
        document.addEventListener("click", this.handleDocumentforMobileSearch);
      } else {
        document.removeEventListener(
          "click",
          this.handleDocumentforMobileSearch
        );
      }
    },
    // eslint-disable-next-line no-unused-vars
    allDomains(newVal, oldVal) {
      return newVal;
    },
  },
};
</script>
