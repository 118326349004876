<template>
  <div class="icon-row-item">
    <b-card class="mb-4">
      <div class="">
        <div class="">
          <h5 class="text-uppercase">{{ maintitle }}</h5>
          <p
            class="text-uppercase text-primary mb-0"
            :style="title == '' ? 'height:20px' : ''"
          >
            {{ title }}
          </p>
          <h1>
            <i class="font-weight-normal" :class="icon"></i>
          </h1>
          <div class="float-right" data-value-lable="">
            <div class="imp-rev-container" v-if="showImpression" tabindex="0">
              <span
                class="lead text-center text-primary cursor-padding"
                tabindex="0"
                >{{ impression }}</span
              >
              <i
                :class="iconinfo"
                v-b-tooltip.righttop.hover
                :title="fullimpression"
              ></i>
            </div>
            <div class="imp-rev-container" v-if="showRevenue" tabindex="0">
              <span
                class="lead text-center text-danger cursor-padding"
                tabindex="0"
                >{{ currency }}{{ revenue }}</span
              >
              <i
                :class="iconinfo"
                v-b-tooltip.righttop.hover
                :title="fullrevenue"
              ></i>
            </div>
          </div>
        </div>
      </div>
      <div class="float-right">
        <a
          :class="[
            showImpression ? 'text-primary active-impression' : '',
            'text-uppercase font-weight-semibold ml-2 mb-0',
          ]"
          href="#"
          @mouseover="changeOverImpRev('impression')"
          >Impressions</a
        >
        <a
          :class="[
            showRevenue ? 'text-danger active-revenue' : '',
            'text-uppercase font-weight-semibold ml-2 mb-0',
          ]"
          href="#"
          @mouseover="changeOverImpRev('revenue')"
          >Revenue</a
        >
      </div>
      <!-- <h1>Last Month</h1>
      <i :class="icon"/>
      <p class="card-text font-weight-semibold mb-0">{{ title }}</p>
      <p class="lead text-center">{{ value }}</p> -->
    </b-card>
  </div>
</template>
<script>
// import 'bootstrap/dist/css/bootstrap.css'
import "bootstrap-vue/dist/bootstrap-vue.css";

export default {
  props: {
    maintitle: {
      type: String,
      default: "Last month",
    },
    title: {
      type: String,
      default: "simple-icon-event",
    },
    icon: {
      type: String,
      default: "simple-icon-event",
    },
    iconinfo: {
      type: String,
      default: "simple-icon-info",
    },
    impression: {
      type: String,
      default: 0,
    },
    revenue: {
      type: String,
      default: 0,
    },
    fullimpression: {
      type: String,
      default: 0,
    },
    fullrevenue: {
      type: String,
      default: 0,
    },
    currency: {
      type: String,
      default: "USD",
    },
  },
  data() {
    return {
      showImpression: true,
      showRevenue: false,
    };
  },
  methods: {
    changeOverImpRev(type) {
      if (type === "impression") {
        this.showImpression = true;
        this.showRevenue = false;
      }
      if (type === "revenue") {
        this.showImpression = false;
        this.showRevenue = true;
      }
    },
  },
};
</script>

<style type="text/css">
.text-danger {
  color: #fb6442 !important;
}

.active-impression {
  border-bottom: 5px solid #922c88;
}

.active-revenue {
  border-bottom: 5px solid #fb6442;
}

a:hover {
  color: none !important;
  text-decoration: none !important;
}

.text-primary {
  color: #922c88 !important;
}

.cursor-padding {
  padding: 0px 5px 0px 20px;
}

.imp-rev-container {
  cursor: pointer;
  display: inline-block;
}
</style>
