<template>
  <iframe :src="iframesrc" height="700px" width="100%" frameborder="0">
  </iframe>
</template>
<script>
import axios from "axios";
import VueAxios from "vue-axios";
import { mapGetters, mapMutations, mapActions } from "vuex";
export default {
  name: "Assertive",
  data() {
    return {
      params: null,
      iframesrc: null,
      pubInfo: [],
      asrtqp: "",
    };
  },
  created() {
    this.params = this.$route.params;
    this.getPubDashInfo();
  },
  computed: {
    ...mapGetters({
      currentUser: "currentUser",
    }),
  },
  mounted() {},
  methods: {
    getPubDashInfo() {
      axios
        .post(process.env.VUE_APP_BASE_URL + "reports/v1/pub-get-dash-info/")
        .then((response) => {
          if (response.data.status) {
            for (var i = 0; i < response.data.data.length; i++) {
              this.pubInfo.push(response.data.data[i]);
            }
            this.generateIframeUrl();
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    generateIframeUrl() {
      for (var pub of this.pubInfo) {
        if (pub.domain_id == this.params.domainId) {
          this.asrtqp = pub.assertive_yield_qp;
        }
      }
      this.iframesrc =
        "https://pba.automatad.com/embed/view/" +
        this.params.type +
        "?" +
        this.asrtqp +
        "";
    },
  },
};
</script>
