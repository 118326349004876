var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sidebar",on:{"mouseenter":function($event){_vm.isMenuOver = true},"mouseleave":function($event){_vm.isMenuOver = false},"touchstart":function($event){_vm.isMenuOver = true}}},[_c('div',{staticClass:"main-menu menu-hidden menu-sub-hidden"},[_c('vue-perfect-scrollbar',{staticClass:"scroll",attrs:{"settings":{ suppressScrollX: true, wheelPropagation: false }}},[_c('ul',{staticClass:"list-unstyled"},[(_vm.isShowAllTab)?_c('router-link',{class:{ active: _vm.selectedParentMenu === 'dashboards' },attrs:{"to":'/app/dashboards/default/' +
              _vm.$route.params.domainId +
              '/' +
              _vm.$route.params.asrtId,"tag":"li"}},[_c('a',[_vm._v(" Dashboard")])]):_vm._e(),(
              _vm.$route.params.domainId != 'null' && _vm.$route.params.asrtId != null
            )?_c('div',[(_vm.isShowAllTab)?_c('router-link',{class:{
                active: _vm.selectedParentMenu === 'revenue',
              },attrs:{"to":'/app/revenue/default/' +
                _vm.$route.params.domainId +
                '/' +
                _vm.$route.params.asrtId,"tag":"li"}},[_c('a',[_vm._v("Revenue")])]):_vm._e(),(_vm.isShowAllTab)?_c('router-link',{staticClass:"insight-tab",class:{
                active: _vm.selectedParentMenu === 'insights',
              },attrs:{"to":'/app/insights/default/' +
                _vm.$route.params.domainId +
                '/' +
                _vm.$route.params.asrtId,"tag":"li"}},[_c('a',[_vm._v("Insights")])]):_vm._e(),(_vm.isShowAllTab)?_c('router-link',{staticClass:"insight-tab",class:{
                active: _vm.selectedParentMenu === 'accountDetails',
              },attrs:{"to":'/app/accountDetails/default/',"tag":"li"}},[_c('a',[_vm._v("Account Details")])]):_vm._e(),(_vm.isShowAllTab)?_c('router-link',{staticClass:"insight-tab",class:{
                active: _vm.selectedParentMenu === 'ads.txt',
              },attrs:{"to":'/app/ads.txt/default/' + _vm.$route.params.domainId,"tag":"li"}},[_c('a',[_vm._v("Ads.txt")])]):_vm._e(),(_vm.isShowAllTab)?_c('router-link',{staticClass:"insight-tab",class:{
                active: _vm.selectedParentMenu === 'BrandSafety',
              },attrs:{"to":'/app/BrandSafety/default/' + _vm.$route.params.domainId,"tag":"li"}},[_c('a',[_vm._v("Brand Safety")])]):_vm._e(),([401, 3, 204, 355].includes(_vm.pubid))?_c('router-link',{staticClass:"insight-tab",class:{
                active: _vm.selectedParentMenu === 'BlockAxt',
              },attrs:{"to":'/app/BlockAxt/default/' + _vm.$route.params.domainId,"tag":"li"}},[_c('a',[_vm._v("Block AXT")])]):_vm._e(),([436].includes(_vm.pubid))?_c('router-link',{staticClass:"insight-tab",class:{
                active: _vm.selectedParentMenu === 'BlockAxt',
              },attrs:{"to":'/app/BlockAxt/default/' + _vm.$route.params.domainId,"tag":"li"}},[_c('a',[_vm._v("Enable AXT")])]):_vm._e(),(_vm.amp_ss_enable && _vm.isShowAllTab)?_c('router-link',{staticClass:"insight-tab",class:{
                active: _vm.selectedParentMenu === 'AmpSetup',
              },attrs:{"to":'/app/ampsetup/default/' + _vm.$route.params.domainId,"tag":"li"}},[_c('a',[_vm._v("AMP set up")])]):_vm._e(),(
                _vm.selectedParentMenu != 'revenue' &&
                _vm.$route.params.asrtId != 'null' &&
                _vm.$route.params.asrtId != null &&
                _vm.$route.params.asrtId != 'undefined'
              )?_c('div',[_c('div',{staticClass:"insight-menu"},[_c('li',{class:{
                    active: _vm.selectedParentMenu === 'demand',
                  }},[_c('a',{staticClass:"insight-tree-parent",attrs:{"href":"#demand"}},[_vm._v(" Demand ")]),_vm._l((_vm.demandMenuList),function(demand,index){return _c('div',{key:index,staticClass:"sub-tree"},[_c('router-link',{attrs:{"to":'/app/assertive/default/' +
                        demand.key +
                        '/' +
                        _vm.$route.params.domainId +
                        '/' +
                        _vm.$route.params.asrtId}},[_c('p',[_vm._v(_vm._s(demand.name))])])],1)})],2),_c('li',{class:{
                    active: _vm.selectedParentMenu === 'adServer',
                  }},[_c('a',{staticClass:"insight-tree-parent",attrs:{"href":"#adServer"}},[_vm._v(" Ad Server ")]),_vm._l((_vm.adServerMenuList),function(adMenu,index){return _c('div',{key:index,staticClass:"sub-tree"},[_c('router-link',{attrs:{"to":'/app/assertive/default/' +
                        adMenu.key +
                        '/' +
                        _vm.$route.params.domainId +
                        '/' +
                        _vm.$route.params.asrtId}},[_c('p',[_vm._v(_vm._s(adMenu.name))])])],1)})],2),_c('li',{class:{
                    active: _vm.selectedParentMenu === 'implementation',
                  }},[_c('a',{staticClass:"insight-tree-parent",attrs:{"href":"#implementation"}},[_vm._v(" Implementation ")]),_vm._l((_vm.implementation),function(imp,index){return _c('div',{key:index,staticClass:"sub-tree"},[_c('router-link',{attrs:{"to":'/app/assertive/default/' +
                        imp.key +
                        '/' +
                        _vm.$route.params.domainId +
                        '/' +
                        _vm.$route.params.asrtId}},[_c('p',[_vm._v(_vm._s(imp.name))])])],1)})],2),_c('li',{class:{
                    active: _vm.selectedParentMenu === 'custom',
                  }},[_c('a',{staticClass:"insight-tree-parent",attrs:{"href":"#custom"}},[_vm._v(" Custom ")]),_vm._l((_vm.custom),function(cus,index){return _c('div',{key:index,staticClass:"sub-tree"},[_c('router-link',{attrs:{"to":'/app/assertive/default/' +
                        cus.key +
                        '/' +
                        _vm.$route.params.domainId +
                        '/' +
                        _vm.$route.params.asrtId}},[_c('p',[_vm._v(_vm._s(cus.name))])])],1)})],2),_c('li',{class:{
                    active: _vm.selectedParentMenu === 'user',
                  }},[_c('a',{staticClass:"insight-tree-parent",attrs:{"href":"#user"}},[_vm._v(" User ")]),_vm._l((_vm.user),function(us,index){return _c('div',{key:index,staticClass:"sub-tree"},[_c('router-link',{attrs:{"to":'/app/assertive/default/' +
                        us.key +
                        '/' +
                        _vm.$route.params.domainId +
                        '/' +
                        _vm.$route.params.asrtId}},[_c('p',[_vm._v(_vm._s(us.name))])])],1)})],2),_c('li',{class:{
                    active: _vm.selectedParentMenu === 'source',
                  }},[_c('a',{staticClass:"insight-tree-parent",attrs:{"shref":"#source"}},[_vm._v(" Source ")]),_vm._l((_vm.source),function(src,index){return _c('div',{key:index,staticClass:"sub-tree"},[_c('router-link',{attrs:{"to":'/app/assertive/default/' +
                        src.key +
                        '/' +
                        _vm.$route.params.domainId +
                        '/' +
                        _vm.$route.params.asrtId}},[_c('p',[_vm._v(_vm._s(src.name))])])],1)})],2),_c('li',{class:{
                    active: _vm.selectedParentMenu === 'content',
                  }},[_c('a',{staticClass:"insight-tree-parent",attrs:{"href":"#content"}},[_vm._v(" Content ")]),_vm._l((_vm.content),function(con,index){return _c('div',{key:index,staticClass:"sub-tree"},[_c('router-link',{attrs:{"to":'/app/assertive/default/' +
                        con.key +
                        '/' +
                        _vm.$route.params.domainId +
                        '/' +
                        _vm.$route.params.asrtId}},[_c('p',[_vm._v(_vm._s(con.name))])])],1)})],2)])]):_vm._e()],1):_c('div',[(_vm.isShowAllTab)?_c('router-link',{class:{
                active: _vm.selectedParentMenu === 'revenue',
              },attrs:{"to":'/app/revenue/default/',"tag":"li"}},[_c('a',[_vm._v("Revenue")])]):_vm._e(),(_vm.isShowAllTab)?_c('router-link',{staticClass:"insight-tab",class:{
                active: _vm.selectedParentMenu === 'ads.txt',
              },attrs:{"to":'/app/ads.txt/default/',"tag":"li"}},[_c('a',[_vm._v("Ads.txt")])]):_vm._e(),(_vm.isShowAllTab)?_c('router-link',{staticClass:"insight-tab",class:{
                active: _vm.selectedParentMenu === 'BrandSafety',
              },attrs:{"to":'/app/BrandSafety/default/',"tag":"li"}},[_c('a',[_vm._v("Brand Safety")])]):_vm._e(),([401, 3, 204, 355].includes(_vm.pubid))?_c('router-link',{staticClass:"insight-tab",class:{
                active: _vm.selectedParentMenu === 'BlockAxt',
              },attrs:{"to":'/app/BlockAxt/default/',"tag":"li"}},[_c('a',[_vm._v("Block AXT")])]):_vm._e(),([436].includes(_vm.pubid))?_c('router-link',{staticClass:"insight-tab",class:{
                active: _vm.selectedParentMenu === 'BlockAxt',
              },attrs:{"to":'/app/BlockAxt/default/',"tag":"li"}},[_c('a',[_vm._v("Enable AXT")])]):_vm._e(),(_vm.isShowAllTab)?_c('router-link',{staticClass:"insight-tab",class:{
                active: _vm.selectedParentMenu === 'insights',
              },attrs:{"to":'/app/insights/default/',"tag":"li"}},[_c('a',[_vm._v("Insights")])]):_vm._e(),(_vm.amp_ss_enable && _vm.isShowAllTab)?_c('router-link',{class:{
                active: _vm.selectedParentMenu === 'AmpSetup',
              },attrs:{"to":'/app/ampsetup/default/',"tag":"li"}},[_c('a',[_vm._v("AMP set up")])]):_vm._e(),(_vm.isShowAllTab)?_c('router-link',{class:{
                active: _vm.selectedParentMenu === 'contract',
              },attrs:{"to":'/app/contract/default/',"tag":"li"}},[_c('a',[_vm._v("Account Info")])]):_vm._e(),(_vm.isShowAllTab)?_c('router-link',{staticClass:"insight-tab",class:{
                active: _vm.selectedParentMenu === 'accountDetails',
              },attrs:{"to":'/app/accountDetails/default/',"tag":"li"}},[_c('a',[_vm._v("Account Details")])]):_vm._e(),(
                _vm.selectedParentMenu != 'revenue' &&
                _vm.$route.params.asrtId != 'null' &&
                _vm.$route.params.asrtId != null &&
                _vm.$route.params.asrtId != 'undefined'
              )?_c('div',[_c('div',{staticClass:"insight-menu"},[_c('li',{class:{
                    active: _vm.selectedParentMenu === 'demand',
                  }},[_c('a',{staticClass:"insight-tree-parent",attrs:{"href":"#demand"}},[_vm._v(" Demand ")]),_vm._l((_vm.demandMenuList),function(demand,index){return _c('div',{key:index,staticClass:"sub-tree"},[_c('router-link',{attrs:{"to":'/app/assertive/default/' +
                        demand.key +
                        '/' +
                        _vm.$route.params.domainId +
                        '/' +
                        _vm.$route.params.asrtId}},[_c('p',[_vm._v(_vm._s(demand.name))])])],1)})],2),_c('li',{class:{
                    active: _vm.selectedParentMenu === 'adServer',
                  }},[_c('a',{staticClass:"insight-tree-parent",attrs:{"href":"#adServer"}},[_vm._v(" Ad Server ")]),_vm._l((_vm.adServerMenuList),function(adMenu,index){return _c('div',{key:index,staticClass:"sub-tree"},[_c('router-link',{attrs:{"to":'/app/assertive/default/' +
                        adMenu.key +
                        '/' +
                        _vm.$route.params.domainId +
                        '/' +
                        _vm.$route.params.asrtId}},[_c('p',[_vm._v(_vm._s(adMenu.name))])])],1)})],2),_c('li',{class:{
                    active: _vm.selectedParentMenu === 'implementation',
                  }},[_c('a',{staticClass:"insight-tree-parent",attrs:{"href":"#implementation"}},[_vm._v(" Implementation ")]),_vm._l((_vm.implementation),function(imp,index){return _c('div',{key:index,staticClass:"sub-tree"},[_c('router-link',{attrs:{"to":'/app/assertive/default/' +
                        imp.key +
                        '/' +
                        _vm.$route.params.domainId +
                        '/' +
                        _vm.$route.params.asrtId}},[_c('p',[_vm._v(_vm._s(imp.name))])])],1)})],2),_c('li',{class:{
                    active: _vm.selectedParentMenu === 'custom',
                  }},[_c('a',{staticClass:"insight-tree-parent",attrs:{"href":"#custom"}},[_vm._v(" Custom ")]),_vm._l((_vm.custom),function(cus,index){return _c('div',{key:index,staticClass:"sub-tree"},[_c('router-link',{attrs:{"to":'/app/assertive/default/' +
                        cus.key +
                        '/' +
                        _vm.$route.params.domainId +
                        '/' +
                        _vm.$route.params.asrtId}},[_c('p',[_vm._v(_vm._s(cus.name))])])],1)})],2),_c('li',{class:{
                    active: _vm.selectedParentMenu === 'user',
                  }},[_c('a',{staticClass:"insight-tree-parent",attrs:{"href":"#user"}},[_vm._v(" User ")]),_vm._l((_vm.user),function(us,index){return _c('div',{key:index,staticClass:"sub-tree"},[_c('router-link',{attrs:{"to":'/app/assertive/default/' +
                        us.key +
                        '/' +
                        _vm.$route.params.domainId +
                        '/' +
                        _vm.$route.params.asrtId}},[_c('p',[_vm._v(_vm._s(us.name))])])],1)})],2),_c('li',{class:{
                    active: _vm.selectedParentMenu === 'source',
                  }},[_c('a',{staticClass:"insight-tree-parent",attrs:{"shref":"#source"}},[_vm._v(" Source ")]),_vm._l((_vm.source),function(src,index){return _c('div',{key:index,staticClass:"sub-tree"},[_c('router-link',{attrs:{"to":'/app/assertive/default/' +
                        src.key +
                        '/' +
                        _vm.$route.params.domainId +
                        '/' +
                        _vm.$route.params.asrtId}},[_c('p',[_vm._v(_vm._s(src.name))])])],1)})],2),_c('li',{class:{
                    active: _vm.selectedParentMenu === 'content',
                  }},[_c('a',{staticClass:"insight-tree-parent",attrs:{"href":"#content"}},[_vm._v(" Content ")]),_vm._l((_vm.content),function(con,index){return _c('div',{key:index,staticClass:"sub-tree"},[_c('router-link',{attrs:{"to":'/app/assertive/default/' +
                        con.key +
                        '/' +
                        _vm.$route.params.domainId +
                        '/' +
                        _vm.$route.params.asrtId}},[_c('p',[_vm._v(_vm._s(con.name))])])],1)})],2)])]):_vm._e()],1)],1)])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }