<style scoped>
.chartdiv {
  /* background-color: #30303d;  */
  /* color: #fff; */
  width: 100%;
  height: 270px;
}
</style>
<template>
  <div class="chartdiv" ref="chartdiv"></div>
</template>
<script>
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
am4core.useTheme(am4themes_animated);

export default {
  name: "PieChart",
  data() {
    return {};
  },
  props: ["data"],
  mounted() {
    this.renderReportChart();
  },
  beforeDestroy() {
    if (this.chart) {
      this.chart.dispose();
    }
  },
  methods: {
    renderReportChart() {
      let chart = am4core.create(this.$refs.chartdiv, am4charts.PieChart);
      var pieSeries = chart.series.push(new am4charts.PieSeries());
      pieSeries.dataFields.value = "data";
      pieSeries.dataFields.category = "labels";
      let label = pieSeries.labels.template;
      if (window.innerWidth < 1311) {
        label.maxWidth = 90;
        label.wrap = true;
      }
      chart.innerRadius = am4core.percent(30);
      pieSeries.colors.list = [
        am4core.color("#375E97"),
        am4core.color("#FB6542"),
        am4core.color("#FFBB00"),
        am4core.color("#3F681C"),
        am4core.color("#004445"),
      ];

      pieSeries.slices.template.stroke = am4core.color("#fff");
      pieSeries.slices.template.strokeWidth = 2;
      pieSeries.slices.template.strokeOpacity = 1;
      pieSeries.slices.template.cursorOverStyle = [
        {
          property: "cursor",
          value: "pointer",
        },
      ];

      pieSeries.alignLabels = true;
      pieSeries.labels.template.bent = false;
      pieSeries.labels.template.radius = 3;
      pieSeries.labels.template.padding(0, 0, 0, 0);

      pieSeries.ticks.template.disabled = false;

      var shadow = pieSeries.slices.template.filters.push(
        new am4core.DropShadowFilter()
      );
      shadow.opacity = 0;

      var hoverState = pieSeries.slices.template.states.getKey("hover");
      var hoverShadow = hoverState.filters.push(new am4core.DropShadowFilter());
      hoverShadow.opacity = 0.7;
      hoverShadow.blur = 5;

      chart.legend = new am4charts.Legend();
      chart.data = this.data;

      // console.log(chart);
    },
  },
  watch: {
    data: function () {
      if (this.chart) {
        this.chart.dispose();
      }
      this.renderReportChart();
    },
  },
};
</script>
