<style scoped>
.blockurl {
  display: inline-block;
  border: 1px solid black;
  padding: 10px;
  background: #f7f6f6;
}

.loader {
  border: 5px solid #f3f3f3;
  border-radius: 50%;
  /* border-top: 16px solid blue; */
  /* border-right: 16px solid green; */
  border-bottom: 5px solid green;
  width: 25px;
  height: 25px;
  -webkit-animation: spin-data-v-1eb20100 2s linear infinite;
  animation: spin-data-v-1eb20100 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>

<template>
  <div>
    <b-row>
      <b-colxx xxs="12" lg="12" md="12">
        <h1>Brand Safety</h1>

        <hr />
        <b-card class="mt-4" no-body>
          <b-card-body>
            <b-tabs v-model="tabIndex" card>
              <b-tab title="Block Urls" title-link-class="linkClass(0)">
                <div v-if="isselect">
                  <div class="row">
                    <div class="col-md-12">
                      <p>
                        Add below the URLs to be blocked (each URL in separate
                        line)
                      </p>

                      <b-form-textarea
                        id="textarea"
                        v-model="blockUrl"
                        placeholder="http://"
                        rows="3"
                        max-rows="6"
                      ></b-form-textarea>
                    </div>
                    <div class="col-md-12">
                      <button
                        type="success"
                        style="margin: 10px 0px 10px 0px; padding: 10px 20px"
                        class="btn btn-success"
                        @click="addblockUrl"
                      >
                        + Save Blockurls
                      </button>
                      <div
                        v-if="isSaveBlockurlLoader"
                        class="loader"
                        style="display: inline-block; margin-left: 15px"
                      ></div>
                    </div>
                  </div>
                  <div class="row" v-if="blockUrlsList.length != 0">
                    <div class="col-md-2">
                      <p>Blocked Urls:</p>
                    </div>

                    <div class="col-md-10">
                      <div style="float: right">
                        <b-button
                          style=""
                          type="submit"
                          variant="primary"
                          size="lg"
                          class="btn-shadow"
                          @click="deleteBlockurls(0)"
                          >Delete SelectedUrls</b-button
                        >
                        <b-button
                          type="submit"
                          size="lg"
                          style="background: red"
                          v-b-modal.modal-1
                          >Delete AllUrls</b-button
                        >
                      </div>
                    </div>

                    <div class="col-md-12">
                      <div>
                        <div v-for="(url, index) in blockUrlsList" :key="index">
                          <div
                            class="table-secondary"
                            style="
                              display: inline-block;
                              margin: 10px 10px 0px 0px !important;
                              padding: 10px;
                              background: #f7f6f6;
                              border: 1px solid black;
                              width: 100%;
                            "
                          >
                            <a
                              :href="url.url"
                              target="_blank"
                              v-if="url.url.length != 0"
                            >
                              <input
                                type="checkbox"
                                style="float: right"
                                :id="url.id"
                                :value="url.id"
                                v-model="selectedBlockurls"
                              />
                              <p
                                style="
                                  white-space: nowrap;
                                  overflow: hidden;
                                  text-overflow: ellipsis;
                                  max-width: 100%;
                                  margin: 0px !important;
                                "
                              >
                                {{ url.url }}
                              </p>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-else>
                  <div style="color: red">
                    Please select a domain to access Block Urls and Block
                    Categories.
                  </div>
                </div>
              </b-tab>
              <b-tab title="Block Categories" title-link-class="linkClass(1)">
                <div v-if="isselect">
                  <div class="col-md-6">
                    <h6 style="color: #9c9797"><b>Block Categories</b></h6>

                    <div role="tablist">
                      <b-card
                        no-body
                        class="mb-1"
                        v-for="(category, index) in categories"
                        :key="index"
                      >
                        <b-card-header
                          header-tag="header"
                          class="p-1"
                          role="tab"
                        >
                          <b-button
                            block
                            v-b-toggle="'accordion-' + index"
                            variant="info"
                            style="
                              border-radius: 3px;
                              background: #ded7d7;
                              color: black;
                            "
                          >
                            <input
                              type="checkbox"
                              style="float: left"
                              :id="category.id"
                              :value="category.id"
                              v-model="selectedParentcategory"
                              @click="parentCheckboxClick(category)"
                            />{{ category.name }}</b-button
                          >
                        </b-card-header>
                        <b-collapse
                          :id="'accordion-' + index"
                          visible
                          accordion="my-accordion"
                          role="tabpanel"
                        >
                          <b-card-body>
                            <b-card-text
                              v-for="(child, index) in category.child_unit"
                              :key="index"
                            >
                              <label
                                ><input
                                  type="checkbox"
                                  :id="child.id"
                                  :value="child.id"
                                  v-model="selectedChildCategories"
                                  @change="childCheckboxClick(child, category)"
                                />{{ " " + child.name }}</label
                              >
                            </b-card-text>
                          </b-card-body>
                        </b-collapse>
                      </b-card>
                    </div>
                  </div>
                  <div>
                    <b-button
                      type="submit"
                      variant="primary"
                      size="lg"
                      class="btn-shadow"
                      @click="addBlockCategory"
                      >+Save Block Categories</b-button
                    >
                    <div
                      v-if="isSavedBlockCategoryLoader"
                      class="loader"
                      style="display: inline-block; margin-left: 15px"
                    ></div>
                  </div>
                </div>
                <div v-else>
                  <div style="color: red">
                    Please select a domain to access Block Urls and Block
                    Categories.
                  </div>
                </div>
              </b-tab>
            </b-tabs>

            <b-modal id="modal-1" title="Delete all BlockedURls">
              <p class="my-4">Are you sure want to delete all blockedurls?</p>
              <template v-slot:modal-footer>
                <b-button
                  class="mt-3"
                  variant="outline-danger"
                  block
                  @click="deleteBlockurls(1)"
                  >OK</b-button
                >
              </template>
            </b-modal>
          </b-card-body>
        </b-card>
      </b-colxx>
    </b-row>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      domid: "",
      isselect: true,
      blockUrl: "",
      blockUrlsList: [],
      categories: [],
      tabIndex: 1,

      selectedChildCategories: [],
      selectedParentcategory: [],
      selectedBlockurls: [],

      isSaveBlockurlLoader: false,
      isSavedBlockCategoryLoader: false,
    };
  },
  created() {
    this.domid = this.$route.params.domainId;

    this.loadBrandCategoryList();
    this.getBlockList();

    this.getBlockedCategories();
  },
  methods: {
    childCheckboxClick(current_child, current_cat_of_child) {
      console.log("current_child", current_child);
      console.log("current_cat_of_child", current_cat_of_child);
      console.log("current_childselcted subcat", this.selectedChildCategories);

      let childuni = current_cat_of_child.child_unit;
      let indexOfCurrentPcat = this.selectedParentcategory.indexOf(
        current_cat_of_child.id
      );
      let childunitsids = [];

      console.log("parent id", current_child.id, "index", indexOfCurrentPcat);

      for (var j = 0; j < childuni.length; j++) {
        console.log("each child unit", childuni[j]);
        childunitsids.push(childuni[j].id);
      }

      if (!this.selectedChildCategories.includes(current_child.id)) {
        if (indexOfCurrentPcat > -1) {
          this.selectedParentcategory.splice(indexOfCurrentPcat, 1);
        }
        console.log("nahi andar", indexOfCurrentPcat);
      } else {
        console.log(
          "all sel child selectedChildCategories",
          ...this.selectedChildCategories
        );
        console.log(
          "all sel child selectedChildCategories current id",
          current_child.id
        );
        console.log("all current child units childunitsids", ...childunitsids);

        let op = childunitsids.every(
          (element) => this.selectedChildCategories.indexOf(element) > -1
        );
        if (
          op == true &&
          this.selectedParentcategory.includes(current_cat_of_child.id) == false
        ) {
          this.selectedParentcategory.push(current_cat_of_child.id);
        }
        console.log("he andar", op);
      }
    },

    parentCheckboxClick(obj) {
      console.log("##obj", obj);
      if (this.selectedParentcategory.includes(obj.id) == true) {
        console.log("&&true");
        for (var i = 0; i < obj.child_unit.length; i++) {
          let index = this.selectedChildCategories.indexOf(
            obj.child_unit[i].id
          );
          console.log("index", index);
          console.log("indexcatbefore", this.selectedChildCategories.length);
          if (index > -1) {
            this.selectedChildCategories.splice(index, 1);
          }
          console.log("indexcatafter", this.selectedChildCategories.length);
        }
      } else if (this.selectedParentcategory.includes(obj.id) == false) {
        console.log("&&false");
        for (var i = 0; i < obj.child_unit.length; i++) {
          let index = this.selectedChildCategories.indexOf(
            obj.child_unit[i].id
          );
          console.log("index", index);
          console.log("indexcatbefore", this.selectedChildCategories.length);
          if (index > -1) {
            this.selectedChildCategories.splice(index, 1);
          }
          this.selectedChildCategories.push(obj.child_unit[i].id);
        }
      }
    },

    getBlockedCategories() {
      var urlCate =
          process.env.VUE_APP_BASE_URL + `v1/brand-safety-block-category-list/domain/${this.domid}/`;

      axios.get(urlCate).then((res) => {
        console.log("newrescate", typeof res.data);
        console.log("newrescate", res.data.block_categories);
        for (var i = 0; i < res.data.block_categories.length; i++) {
          console.log("newrescatesingle", res.data.block_categories[i]);
          this.selectedChildCategories.push(res.data.block_categories[i]);
        }
        for (var i = 0; i < res.data.blockedParentCat.length; i++) {
          console.log("newrescatesingle", res.data.blockedParentCat[i]);
          this.selectedParentcategory.push(res.data.blockedParentCat[i]);
        }
      });
    },
    addBlockCategory() {
      var urlCate =
        process.env.VUE_APP_BASE_URL + `v1/brand-safety-block-category-list/domain/${this.domid}/`;
      var objCat = {
        blockedParentCat: this.selectedParentcategory,
        block_categories: this.selectedChildCategories,
      };

      console.log(
        "**",
        objCat.blockedParentCat.length,
        "*",
        objCat.blockedParentCat.length
      );

      this.isSavedBlockCategoryLoader = true;

      axios.post(urlCate, objCat).then((res) => {
        this.isSavedBlockCategoryLoader = false;
        console.log("newrescate", res);
        this.selectedChildCategories = [];
        this.selectedParentcategory = [];
        this.getBlockedCategories();

        this.$notify("success", "success", "Category blocked successfully.", {
          duration: 3000,
          permanent: false,
        });
      });
    },
    deleteBlockurls(flag) {
      var deleteUrl =
        process.env.VUE_APP_BASE_URL + `v1/delete-brand-safety-block-url/domain/${this.domid}/`;
      if (flag == 1) {
        var x = {
          block_url_id: [],
          delete: "all",
        };
      } else {
        if (this.selectedBlockurls.length == 0) {
          this.$notify("error", "error", "Please select blockedurls.", {
            duration: 3000,
            permanent: false,
          });
          return;
        }
        var x = {
          block_url_id: this.selectedBlockurls,
          delete: "partial",
        };
      }

      axios.post(deleteUrl, x).then((res) => {
        console.log(res);
        this.blockUrlsList = [];
        this.selectedBlockurls = [];
        this.getBlockList();
        this.$bvModal.hide("modal-1");
      });
    },

    getBlockList() {
      console.log("domid length", this.domid);
      if (this.domid == undefined) {
        //console.log("plz select domain");
        this.isselect = false;
        return;
      }
      console.log("undeselct", this.isselect);

      var api =
        process.env.VUE_APP_BASE_URL + `v1/brand-safety-block-url-list/domain/${this.domid}/`;
      axios.get(api).then((res) => {
        console.log("newresof ", res.data, "len", res.data.data.length);

        for (var i = 0; i < res.data.data.length; i++) {
          console.log(
            "newar",
            res.data.data.length,
            "newarnot length",
            res.data.data[i]
          );
          if (res.data.data[i].block_url.length != 0) {
            console.log(
              "blockurls",
              res.data.data[i].block_url.length,
              res.data.data[i].block_url
            );
            // let url =  res.data.data[i].block_url;
            // let id = res.data.data[i].id;
            let obj = {
              url: res.data.data[i].block_url,
              id: res.data.data[i].id,
            };
            this.blockUrlsList.push(obj);
          }
        }
      });
    },
    addblockUrl() {
      console.log("domid", this.domid);
      console.log("block", this.block_url);

      var api =
        process.env.VUE_APP_BASE_URL + `v1/brand-safety-block-url-list/domain/${this.domid}/`;
      var obj = {
        block_url: this.blockUrl,
      };
      console.log("objblock", obj.block_url.length);

      if (obj.block_url.length == 0) {
        this.$notify("error", "error", "Please enter blockurl", {
          duration: 3000,
          permanent: false,
        });
        return;
      }

      this.isSaveBlockurlLoader = true;

      axios.post(api, obj).then((res) => {
        console.log("respost", res);
        this.isSaveBlockurlLoader = false;

        this.blockUrlsList = [];
        this.getBlockList();
        this.blockUrl = "";
      });
    },

    loadBrandCategoryList() {
      console.log("domid length", this.domid);
      if (this.domid == undefined) {
        console.log("undefine", this.domid);
        this.isselect = false;
        return;
      }
      console.log("undeselct", this.isselect);
      var api = process.env.VUE_APP_BASE_URL + `v1/block-category-list/`;

      axios.get(api).then((res) => {
        console.log("@@cat res", res.data);
        console.log("@@cat res", typeof res.data);
        this.categories = [];
        this.categories = res.data;
        console.log("@@cat length", this.categories.length);
        for (var i = 0; i < this.categories.length; i++) {
          console.log("@@catsub", this.categories[i]);
        }
        console.log("@@cat", Object.entries(this.categories));
        for (var cat in Object.entries(this.categories)) {
          console.log("@@cat", cat, "typeof", typeof cat);
        }
      });
    },
  },
};
</script>
