import AuthRequired from "./authRequired";

import Error from "@/pages/Error";
import User from "@/pages/user";
import UserLogin from "@/pages/user/Login";
import UserForgotPassword from "@/pages/user/ForgotPassword";

import App from "@/pages/app";

import Dashboards from "@/pages/app/dashboards";
import DashboardsDefault from "@/pages/app/dashboards/Default";
import Revenue from "@/pages/app/dashboards/Revenue";
import Assertive from "@/pages/app/dashboards/Assertive";
import Insights from "@/pages/app/dashboards/Insights";
import contract from "@/pages/app/dashboards/contract";
import AdsText from "@/pages/app/dashboards/AdsText";
import AmpSetup from "@/pages/app/dashboards/AmpSetup";
import BrandSafety from "@/pages/app/dashboards/BrandSafety";
import BlockAxt from "@/pages/app/dashboards/BlockAxt";

import accountDetails from "@/pages/app/dashboards/accountEdit";

import ResetPassword from "@/pages/user/ResetPassword";
import AccountActivate from "@/pages/user/AccountActivate";

const routes = [
  {
    path: "/error",
    component: Error,
  },
  {
    path: "/",
    redirect: "/app",
  },
  {
    path: "/user",
    component: User,
    redirect: "/user/login",
    children: [
      { path: "login", component: UserLogin },
      { path: "forgot-password", component: UserForgotPassword },
      { path: "reset/:id/:resetID", component: ResetPassword },
      { path: "activate/reset/:id/:resetID", component: AccountActivate },
    ],
  },
  {
    path: "/app",
    component: App,
    redirect: "/app/dashboards",
    beforeEnter: AuthRequired,
    children: [
      {
        path: "dashboards",
        name: "dashboards",
        component: Dashboards,
        redirect: "/app/dashboards/default",
        children: [
          { path: "default", component: DashboardsDefault },
          { path: "default/:domainId", component: DashboardsDefault },
        ],
      },
      {
        path: "contract",
        component: Dashboards,
        redirect: "/app/contract/default",
        children: [{ path: "default", component: contract }],
      },
      {
        path: "accountDetails",
        component: Dashboards,
        redirect: "/app/accountDetails/default",
        children: [{ path: "default", component: accountDetails }],
      },
      {
        path: "ads.txt",
        component: Dashboards,
        redirect: "/app/ads.txt/default",
        children: [
          { path: "default", component: AdsText },
          { path: "default/:domainId", component: AdsText },
        ],
      },
      {
        path: "AmpSetup",
        component: Dashboards,
        redirect: "/app/ampsetup/default",
        children: [
          { path: "default", component: AmpSetup },
          { path: "default/:domainId", component: AmpSetup },
        ],
      },

      {
        path: "BrandSafety",
        component: Dashboards,
        redirect: "/app/BrandSafety/default",
        children: [
          { path: "default", component: BrandSafety },
          { path: "default/:domainId", component: BrandSafety },
        ],
      },
      {
        path: "BlockAxt",
        name: "BlockAxt",
        component: Dashboards,
        redirect: "/app/BlockAxt/default",
        children: [
          { path: "default", component: BlockAxt },
          { path: "default/:domainId", component: BlockAxt },
        ],
      },
      {
        path: "revenue",
        component: Dashboards,
        redirect: "/app/revenue/default",
        children: [
          { path: "default", component: Revenue },
          { path: "default/:domainId", component: Revenue },
          { path: "default/:domainId/:asrtId", component: Revenue },
        ],
      },
      {
        path: "assertive",
        component: Dashboards,
        redirect: "/app/assertive/default",
        children: [
          { path: "default/:type/:domainId/:asrtId", component: Assertive },
        ],
      },

      {
        path: "insights",
        component: Dashboards,
        redirect: "/app/insights/default",
        children: [
          { path: "default", component: Insights },
          { path: "default/:domainId", component: Insights },
          { path: "default/:domainId/:asrtId", component: Insights },
        ],
      },
    ],
  },
  {
    path: "*",
    redirect: "/app",
  },
];

export default routes;
