<template>
  <div>
    <b-row>
      <b-colxx xxs="12" lg="12" md="12">
        <h1>{{ [436].includes(pubid) ? "Enable AXT" : "Block AXT" }}</h1>

        <hr />
        <b-card class="mt-4" no-body>
          <b-card-body>
            <div v-if="isselect">
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label>Advertiser/Order/LineItem/Creative Id</label>
                    <input
                      type="text"
                      class="form-control"
                      v-validate="'numeric'"
                      name="blockId"
                      v-model="advertiserId"
                      placeholder="Enter Advertiser/Order/LineItem/Creative Id"
                    />
                    <span v-show="errors.has('blockId')" style="color: red">{{
                      errors.first("blockId")
                    }}</span>
                  </div>
                </div>
                <div class="col-md-12">
                  <button
                    type="success"
                    style="margin: 10px 0px 10px 0px; padding: 10px 20px"
                    class="btn btn-success"
                    @click="addblockIds"
                  >
                    + Save
                  </button>
                  <div
                    v-if="isSaveBlockIdsLoader"
                    class="loader"
                    style="display: inline-block; margin-left: 15px"
                  ></div>
                </div>
              </div>
              <div class="row" v-if="blockIdsList.length != 0">
                <div class="col-md-2">
                  <p>Blocked Ids:</p>
                </div>

                <div class="col-md-10">
                  <div style="float: right">
                    <b-button
                      style=""
                      type="submit"
                      variant="primary"
                      size="lg"
                      class="btn-shadow"
                      @click="deleteBlockIds()"
                      >Delete</b-button
                    >
                  </div>
                </div>

                <div class="col-md-12">
                  <div>
                    <div v-for="(url, index) in blockIdsList" :key="index">
                      <div
                        class="table-secondary"
                        style="
                          display: inline-block;
                          margin: 10px 10px 0px 0px !important;
                          padding: 10px;
                          background: #f7f6f6;
                          border: 1px solid black;
                          width: 100%;
                        "
                      >
                        <input
                          type="checkbox"
                          style="float: right"
                          :id="url.url"
                          :value="url.url"
                          v-model="selectedBlockIds"
                        />
                        <p
                          style="
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            max-width: 100%;
                            margin: 0px !important;
                          "
                        >
                          {{ url.url }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-else>
              <div style="color: red">Please select a domain</div>
            </div>
          </b-card-body>
        </b-card>
      </b-colxx>
    </b-row>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      pubid: "",
      domid: "",
      isselect: true,
      blockIds: "",
      isSaveBlockIdsLoader: false,
      blockIdsList: [],
      selectedBlockIds: [],
      advertiserId: "",
    };
  },
  created() {
    this.domid = this.$route.params.domainId;
    this.pubid = JSON.parse(localStorage.getItem("publisher"))[0].id;
    console.log("pubid", this.pubid);
    this.getData();
  },
  methods: {
    getData() {
      console.log("domid length", this.domid);
      if (this.domid == undefined) {
        this.isselect = false;
        return;
      }
      console.log("undeselct", this.isselect);

      var api = process.env.VUE_APP_BASE_URL + `v1/get_axt_direct_campaign_list/`;
      var reqObj = {
        domainId: this.domid,
      };
      axios.post(api, reqObj).then((res) => {
        console.log("newresof ", res.data.data, "len", res.data.data.length);

        for (var i = 0; i < res.data.data.length; i++) {
          console.log(
            "newar",
            res.data.data.length,
            "newarnot length",
            res.data.data[i]
          );
          if (res.data.data[i].advertiser_id.length != 0) {
            console.log(
              "blockurls",
              res.data.data[i].advertiser_id.length,
              res.data.data[i].advertiser_id
            );
            let obj = {
              url: res.data.data[i].advertiser_id,
              id: res.data.data[i].id,
            };
            this.blockIdsList.push(obj);
          }
        }
      });
    },
    deleteBlockIds() {
      var deleteUrl = process.env.VUE_APP_BASE_URL + `v1/delete_direct_campaign_block_list/`;

      var x = {
        advertiserId: this.selectedBlockIds,
        domainId: this.domid,
      };

      axios.post(deleteUrl, x).then((res) => {
        console.log(res);
        this.blockIdsList = [];
        this.selectedBlockIds = [];
        this.getData();
      });
    },
    addblockIds() {
      console.log("domid", this.domid);
      console.log("block", this.block_url);

      var api = process.env.VUE_APP_BASE_URL + `v1/set_axt_direct_campaign_block_list/`;
      var obj = {
        domainId: this.domid,
        advertiserId: this.advertiserId,
        status: true,
      };
      if (obj.advertiserId.length == 0) {
        this.$notify("error", "error", "Please enter Id", {
          duration: 3000,
          permanent: false,
        });
        return;
      }

      this.isSaveBlockIdsLoader = true;
      this.$validator.validateAll().then((result) => {
        if (result) {
          axios.post(api, obj).then((res) => {
            console.log("respost", res);
            this.isSaveBlockIdsLoader = false;

            this.blockIdsList = [];
            this.getData();
            this.advertiserId = "";
          });
        } else {
          this.$notify("error", "error", "Ids to be numeric", {
            duration: 3000,
            permanent: false,
          });
        }
      });
    },
  },
};
</script>
