<style scoped>
.contact-adops {
  color: blue;
}
.addUserBtn {
  float: right;
  margin-bottom: 10px;
}
.inActiveBtn {
  background: red !important;
}
.ActiveBtn {
  background: green !important;
}
</style>
<template>
  <div>
    <b-row>
      <b-colxx xxs="12" lg="12" md="12">
        <b-card class="mt-4" no-body>
          <b-card-body>
            <b-tabs v-model="tabIndex" card>
              <b-tab title="Update Password" title-link-class="linkClass(0)">
                <!-- <h1> Password change</h1> -->
                <form
                  class="form-horizontal"
                  v-on:submit.prevent="submitPasswordEditform"
                >
                  <div class="form-group">
                    <label>Current password</label>
                    <input
                      type="password"
                      class="form-control"
                      name="currentPassword"
                      v-model="accountEditForm.currentPassword"
                      placeholder="Enter Current Password"
                    />
                  </div>
                  <div class="form-group">
                    <label>New password</label>
                    <input
                      type="password"
                      class="form-control"
                      name="newPassword"
                      v-model="accountEditForm.newPassword"
                      placeholder="Enter New Password"
                    />
                  </div>
                  <div class="form-group">
                    <label>Confirm password</label>
                    <input
                      type="password"
                      class="form-control"
                      name="confirmPassword"
                      v-model="accountEditForm.confirmPassword"
                      placeholder="Enter Confirm  Password"
                    />
                  </div>

                  <button type="submit" class="btn btn-primary">Submit</button>
                  <div
                    v-if="msgErrorPass.length != 0"
                    style="color: red; margin-top: 10px"
                  >
                    {{ msgErrorPass }}
                  </div>
                </form>
              </b-tab>
              <b-tab title="Update Email" title-link-class="linkClass(1)">
                <form
                  class="form-horizontal"
                  v-on:submit.prevent="submitEmailEditform"
                >
                  <div class="form-group">
                    <label>Current Email</label>
                    <input
                      type="text"
                      class="form-control"
                      name="currentEmail"
                      v-model="accountEmailEditForm.currentEmail"
                      placeholder="Enter Current Email"
                      disabled
                    />
                  </div>
                  <div class="form-group">
                    <label>New Email</label>
                    <input
                      type="text"
                      class="form-control"
                      name="newEmail"
                      v-model="accountEmailEditForm.newEmail"
                      placeholder="Enter New Email"
                    />
                  </div>
                  <div class="form-group">
                    <label>Confirm Email</label>
                    <input
                      type="text"
                      class="form-control"
                      name="confirmEmail"
                      v-model="accountEmailEditForm.confirmEmail"
                      placeholder="Enter Confirm Email"
                    />
                  </div>
                  <div class="form-group">
                    <label>Current password</label>
                    <input
                      type="password"
                      class="form-control"
                      name="currentPassword"
                      v-model="accountEmailEditForm.currentPassword"
                      placeholder="Enter Current Password"
                    />
                  </div>

                  <button type="submit" class="btn btn-primary">Submit</button>
                  <div
                    v-if="msgErrorEmail.length != 0"
                    style="color: red; margin-top: 10px"
                  >
                    {{ msgErrorEmail }}
                  </div>
                </form>
              </b-tab>
              <b-tab
                title="Users"
                title-link-class="linkClass(0)"
                v-if="isMainUser"
              >
                <div>
                  <b-btn
                    variant="info"
                    @click="addUser()"
                    class="addUserBtn btn btn-shadow btn-primary btn-lg"
                  >
                    Add user
                  </b-btn>
                  <div v-if="items.length != 0">
                    <b-table :items="items" :fields="fields">
                      <template #cell(is_active)="data_is_active">
                        <div v-if="data_is_active.item.is_active">Active</div>
                        <div v-else>In Active</div>
                      </template>
                      <template #cell(actions)="data">
                        <b-btn
                          v-b-tooltip.hover
                          title="update the user"
                          variant="info"
                          @click="editUser(data.item)"
                          class="btn btn-shadow btn-primary btn-lg"
                        >
                          Edit
                        </b-btn>
                        <b-btn
                          v-b-tooltip.hover
                          title="send the password reset email"
                          variant="info"
                          @click="sentEmailToUser(data.item)"
                          class="btn btn-shadow btn-primary btn-lg"
                          style="background: blue"
                          :disabled="!data.item.is_active"
                        >
                          Send Email
                        </b-btn>

                        <b-btn
                          v-b-tooltip.hover
                          title="Deactivate"
                          variant="info"
                          @click="isActiveUser(data.item)"
                          class="btn btn-shadow btn-primary btn-lg inActiveBtn"
                          style="background: red"
                          v-if="data.item.is_active == true"
                        >
                          Deactivate
                        </b-btn>
                        <b-btn
                          v-b-tooltip.hover
                          title=" Activate"
                          variant="info"
                          @click="isActiveUser(data.item)"
                          class="btn btn-shadow btn-primary btn-lg ActiveBtn"
                          style="background: green"
                          v-if="data.item.is_active == false"
                        >
                          Activate
                        </b-btn>
                      </template>
                    </b-table>
                  </div>
                  <div v-else-if="!isForm">No User</div>
                </div>
                <div v-if="isForm">
                  <form
                    class="form-horizontal"
                    v-on:submit.prevent="submitAddUsersform"
                  >
                    <div class="form-group">
                      <label>User name</label>
                      <input
                        type="text"
                        class="form-control"
                        name="firstName"
                        v-model="addUsersform.firstName"
                        placeholder="Enter username"
                      />
                    </div>

                    <div class="form-group">
                      <label>Email</label>
                      <input
                        type="text"
                        class="form-control"
                        name="email"
                        v-model="addUsersform.email"
                        placeholder="Enter email"
                      />
                    </div>
                    <div class="form-group">
                      <label>Status</label>
                      <div class="form-group form-check">
                        <input
                          type="checkbox"
                          class="form-check-input"
                          id="exampleCheck1"
                          v-model="addUsersform.isActive"
                        />
                        <label class="form-check-label" for="exampleCheck1"
                          >Active?</label
                        >
                      </div>
                    </div>

                    <button type="submit" class="btn btn-primary" v-if="isEdit">
                      Update User
                    </button>
                    <button type="submit" class="btn btn-primary" v-else>
                      Add User
                    </button>
                    <button
                      type="submit"
                      class="btn btn-primary"
                      style="background: red"
                      @click="cancelClick"
                    >
                      Cancel
                    </button>
                    <div
                      v-if="msgErrorAddUsers.length != 0"
                      style="color: red; margin-top: 10px"
                    >
                      {{ msgErrorAddUsers }}
                    </div>
                  </form>
                </div>
              </b-tab>
            </b-tabs>
          </b-card-body>
        </b-card>
      </b-colxx>
    </b-row>
  </div>
</template>
<script>
import axios from "axios";
import { mapGetters, mapMutations, mapActions } from "vuex";
import { type } from "@amcharts/amcharts4/core";
export default {
  data() {
    return {
      isMainUser: true,
      isLoadingUsers: false,
      tabIndex: 0,
      msgErrorPass: "",
      msgErrorEmail: "",
      msgErrorAddUsers: "",
      accountEditForm: {
        currentPassword: "",
        newPassword: "",
        confirmPassword: "",
      },
      accountEmailEditForm: {
        currentEmail: "",
        newEmail: "",
        currentPassword: "",
        confirmEmail: "",
      },
      addUsersform: {
        firstName: "",
        email: "",
        isActive: true,
      },

      fields: [
        { key: "id", thClass: "d-none", tdClass: "d-none" },
        { key: "first_name", editable: true },
        { key: "email", editable: true },
        {
          key: "associated_publisher_id",
          thClass: "d-none",
          tdClass: "d-none",
        },
        { key: "is_active", editable: true, label: "status" },
        { key: "actions", class: "text-center" },
      ],
      items: [],
      isEdit: false,
      editObject: "",
      isForm: false,
    };
  },
  created() {
    this.getEmail();
    console.log("isOtherUser", this.isMainUser);
    this.isMainUser = JSON.parse(localStorage.getItem("isMainUser"));
    console.log("isOtherUser", this.isMainUser);
    this.getUsers();
  },
  computed: {
    editableFields() {
      return this.fields.filter((field) => {
        return field.editable === true;
      });
    },
  },
  methods: {
    ...mapActions(["setLang", "signOut", "allDomainsAction"]),
    cancelClick() {
      this.isForm = false;
      this.isEdit = false;
    },
    isActiveUser(obj) {
      console.log("isActiveUser");
      obj.is_active = !obj.is_active;
      var urlEditActiveStatus = process.env.VUE_APP_BASE_URL + `v1/publisher_user/${obj.id}/`;
      let reqObj = {
        email: obj.email,
        first_name: obj.first_name,
        is_active: obj.is_active,
      };
      axios
        .put(urlEditActiveStatus, reqObj)
        .then((res) => {
          this.getUsers();
          this.isForm = false;
          console.log("res", res.data);
          this.msgErrorAddUsers = "";
          this.$notify(
            "success",
            "success",
            "User successfully " +
              (obj.is_active ? "activated" : "deactivated"),
            {
              duration: 3000,
              permanent: false,
            }
          );
        })
        .catch((error) => {
          console.log(
            "@@@eror= ",
            error.response.data,
            "datatype",
            typeof error.response.data
          );
          var erroMsg = error.response.data;
          this.msgErrorAddUsers = erroMsg;
        });
    },
    sentEmailToUser(obj) {
      console.log("@@@@obj email", obj);
      var getUrl = process.env.VUE_APP_BASE_URL + `v1/publisher_user/invite/${obj.id}/`;
      axios.get(getUrl).then((res) => {
        console.log("getEmail", res.data);
        this.$notify("success", "success", "sent Email successfully", {
          duration: 3000,
          permanent: false,
        });
      });
    },
    addUser() {
      this.msgErrorAddUsers = "";
      this.addUsersform.email = "";
      this.addUsersform.firstName = "";
      this.isForm = true;
      this.isEdit = false;
      console.log("isForm", this.isForm);
    },
    editUser(obj) {
      this.msgErrorAddUsers = "";
      console.log("obj", obj);
      this.editObject = obj;
      this.addUsersform.firstName = obj.first_name;
      this.addUsersform.email = obj.email;
      this.addUsersform.isActive = obj.is_active;
      this.isEdit = true;
      this.isForm = true;
    },
    getEmail() {
      var getUrl = process.env.VUE_APP_BASE_URL + "v1/auth/email/";
      axios.get(getUrl).then((res) => {
        console.log("getEmail", res.data.email);
        this.accountEmailEditForm.currentEmail = res.data.email;
      });
    },
    getUsers() {
      var getUrlusers = process.env.VUE_APP_BASE_URL + "v1/publisher_user/";
      this.isLoadingUsers = true;
      axios
        .get(getUrlusers)
        .then((res) => {
          console.log("getUrlusers", res.data);
          this.isLoadingUsers = false;
          this.items = res.data;
        })
        .catch((error) => {
          this.isLoadingUsers = false;
          console.log("get users error", error.response.status);
          if (error.response.status == 403) {
            console.log("forbidden");
            //this user is the other user of main user so it should be allow to add the other
            // this.isOtherUser = false;
          }
        });
    },

    logout() {
      this.signOut().then(() => {
        localStorage.removeItem("default_auth_token");
        localStorage.removeItem("publisher");
        localStorage.removeItem("ltRD");
        this.$router.push("/user/login");
      });
    },
    submitAddUsersform() {
      console.log("this.editObject", this.editObject);
      if (this.isEdit) {
        var urlAddusers = process.env.VUE_APP_BASE_URL + `v1/publisher_user/${this.editObject.id}/`;
        let reqObj = {
          email: this.addUsersform.email,
          first_name: this.addUsersform.firstName,
          is_active: this.addUsersform.isActive,
        };
        axios
          .put(urlAddusers, reqObj)
          .then((res) => {
            this.getUsers();
            this.isForm = false;
            console.log("res", res.data);
            this.msgErrorAddUsers = "";
            this.$notify("success", "success", "user added successfully", {
              duration: 3000,
              permanent: false,
            });
          })
          .catch((error) => {
            console.log(
              "@@@eror= ",
              error.response.data,
              "datatype",
              typeof error.response.data
            );
            console.log("------------error");
            var finalError = "";
            let entries = Object.entries(error.response.data);
            for (const [prop, val] of entries) {
              finalError = finalError + prop + ": " + val;
              console.log(prop, val, "type of val", typeof val);
            }
            this.msgErrorAddUsers = finalError;
          });

        return;
      }
      console.log("acount add users form submit click");
      var urlAddusers = process.env.VUE_APP_BASE_URL + `v1/publisher_user/`;
      let reqObj = {
        email: this.addUsersform.email,
        first_name: this.addUsersform.firstName,
        is_active: this.addUsersform.isActive,
      };
      axios
        .post(urlAddusers, reqObj)
        .then((res) => {
          console.log("res", res.data);
          this.getUsers();
          this.isForm = false;
          this.msgErrorAddUsers = "";
          this.$notify("success", "success", "user added successfully", {
            duration: 3000,
            permanent: false,
          });
        })
        .catch((error) => {
          console.log(
            "@@@eror= ",
            error.response.data,
            "datatype",
            typeof error.response.data
          );
          console.log("------------error");
          var finalError = "";
          let entries = Object.entries(error.response.data);
          for (const [prop, val] of entries) {
            console.log(prop, val, "type of val", typeof val);
            finalError = finalError + prop + ": " + val;
          }

          this.msgErrorAddUsers = finalError;
        });
    },
    submitEmailEditform() {
      if (
        this.accountEmailEditForm.newEmail !=
        this.accountEmailEditForm.confirmEmail
      ) {
        this.msgErrorEmail = "The two email fields didn't match.";
        return;
      }
      console.log("acount Email form submit click");
      var urlEmailChange = process.env.VUE_APP_BASE_URL + `v1/auth/email/reset/`;
      var reqObj = {
        current_email: this.accountEmailEditForm.currentEmail,
        new_email: this.accountEmailEditForm.newEmail,
        password: this.accountEmailEditForm.currentPassword,
      };
      axios
        .post(urlEmailChange, reqObj)
        .then((res) => {
          console.log("res", res.data);
          this.msgErrorEmail = "";
          this.$notify(
            "success",
            "success",
            "Email changed successfully, please login",
            {
              duration: 3000,
              permanent: false,
            }
          );
          setTimeout(() => this.logout(), 1000);
        })
        .catch((error) => {
          console.log(
            "@@@eror= ",
            error.response.data,
            "datatype",
            typeof error.response.data
          );
          this.msgErrorEmail = error.response.data;
          if (
            error.response.data.new_email != undefined &&
            error.response.data.new_email.length != 0
          ) {
            this.msgErrorEmail = error.response.data.new_email[0];
          }
          if (
            error.response.data.non_field_errors != undefined &&
            error.response.data.non_field_errors.length != 0
          ) {
            this.msgErrorEmail = error.response.data.non_field_errors[0];
          }
          if (
            error.response.data.detail != undefined &&
            error.response.data.detail.length != 0
          ) {
            this.msgErrorEmail = error.response.data.detail;
          }
        });
    },
    submitPasswordEditform() {
      console.log("acount password form submit click");
      var urlPassChange = process.env.VUE_APP_BASE_URL + `v1/auth/password/change/`;
      var reqObj = {
        old_password: this.accountEditForm.currentPassword,
        new_password1: this.accountEditForm.newPassword,
        new_password2: this.accountEditForm.confirmPassword,
      };
      axios
        .post(urlPassChange, reqObj)
        .then((res) => {
          console.log("res", res.data);
          this.msgErrorPass = "";
          this.$notify(
            "success",
            "success",
            "Password changed successfully, please login",
            {
              duration: 3000,
              permanent: false,
            }
          );
          setTimeout(() => this.logout(), 1000);
        })
        .catch((error) => {
          console.log("@@@eror= ", error.response.data);
          console.log(
            "@@erro error.response.data.old_password.length",
            error.response.data.old_password
          );
          if (
            error.response.data.old_password != undefined &&
            error.response.data.old_password.length != 0
          ) {
            this.msgErrorPass = error.response.data.old_password[0];
          }
          if (
            error.response.data.new_password2 != undefined &&
            error.response.data.new_password2.length != 0
          ) {
            this.msgErrorPass = error.response.data.new_password2[0];
          }
          if (
            error.response.data.new_password1 != undefined &&
            error.response.data.new_password1.length != 0
          ) {
            this.msgErrorPass = error.response.data.new_password1[0];
          }
        });
    },
  },
};
</script>
