<style scoped>
.chartdiv {
  width: 100%;
  height: 270px;
}
</style>
<template>
  <div class="chartdiv" ref="chartdiv"></div>
</template>
<script>
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
am4core.useTheme(am4themes_animated);
export default {
  name: "DonutPieChart",
  data() {
    return {};
  },
  props: ["data"],
  mounted() {
    this.renderReportChart();
  },
  methods: {
    renderReportChart() {
      let chart = am4core.create(this.$refs.chartdiv, am4charts.PieChart3D);
      chart.hiddenState.properties.opacity = 0;
      chart.legend = new am4charts.Legend();
      chart.data = this.data;
      chart.innerRadius = 50;

      var series = chart.series.push(new am4charts.PieSeries3D());
      series.dataFields.value = "data";
      let label = series.labels.template;
      if (window.innerWidth < 1311) {
        label.maxWidth = 90;
        label.wrap = true;
      }
      series.dataFields.category = "labels";
      series.alignLabels = true;
      series.colors.list = [
        am4core.color("#004445"),
        am4core.color("#3F681C"),
        am4core.color("#FFBB00"),
        am4core.color("#FB6542"),
        am4core.color("#375E97"),
      ];
    },
  },
  beforeDestroy() {
    if (this.chart) {
      this.chart.dispose();
    }
  },
  watch: {
    data: function () {
      if (this.chart) {
        this.chart.dispose();
      }
      this.renderReportChart();
    },
  },
};
</script>
