<style>
.sub-menu-lable {
  width: 155px !important;
  word-break: break-all !important;
}

#app-container .sidebar .sub-menu ul li i {
  font-size: 1.5em !important;
  color: black !important;
}

#app-container .sidebar .main-menu ul li a {
  height: 60px;
  padding: 5px;
}

#app-container .sidebar .main-menu ul li.active:after {
  height: 60px;
}

.sub-tree a {
  height: 30px !important;
  margin: 0 0 0 10px;
  padding: 5px !important;
  color: #212529 !important;
  border-left: 1px solid #f3f3f3;
  border-bottom: 1px solid #f3f3f3;
}

.sub-tree a p {
  margin: 0px;
  color: rgb(225, 64, 151);
  text-align: right;
  padding-right: 8px;
  width: 100%;
}

.sub-tree p:hover {
  background-color: #f8f8f8;
  cursor: pointer;
}

.insight-menu {
  margin: 0 0 0 5px;
  /* border-left: 6px solid #912d87; */
  border-radius: 5px;
  background-color: #fff;
}

.insight-tree-parent {
  height: 45px !important;
  border-top: none;
  border-left: none;
  cursor: default;
  border: 1px solid #f8f8f8;
  color: #212529 !important;
  text-transform: uppercase;
  font-size: 11px !important;
}

.insight-menu li :hover {
  background-color: #ffffff !important;
}

.sub-tree .router-link-exact-active {
  background-color: #f8f8f8;
  border-left: 4px solid #912d87;
  /* border-left: 5px solid #912d87; */
}

#app-container .sidebar .main-menu {
  width: 150px;
  height: calc(100% - 90px);
}
</style>
<template>
  <div
    class="sidebar"
    @mouseenter="isMenuOver = true"
    @mouseleave="isMenuOver = false"
    @touchstart="isMenuOver = true"
  >
    <div class="main-menu menu-hidden menu-sub-hidden">
      <vue-perfect-scrollbar
        class="scroll"
        :settings="{ suppressScrollX: true, wheelPropagation: false }"
      >
        <ul class="list-unstyled">
          <router-link
            :class="{ active: selectedParentMenu === 'dashboards' }"
            v-if="isShowAllTab"
            :to="
              '/app/dashboards/default/' +
              $route.params.domainId +
              '/' +
              $route.params.asrtId
            "
            tag="li"
          >
            <a> Dashboard</a>
          </router-link>
          <!-- <router-link v-if="$route.params.domainId" :class="{ active : selectedParentMenu==='ads.txt' }" :to="'/app/ads.txt/default/'+ $route.params.domainId " tag="li">
                    <a>ads.txt</a>
                </router-link> -->
          <div
            v-if="
              $route.params.domainId != 'null' && $route.params.asrtId != null
            "
          >
            <router-link
              v-if="isShowAllTab"
              :class="{
                active: selectedParentMenu === 'revenue',
              }"
              :to="
                '/app/revenue/default/' +
                $route.params.domainId +
                '/' +
                $route.params.asrtId
              "
              tag="li"
            >
              <a>Revenue</a>
            </router-link>

            <router-link
              v-if="isShowAllTab"
              class="insight-tab"
              :class="{
                active: selectedParentMenu === 'insights',
              }"
              :to="
                '/app/insights/default/' +
                $route.params.domainId +
                '/' +
                $route.params.asrtId
              "
              tag="li"
            >
              <a>Insights</a>
            </router-link>
            <router-link
              class="insight-tab"
              v-if="isShowAllTab"
              :class="{
                active: selectedParentMenu === 'accountDetails',
              }"
              :to="'/app/accountDetails/default/'"
              tag="li"
            >
              <a>Account Details</a>
            </router-link>
            <router-link
              v-if="isShowAllTab"
              class="insight-tab"
              :class="{
                active: selectedParentMenu === 'ads.txt',
              }"
              :to="'/app/ads.txt/default/' + $route.params.domainId"
              tag="li"
            >
              <a>Ads.txt</a>
            </router-link>

            <router-link
              v-if="isShowAllTab"
              class="insight-tab"
              :class="{
                active: selectedParentMenu === 'BrandSafety',
              }"
              :to="'/app/BrandSafety/default/' + $route.params.domainId"
              tag="li"
            >
              <a>Brand Safety</a>
            </router-link>
            <router-link
              class="insight-tab"
              v-if="[401, 3, 204, 355].includes(pubid)"
              :class="{
                active: selectedParentMenu === 'BlockAxt',
              }"
              :to="'/app/BlockAxt/default/' + $route.params.domainId"
              tag="li"
            >
              <a>Block AXT</a>
            </router-link>
            <router-link
              class="insight-tab"
              v-if="[436].includes(pubid)"
              :class="{
                active: selectedParentMenu === 'BlockAxt',
              }"
              :to="'/app/BlockAxt/default/' + $route.params.domainId"
              tag="li"
            >
              <a>Enable AXT</a>
            </router-link>
            <router-link
              v-if="amp_ss_enable && isShowAllTab"
              class="insight-tab"
              :class="{
                active: selectedParentMenu === 'AmpSetup',
              }"
              :to="'/app/ampsetup/default/' + $route.params.domainId"
              tag="li"
            >
              <a>AMP set up</a>
            </router-link>
            <div
              v-if="
                selectedParentMenu != 'revenue' &&
                $route.params.asrtId != 'null' &&
                $route.params.asrtId != null &&
                $route.params.asrtId != 'undefined'
              "
            >
              <div class="insight-menu">
                <li
                  :class="{
                    active: selectedParentMenu === 'demand',
                  }"
                >
                  <a class="insight-tree-parent" href="#demand"> Demand </a>
                  <div
                    class="sub-tree"
                    v-for="(demand, index) in demandMenuList"
                    :key="index"
                  >
                    <router-link
                      :to="
                        '/app/assertive/default/' +
                        demand.key +
                        '/' +
                        $route.params.domainId +
                        '/' +
                        $route.params.asrtId
                      "
                    >
                      <p>{{ demand.name }}</p>
                    </router-link>
                  </div>
                </li>
                <li
                  :class="{
                    active: selectedParentMenu === 'adServer',
                  }"
                >
                  <a class="insight-tree-parent" href="#adServer">
                    Ad Server
                  </a>
                  <div
                    class="sub-tree"
                    v-for="(adMenu, index) in adServerMenuList"
                    :key="index"
                  >
                    <router-link
                      :to="
                        '/app/assertive/default/' +
                        adMenu.key +
                        '/' +
                        $route.params.domainId +
                        '/' +
                        $route.params.asrtId
                      "
                    >
                      <p>{{ adMenu.name }}</p>
                    </router-link>
                  </div>
                </li>
                <li
                  :class="{
                    active: selectedParentMenu === 'implementation',
                  }"
                >
                  <a class="insight-tree-parent" href="#implementation">
                    Implementation
                  </a>
                  <div
                    class="sub-tree"
                    v-for="(imp, index) in implementation"
                    :key="index"
                  >
                    <router-link
                      :to="
                        '/app/assertive/default/' +
                        imp.key +
                        '/' +
                        $route.params.domainId +
                        '/' +
                        $route.params.asrtId
                      "
                    >
                      <p>{{ imp.name }}</p>
                    </router-link>
                  </div>
                </li>
                <li
                  :class="{
                    active: selectedParentMenu === 'custom',
                  }"
                >
                  <a class="insight-tree-parent" href="#custom"> Custom </a>
                  <div
                    class="sub-tree"
                    v-for="(cus, index) in custom"
                    :key="index"
                  >
                    <router-link
                      :to="
                        '/app/assertive/default/' +
                        cus.key +
                        '/' +
                        $route.params.domainId +
                        '/' +
                        $route.params.asrtId
                      "
                    >
                      <p>{{ cus.name }}</p>
                    </router-link>
                  </div>
                </li>
                <li
                  :class="{
                    active: selectedParentMenu === 'user',
                  }"
                >
                  <a class="insight-tree-parent" href="#user"> User </a>
                  <div
                    class="sub-tree"
                    v-for="(us, index) in user"
                    :key="index"
                  >
                    <router-link
                      :to="
                        '/app/assertive/default/' +
                        us.key +
                        '/' +
                        $route.params.domainId +
                        '/' +
                        $route.params.asrtId
                      "
                    >
                      <p>{{ us.name }}</p>
                    </router-link>
                  </div>
                </li>
                <li
                  :class="{
                    active: selectedParentMenu === 'source',
                  }"
                >
                  <a class="insight-tree-parent" shref="#source"> Source </a>
                  <div
                    class="sub-tree"
                    v-for="(src, index) in source"
                    :key="index"
                  >
                    <router-link
                      :to="
                        '/app/assertive/default/' +
                        src.key +
                        '/' +
                        $route.params.domainId +
                        '/' +
                        $route.params.asrtId
                      "
                    >
                      <p>{{ src.name }}</p>
                    </router-link>
                  </div>
                </li>
                <li
                  :class="{
                    active: selectedParentMenu === 'content',
                  }"
                >
                  <a class="insight-tree-parent" href="#content"> Content </a>
                  <div
                    class="sub-tree"
                    v-for="(con, index) in content"
                    :key="index"
                  >
                    <router-link
                      :to="
                        '/app/assertive/default/' +
                        con.key +
                        '/' +
                        $route.params.domainId +
                        '/' +
                        $route.params.asrtId
                      "
                    >
                      <p>{{ con.name }}</p>
                    </router-link>
                  </div>
                </li>
              </div>
            </div>
          </div>

          <div v-else>
            <router-link
              v-if="isShowAllTab"
              :class="{
                active: selectedParentMenu === 'revenue',
              }"
              :to="'/app/revenue/default/'"
              tag="li"
            >
              <a>Revenue</a>
            </router-link>

            <router-link
              v-if="isShowAllTab"
              class="insight-tab"
              :class="{
                active: selectedParentMenu === 'ads.txt',
              }"
              :to="'/app/ads.txt/default/'"
              tag="li"
            >
              <a>Ads.txt</a>
            </router-link>

            <router-link
              v-if="isShowAllTab"
              class="insight-tab"
              :class="{
                active: selectedParentMenu === 'BrandSafety',
              }"
              :to="'/app/BrandSafety/default/'"
              tag="li"
            >
              <a>Brand Safety</a>
            </router-link>
            <router-link
              class="insight-tab"
              v-if="[401, 3, 204, 355].includes(pubid)"
              :class="{
                active: selectedParentMenu === 'BlockAxt',
              }"
              :to="'/app/BlockAxt/default/'"
              tag="li"
            >
              <a>Block AXT</a>
            </router-link>
            <router-link
              class="insight-tab"
              v-if="[436].includes(pubid)"
              :class="{
                active: selectedParentMenu === 'BlockAxt',
              }"
              :to="'/app/BlockAxt/default/'"
              tag="li"
            >
              <a>Enable AXT</a>
            </router-link>
            <!-- <router-link :class="{ active : selectedParentMenu==='ads.txt' }"

:to="'/app/ads.txt/default'"

tag="li">

<a>ads.txt</a>

</router-link> -->
            <router-link
              v-if="isShowAllTab"
              class="insight-tab"
              :class="{
                active: selectedParentMenu === 'insights',
              }"
              :to="'/app/insights/default/'"
              tag="li"
            >
              <a>Insights</a>
              <!-- <div v-if="selectedParentMenu !='revenue'  &&  $route.params.asrtId != 'null' && $route.params.asrtId != null && $route.params.asrtId != 'undefined'">

<div class="insight-menu">

<li :class="{ active : selectedParentMenu==='demand' }">

<a class="insight-tree-parent" href="#demand">

Demand

</a>

<div class="sub-tree" v-for="(demand, index) in demandMenuList" :key=index>

<router-link :to="'/app/assertive/default/' + demand.key + '/' + $route.params.domainId +'/' + $route.params.asrtId">

<p> {{ demand.name }} </p>

</router-link>

</div>

</li>

<li :class="{ active : selectedParentMenu==='adServer' }">

<a class="insight-tree-parent" href="#adServer">

Ad Server

</a>

<div class="sub-tree" v-for="(adMenu, index) in adServerMenuList" :key=index>

<router-link :to="'/app/assertive/default/' + adMenu.key + '/' + $route.params.domainId +'/' + $route.params.asrtId">

<p> {{ adMenu.name }} </p>

</router-link>

</div>

</li>

<li :class="{ active : selectedParentMenu==='implementation' }">

<a  class="insight-tree-parent" href="#implementation">

Implementation

</a>

<div class="sub-tree" v-for="(imp, index) in implementation" :key=index>

<router-link :to="'/app/assertive/default/' + imp.key + '/' + $route.params.domainId +'/' + $route.params.asrtId">

<p> {{ imp.name }} </p>

</router-link>

</div>

</li>

<li :class="{ active : selectedParentMenu==='custom' }">

<a class="insight-tree-parent" href="#custom">

Custom

</a>

<div class="sub-tree" v-for="(cus, index) in custom" :key=index>

<router-link :to="'/app/assertive/default/' + cus.key + '/' + $route.params.domainId +'/' + $route.params.asrtId">

<p> {{ cus.name }} </p>

</router-link>

</div>

</li>

<li :class="{ active : selectedParentMenu==='user' }">

<a class="insight-tree-parent" href="#user">

User

</a>

<div class="sub-tree" v-for="(us, index) in user" :key=index>

<router-link :to="'/app/assertive/default/' + us.key + '/' + $route.params.domainId +'/' + $route.params.asrtId">

<p> {{ us.name }} </p>

</router-link>

</div>

</li>

<li :class="{ active : selectedParentMenu==='source' }">

<a class="insight-tree-parent" shref="#source">

Source

</a>

<div class="sub-tree" v-for="(src, index) in source" :key=index>

<router-link :to="'/app/assertive/default/' + src.key + '/' + $route.params.domainId +'/' + $route.params.asrtId">

<p> {{ src.name }} </p>

</router-link>

</div>

</li>

<li :class="{ active : selectedParentMenu==='content' }">

<a class="insight-tree-parent" href="#content">

Content

</a>

<div class="sub-tree" v-for="(con, index) in content" :key=index>

<router-link :to="'/app/assertive/default/' + con.key + '/' + $route.params.domainId +'/' + $route.params.asrtId">

<p> {{ con.name }} </p>

</router-link>

</div>

</li>

</div>

</div> -->
            </router-link>

            <router-link
              v-if="amp_ss_enable && isShowAllTab"
              :class="{
                active: selectedParentMenu === 'AmpSetup',
              }"
              :to="'/app/ampsetup/default/'"
              tag="li"
            >
              <a>AMP set up</a>
            </router-link>
            <router-link
              v-if="isShowAllTab"
              :class="{
                active: selectedParentMenu === 'contract',
              }"
              :to="'/app/contract/default/'"
              tag="li"
            >
              <a>Account Info</a>
            </router-link>
            <router-link
              class="insight-tab"
              v-if="isShowAllTab"
              :class="{
                active: selectedParentMenu === 'accountDetails',
              }"
              :to="'/app/accountDetails/default/'"
              tag="li"
            >
              <a>Account Details</a>
            </router-link>

            <div
              v-if="
                selectedParentMenu != 'revenue' &&
                $route.params.asrtId != 'null' &&
                $route.params.asrtId != null &&
                $route.params.asrtId != 'undefined'
              "
            >
              <div class="insight-menu">
                <li
                  :class="{
                    active: selectedParentMenu === 'demand',
                  }"
                >
                  <a class="insight-tree-parent" href="#demand"> Demand </a>
                  <div
                    class="sub-tree"
                    v-for="(demand, index) in demandMenuList"
                    :key="index"
                  >
                    <router-link
                      :to="
                        '/app/assertive/default/' +
                        demand.key +
                        '/' +
                        $route.params.domainId +
                        '/' +
                        $route.params.asrtId
                      "
                    >
                      <p>{{ demand.name }}</p>
                    </router-link>
                  </div>
                </li>
                <li
                  :class="{
                    active: selectedParentMenu === 'adServer',
                  }"
                >
                  <a class="insight-tree-parent" href="#adServer">
                    Ad Server
                  </a>
                  <div
                    class="sub-tree"
                    v-for="(adMenu, index) in adServerMenuList"
                    :key="index"
                  >
                    <router-link
                      :to="
                        '/app/assertive/default/' +
                        adMenu.key +
                        '/' +
                        $route.params.domainId +
                        '/' +
                        $route.params.asrtId
                      "
                    >
                      <p>{{ adMenu.name }}</p>
                    </router-link>
                  </div>
                </li>
                <li
                  :class="{
                    active: selectedParentMenu === 'implementation',
                  }"
                >
                  <a class="insight-tree-parent" href="#implementation">
                    Implementation
                  </a>
                  <div
                    class="sub-tree"
                    v-for="(imp, index) in implementation"
                    :key="index"
                  >
                    <router-link
                      :to="
                        '/app/assertive/default/' +
                        imp.key +
                        '/' +
                        $route.params.domainId +
                        '/' +
                        $route.params.asrtId
                      "
                    >
                      <p>{{ imp.name }}</p>
                    </router-link>
                  </div>
                </li>
                <li
                  :class="{
                    active: selectedParentMenu === 'custom',
                  }"
                >
                  <a class="insight-tree-parent" href="#custom"> Custom </a>
                  <div
                    class="sub-tree"
                    v-for="(cus, index) in custom"
                    :key="index"
                  >
                    <router-link
                      :to="
                        '/app/assertive/default/' +
                        cus.key +
                        '/' +
                        $route.params.domainId +
                        '/' +
                        $route.params.asrtId
                      "
                    >
                      <p>{{ cus.name }}</p>
                    </router-link>
                  </div>
                </li>
                <li
                  :class="{
                    active: selectedParentMenu === 'user',
                  }"
                >
                  <a class="insight-tree-parent" href="#user"> User </a>
                  <div
                    class="sub-tree"
                    v-for="(us, index) in user"
                    :key="index"
                  >
                    <router-link
                      :to="
                        '/app/assertive/default/' +
                        us.key +
                        '/' +
                        $route.params.domainId +
                        '/' +
                        $route.params.asrtId
                      "
                    >
                      <p>{{ us.name }}</p>
                    </router-link>
                  </div>
                </li>
                <li
                  :class="{
                    active: selectedParentMenu === 'source',
                  }"
                >
                  <a class="insight-tree-parent" shref="#source"> Source </a>
                  <div
                    class="sub-tree"
                    v-for="(src, index) in source"
                    :key="index"
                  >
                    <router-link
                      :to="
                        '/app/assertive/default/' +
                        src.key +
                        '/' +
                        $route.params.domainId +
                        '/' +
                        $route.params.asrtId
                      "
                    >
                      <p>{{ src.name }}</p>
                    </router-link>
                  </div>
                </li>
                <li
                  :class="{
                    active: selectedParentMenu === 'content',
                  }"
                >
                  <a class="insight-tree-parent" href="#content"> Content </a>
                  <div
                    class="sub-tree"
                    v-for="(con, index) in content"
                    :key="index"
                  >
                    <router-link
                      :to="
                        '/app/assertive/default/' +
                        con.key +
                        '/' +
                        $route.params.domainId +
                        '/' +
                        $route.params.asrtId
                      "
                    >
                      <p>{{ con.name }}</p>
                    </router-link>
                  </div>
                </li>
              </div>
            </div>
          </div>
        </ul>
      </vue-perfect-scrollbar>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import { menuHiddenBreakpoint, subHiddenBreakpoint } from "@/constants/config";
import axios from "axios";
import jquery from "jquery";

export default {
  data() {
    return {
      selectedParentMenu: "",
      isMenuOver: false,
      publisherId: undefined,
      pubuuid: "",
      route: [],
      domainId: "",
      amp_ss_enable: false,
      pubid: "",
      asrtId: "",
      pubRoles: [],
      isShowAllTab: true,
      // showAdstxt: false,
      demandMenuList: [
        {
          name: "Winning bidder",
          key: "bidder",
          url: process.env.VUE_APP_ASSERTIVE_URL + "bidder",
        },
        {
          name: "Highest bidder",
          key: "hb-bidder",
          url: process.env.VUE_APP_ASSERTIVE_URL + "hb-bidder",
        },
        {
          name: "DSP",
          key: "dsp",
          url: process.env.VUE_APP_ASSERTIVE_URL + "dsp",
        },
        {
          name: "Buyer",
          key: "buyer",
          url: process.env.VUE_APP_ASSERTIVE_URL + "buyer",
        },
        {
          name: "Brand",
          key: "brand",
          url: process.env.VUE_APP_ASSERTIVE_URL + "brand",
        },
        {
          name: "Deal",
          key: "deal",
          url: process.env.VUE_APP_ASSERTIVE_URL + "deal",
        },
        {
          name: "Media Type",
          key: "media-type",
          url: process.env.VUE_APP_ASSERTIVE_URL + "media-type",
        },
        {
          name: "Bid",
          key: "bid",
          url: process.env.VUE_APP_ASSERTIVE_URL + "bid",
        },
        {
          name: "Time to respond",
          key: "time-to-respond",
          url: process.env.VUE_APP_ASSERTIVE_URL + "time-to-respond",
        },
      ],
      adServerMenuList: [
        {
          name: "GAM Advertiser",
          key: "advertiser",
          url: process.env.VUE_APP_ASSERTIVE_URL + "advertiser",
        },
        {
          name: "GAM Order",
          key: "order",
          url: process.env.VUE_APP_ASSERTIVE_URL + "order",
        },
        {
          name: "GAM LineItem",
          key: "lineitem",
          url: process.env.VUE_APP_ASSERTIVE_URL + "lineitem",
        },
      ],
      implementation: [
        {
          name: "Placements",
          key: "placements",
          url: "www.test.com",
        },
        {
          name: "Ad Units",
          key: "ad-units",
          url: "www.test.com",
        },
        {
          name: "Sizes",
          key: "sizes",
          url: "www.test.com",
        },
        {
          name: "Prebid Version",
          key: "prebid-version",
          url: "www.test.com",
        },
        {
          name: "Assertive Version",
          key: "assertive-version",
          url: "www.test.com",
        },
      ],
      custom: [
        {
          name: "Timeout",
          key: "timeout",
          url: "www.test.com",
        },
        {
          name: "Layout",
          key: "layout",
          url: "www.test.com",
        },
        {
          name: "User state",
          key: "userstate",
          url: "www.test.com",
        },
      ],
      user: [
        {
          name: "Devices",
          key: "devices",
          url: "www.test.com",
        },
        {
          name: "Operating System",
          key: "operating-system",
          url: "www.test.com",
        },
        {
          name: "Browser",
          key: "browser",
          url: "www.test.com",
        },
        {
          name: "Countries",
          key: "countries",
          url: "www.test.com",
        },
        {
          name: "Regions",
          key: "regions",
          url: "www.test.com",
        },
        {
          name: "Continents",
          key: "continents",
          url: "www.test.com",
        },
        {
          name: "Language",
          key: "languages",
          url: "www.test.com",
        },
      ],
      source: [
        {
          name: "Referrer Type",
          key: "referrer",
          url: "www.test.com",
        },
        {
          name: "Referrer Domain",
          key: "referrer-domain",
          url: "www.test.com",
        },
        {
          name: "Referrer URL",
          key: "referrer-url",
          url: "www.test.com",
        },
        {
          name: "UTM Source",
          key: "utm-source",
          url: "www.test.com",
        },
        {
          name: "UTM Medium",
          key: "utm-medium",
          url: "www.test.com",
        },
        {
          name: "UTM Campaign",
          key: "utm-campaign",
          url: "www.test.com",
        },
        {
          name: "UTM Term",
          key: "utm-term",
          url: "www.test.com",
        },
        {
          name: "UTM Content",
          key: "utm-content",
          url: "www.test.com",
        },
      ],
      content: [
        {
          name: "Domain",
          key: "domain",
          url: "www.test.com",
        },
        {
          name: "Entry Page",
          key: "entry-page",
          url: "www.test.com",
        },
        {
          name: "Content",
          key: "content",
          url: "www.test.com",
        },
      ],
    };
  },
  created() {
    this.pubRoles = this.userRoles;
    console.log("!!userRoles", this.userRoles, "pubRoles", this.pubRoles);
    if (this.pubRoles.includes("Nivid-Block-AXT")) {
      this.isShowAllTab = false;
    }
    this.pubuuid = JSON.parse(localStorage.getItem("publisher"))[0].uuid;
    this.pubid = JSON.parse(localStorage.getItem("publisher"))[0].id;
    console.log("pubid", this.pubid);
    axios
      .get(
        process.env.VUE_APP_BASE_URL +
          `v1/publisher/${this.pubuuid}/amp-self-serve/`
      )
      .then((res) => {
        console.log("res amppage", res);
        this.amp_ss_enable = res.data.amp_ss_enable;
      });

    console.log("res amp sidebar page", this.amp_ss_enable);

    // this.showAdstxt = (this.$route.params.domainId) ? true : false;
  },
  mounted() {
    this.pubRoles = this.userRoles;
    console.log("!!userRoles", this.userRoles, "pubRoles", this.pubRoles);
    if (this.pubRoles.includes("Nivid-Block-AXT")) {
      this.isShowAllTab = false;
    }

    this.publisherId = this.currentUser
      ? this.currentUser[0]["id"]
      : JSON.parse(localStorage.getItem("publisher"))[0]["id"];
    this.selectMenu();
    window.addEventListener("resize", this.handleWindowResize);
    document.addEventListener("click", this.returnSelectedMenu);
    this.handleWindowResize();
    this.getPublisherDomainList();
  },
  beforeDestroy() {
    document.removeEventListener("click", this.returnSelectedMenu);
    window.removeEventListener("resize", this.handleWindowResize);
  },
  methods: {
    ...mapMutations(["changeSideMenuStatus", "addMenuClassname"]),
    selectMenu() {
      const currentParentUrl = this.$route.path
        .split("/")
        .filter((x) => x != "")[1];
      if (currentParentUrl == "revenue" || currentParentUrl == "insights") {
        this.changeSideMenuStatus({
          step: 2,
          classNames: this.menuType,
        });
      }
      if (currentParentUrl != undefined || currentParentUrl != null) {
        this.selectedParentMenu = currentParentUrl.toLowerCase();
      } else {
        this.selectedParentMenu = "dashboards";
      }
      // this.nosubmenu("revenue")
    },
    openSubMenu(e, selectedParent) {
      const currentClasses = this.menuType
        ? this.menuType.split(" ").filter((x) => x != "")
        : "";
      if (!currentClasses.includes("menu-mobile")) {
        if (
          currentClasses.includes("menu-sub-hidden") &&
          (this.menuClickCount == 2 || this.menuClickCount == 0)
        ) {
          this.changeSideMenuStatus({
            step: 3,
            classNames: this.menuType,
          });
        } else if (
          currentClasses.includes("menu-hidden") &&
          (this.menuClickCount == 1 || this.menuClickCount == 3)
        ) {
          this.changeSideMenuStatus({
            step: 2,
            classNames: this.menuType,
          });
        } else if (
          currentClasses.includes("menu-default") &&
          !currentClasses.includes("menu-sub-hidden") &&
          (this.menuClickCount == 1 || this.menuClickCount == 3)
        ) {
          this.changeSideMenuStatus({
            step: 0,
            classNames: this.menuType,
          });
        }
      } else {
        this.addMenuClassname({
          classname: "sub-show-temporary",
          currentClasses: this.menuType,
        });
      }
      this.selectedParentMenu = selectedParent;
      // switch(selectedParent){
      // 	case 'revenue':
      // 		this.$router.push('/app/revenue/default');
      // 		break;
      // 	case 'insights':
      // 		this.$router.push('/app/insights/default');
      // 		break;
      // }
    },
    addEvents() {
      document.addEventListener("click", this.handleDocumentClick);
      document.addEventListener("touchstart", this.handleDocumentClick);
    },
    removeEvents() {
      document.removeEventListener("click", this.handleDocumentClick);
      document.removeEventListener("touchstart", this.handleDocumentClick);
    },
    returnSelectedMenu() {
      if (!this.isMenuOver) {
        this.selectMenu();
      }
    },
    // eslint-disable-next-line no-unused-vars
    handleDocumentClick(e) {
      if (!this.isMenuOver) {
        this.toggle();
      }
    },
    // nosubmenu(test){
    // if(test==='dashboards'){
    // const currentClasses = this.menuType.split(" ").filter(x => x != "");
    // ////console.log("test" + test);
    // ////console.log(this.selectedParentMenu);
    // if (this.selectedParentMenu==test){
    // 	jquery('#app-container').addClass("menu-sub-hidden")
    // }
    // else{
    // 	jquery('#app-container').removeClass("menu-sub-hidden")
    // }
    // }
    // },
    toggle() {
      const currentClasses = this.menuType.split(" ").filter((x) => x != "");
      if (
        currentClasses.includes("menu-sub-hidden") &&
        this.menuClickCount == 3
      ) {
        this.changeSideMenuStatus({
          step: 2,
          classNames: this.menuType,
        });
      } else if (
        currentClasses.includes("menu-hidden") ||
        currentClasses.includes("menu-mobile")
      ) {
        this.changeSideMenuStatus({
          step: 0,
          classNames: this.menuType,
        });
      }
    },
    //Resize
    handleWindowResize(event) {
      if (event && !event.isTrusted) {
        return;
      }
      let nextClasses = this.getMenuClassesForResize(this.menuType);
      this.changeSideMenuStatus({
        step: 0,
        classNames: nextClasses.join(" "),
      });
    },
    getMenuClassesForResize(classes) {
      let nextClasses = classes.split(" ").filter((x) => x != "");
      const windowWidth = window.innerWidth;

      if (windowWidth < menuHiddenBreakpoint) {
        nextClasses.push("menu-mobile");
      } else if (windowWidth < subHiddenBreakpoint) {
        nextClasses = nextClasses.filter((x) => x != "menu-mobile");
        if (
          nextClasses.includes("menu-default") &&
          !nextClasses.includes("menu-sub-hidden")
        ) {
          nextClasses.push("menu-sub-hidden");
        }
      } else {
        nextClasses = nextClasses.filter((x) => x != "menu-mobile");
        if (
          nextClasses.includes("menu-default") &&
          nextClasses.includes("menu-sub-hidden")
        ) {
          nextClasses = nextClasses.filter((x) => x != "menu-sub-hidden");
        }
      }
      return nextClasses;
    },
    //Change Default Menu Type
    changeDefaultMenuType(containerClassnames) {
      let nextClasses = this.getMenuClassesForResize(containerClassnames);
      this.changeSideMenuStatus({
        step: 0,
        classNames: nextClasses.join(" "),
      });
    },
    getPublisherDomainList() {
      // this.unauthorizedAccess();
      return this.listOfDomains;
      // axios.post(process.env.VUE_APP_BASE_URL + 'reports/v1/pub-get-dash-info/', {
      //         'publisherId': this.publisherId
      //     })
      //     .then(response => {
      //         if (response.data.status) {
      //             this.listOfDomains = response.data.data;
      //         }
      //     })
      //     .catch(e => {
      //         this.unauthorizedAccess();
      //     });
    },
    domainNameFormatter(domainName) {
      return domainName
        .replace("http://", "")
        .replace("https://", "")
        .replace("/", "");
    },
    unauthorizedAccess() {
      this.$notify("error", "Unauthorized Access", "Contact admin for help!", {
        duration: 3000,
        permanent: false,
      });
      localStorage.removeItem("default_auth_token");
      localStorage.removeItem("publisher");
      this.$router.push("/user/login");
    },
    closeSubMenu() {
      jquery("#app-container").removeClass("sub-show-temporary");
    },
  },
  computed: {
    ...mapGetters({
      menuType: "getMenuType",
      menuClickCount: "getMenuClickCount",
      listOfDomains: "allDomains",
      currentUser: "currentUser",
      userRoles: "userRoles",
    }),
  },
  watch: {
    menuType: function (val) {
      if (val.indexOf("show-temporary") > -1) {
        this.addEvents();
      } else {
        this.removeEvents();
      }
    },
    $route(to, from) {
      console.log("to", to, "from", from);
      if (to.path !== from.path) {
        ////console.log("Change state");
        // this.showAdstxt = (this.$route.params.domainId) ? true : false;
        this.changeSideMenuStatus({
          step:
            to.path.indexOf("revenue") > -1 ||
            to.path.indexOf("insights") > -1 ||
            to.path.indexOf("demand") > -1
              ? 3
              : 0,
          classNames: this.menuType,
        });
        this.selectMenu();
        window.scrollTo(0, top);
        // if(to.path.indexOf('revenue')>-1 || to.path.indexOf('insights')>-1) jquery("#app-container").removeClass('sub-show-temporary');
        // if((from.path.indexOf('revenue')>-1 && to.path.indexOf('revenue')>-1) || (from.path.indexOf('insights')>-1 && to.path.indexOf('insights')>-1)) jquery("#app-container").removeClass('sub-show-temporary');
      }
    },
    publisherId: function () {
      this.getPublisherDomainList();
    },
  },
};
</script>
