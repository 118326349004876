<style scoped>
label {
    font-size: 13px;
    color: #424242;
    font-weight: 900;
}

.btn-color-mode-switch{
    display: inline-block;
    margin: 0px;
    position: relative;
}

.btn-color-mode-switch > label.btn-color-mode-switch-inner{
    margin: 0px;
    width: 140px;
    height: 30px;
    background: #E0E0E0;
    border-radius: 26px;
    overflow: hidden;
    position: relative;
    transition: all 0.3s ease;
    display: block;
}

.btn-color-mode-switch > label.btn-color-mode-switch-inner:before{
    content: attr(data-on);
    position: absolute;
    font-size: 12px;
    font-weight: 500;
    top: 6px;
    right: 20px;

}

.btn-color-mode-switch > label.btn-color-mode-switch-inner:after{
    content: attr(data-off);
    width: 70px;
    height: 25px;
    background: #fff;
    border-radius: 26px;
    position: absolute;
    left: 2px;
    top: 2px;
    text-align: center;
    line-height: 1.2;
    transition: all 0.3s ease;
    box-shadow: 0px 0px 6px -2px #111;
    padding: 5px 0px;
}


.btn-color-mode-switch input[type="checkbox"]{
    cursor: pointer;
    width: 50px;
    height: 25px;
    opacity: 0;
    position: absolute;
    top: 0;
    z-index: 1;
    margin: 0px;
}

.btn-color-mode-switch input[type="checkbox"]:checked + label.btn-color-mode-switch-inner{
    background: #E0E0E0;
}

.btn-color-mode-switch input[type="checkbox"]:checked + label.btn-color-mode-switch-inner:after{
    content: attr(data-on);
    left: 68px;
    background: #fff;
}

.btn-color-mode-switch input[type="checkbox"]:checked + label.btn-color-mode-switch-inner:before{
    content: attr(data-off);
    right: auto;
    left: 20px;
}

.loader {
    border: 5px solid #f3f3f3;
    border-radius: 50%;
    /* border-top: 16px solid blue; */
    /* border-right: 16px solid green; */
    border-bottom: 5px solid green;
    width: 25px;
    height: 25px;
    -webkit-animation: spin-data-v-1eb20100 2s linear infinite;
    animation: spin-data-v-1eb20100 2s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
</style>
<template>
    <div>
        <b-row>
            <b-colxx xxs="12" lg="12" md="12">
                <h1>Account Information</h1>
                <!-- <button type="success" @click="visibleclick"></button> -->
                <hr />
                <b-card class="mt-4" no-body>
                    <b-card-body>
                        <div>
                            <h5><b>Contract</b></h5>
                            <a :href="signpdf" v-if="signpdf" download class="btn btn-success"
                                style="background: blue; color: white"><i class="fa fa-download"></i>Download Contract</a>
                            <label v-else style="color: black"><b style="color: red">Contract is currently not available
                                    online for this
                                    publisher</b></label>
                        </div>
                    </b-card-body>
                </b-card>
                <b-card class="mt-4" no-body>
                    <b-card-body>
                        <div>
                            <h5><b>Tax Form</b></h5>
                            <button type="success" class="btn btn-success" @click="visibleclick">
                                Upload Tax Form
                            </button>
                            <a :href="taxpdf" v-if="taxpdf" download class="btn btn-success"
                                style="background: blue; color: white">Download Tax Form</a>
                            <a href="#" v-else class="btn btn-success"
                                style="background: blue; color: white; cursor: not-allowed"
                                @click="notavailabletaxform">Download Tax Form</a>
                            <template>
                                <modal name="contractupload">
                                    <div class="text-center p-5">
                                        <!-- <i class="fa fa-file" aria-hidden="true"> -->
                                        <file-upload class="btn btn-primary" accept="application/pdf"
                                            style="cursor: pointer !important" post-action="v1/upload-tax-docs-nivid/"
                                            :drop="true" :drop-directory="true" @click="uploadclick" v-model="files"
                                            ref="upload">
                                            Select file
                                            <i class="fas fa-cloud-upload-alt mr-2" aria-hidden="true"></i>
                                        </file-upload>
                                        <h4>
                                            or<br />
                                            Drop files to upload(only PDF,maxlimit=20MB)
                                        </h4>
                                        <!-- </i> -->
                                    </div>
                                    <div class="upload" style="position: relative">
                                        <ul v-if="files.length">
                                            <li v-for="(file, index) in files" :key="file.id">
                                                <span>{{ file.name }}</span>
                                                <!-- <span>{{file.size | formatSize}}</span> - -->
                                                <span v-if="file.error">{{ file.error }}</span>
                                                <span v-else-if="file.success">success</span>
                                                <span v-else-if="file.active">active</span>
                                                <span v-else></span>
                                            </li>
                                        </ul>
                                        <div class="example-btn" style="
                                            float: right;
                                            position: absolute;
                                            top: 39px;
                                            left: 49%;
                                        ">
                                            <button type="button" class="btn btn-danger" @click="stopvisiblity" style="
                                                background: white;
                                                color: black;
                                                border: 1px solid black;
                                            ">
                                                <i class="fa fa-stop" aria-hidden="true"></i>
                                                Cancel
                                            </button>
                                            <button v-if="!isupload" type="button" class="btn btn-success"
                                                @click="uploadclick">
                                                <i class="fa fa-arrow-up" aria-hidden="true"></i>
                                                Upload Tax Form
                                            </button>
                                            <button v-else type="button" class="btn btn-success" @click="uploadclick">
                                                <i class="fa fa-arrow-up" aria-hidden="true"></i>
                                                Uploading...
                                            </button>
                                        </div>
                                    </div>
                                </modal>
                            </template>
                        </div>
                    </b-card-body>
                </b-card>
                <b-card class="mt-4" no-body>
                    <b-card-body>
                        <div>
                            <h5><b>Publisher Payment Form - Automatad</b></h5>
                            <div style="margin-top: 20px">
                                <form class="form-horizontal" v-on:submit.prevent="submitbankform">
                                    <b-tabs v-model="paymentType" card>
                                        <b-tab title="ACH Payment Type" title-link-class="linkClass(0)">
                                            <div class="form-group" id="ach-phone">
                                                <label>Phone Number</label><span class="required-span">*</span>
                                                <input type="text" class="form-control"
                                                    v-validate="(ACHPaymentType) && 'required'"
                                                    name="ACH Phone Number" v-model="bankFormData.ach.achPhoneNumber"
                                                    :disabled="isSubmitBankForm" :placeholder="[[isSubmitBankForm ? bankFormData.ach.achPhoneNumber : 'Phone Number'],
                                                    ]" />
                                                <span v-show="errors.has('ACH Phone Number')" style="color: red">{{
                                                    errors.first("ACH Phone Number") }}</span>
                                            </div>
                                            <div class="form-group" id="ach-beneficiary-name">
                                                <label>Beneficiary Name (official Company Name as registered with your
                                                    bank)</label><span class="required-span">*</span>
                                                <input type="text" class="form-control"
                                                    v-validate="ACHPaymentType && 'required'" name="ACH Beneficiary Name"
                                                    v-model="bankFormData.ach.achBeneficiaryName"
                                                    :disabled="isSubmitBankForm" :placeholder="[[isSubmitBankForm ? bankFormData.ach.achBeneficiaryName : 'Beneficiary Name'],
                                                    ]" />
                                                <span v-show="errors.has('ACH Beneficiary Name')" style="color: red">{{
                                                    errors.first("ACH Beneficiary Name") }}</span>
                                            </div>
                                            <div class="form-group" id="ach-beneficiary-address">
                                                <label>Beneficiary Address (with City, Country and Zipcode):</label><span
                                                    class="required-span">*</span>
                                                <input type="text" class="form-control"
                                                    v-validate="ACHPaymentType && 'required'" name="ACH Beneficiary Address"
                                                    v-model="bankFormData.ach.achBeneficiaryAddress"
                                                    :disabled="isSubmitBankForm" :placeholder="[[isSubmitBankForm ? bankFormData.ach.achBeneficiaryAddress : 'Beneficiary Address'],
                                                    ]" />
                                                <span v-show="errors.has('ACH Beneficiary Address')" style="color: red">{{
                                                    errors.first("ACH Beneficiary Address") }}</span>
                                            </div>
                                            <div class="form-group" id="ach-bank-name">
                                                <label>Bank Name</label><span class="required-span">*</span>
                                                <input type="text" class="form-control"
                                                    v-validate="ACHPaymentType && 'required'" name="ACH Beneficiary Bank Name"
                                                    v-model="bankFormData.ach.achBeneficiaryBankName"
                                                    :disabled="isSubmitBankForm" :placeholder="[[isSubmitBankForm ? bankFormData.ach.achBeneficiaryBankName : 'Beneficiary Bank Name'],
                                                    ]" />
                                                <span v-show="errors.has('ACH Beneficiary Bank Name')" style="color: red">{{
                                                    errors.first("ACH Beneficiary Bank Name") }}</span>
                                            </div>
                                            <div class="form-group" id="ach-bank-address">
                                                <label>Bank Address (with City, Country and Zipcode)</label><span
                                                    class="required-span">*</span>
                                                <input type="text" class="form-control"
                                                    v-validate="ACHPaymentType && 'required'" name="ACH Bank Address"
                                                    v-model="bankFormData.ach.achBankAddress" :disabled="isSubmitBankForm"
                                                    :placeholder="[[isSubmitBankForm ? bankFormData.ach.achBankAddress : 'Bank Address'],
                                                    ]" />
                                                <span v-show="errors.has('ACH Bank Address')" style="color: red">{{
                                                    errors.first("ACH Bank Address") }}</span>
                                            </div>
                                            <div class="form-group" id="ach-routing-number">
                                                <label>Routing Number</label><span class="required-span">*</span>
                                                <input type="text" class="form-control"
                                                    v-validate="ACHPaymentType && 'required|alpha_num'"
                                                    name="ACH Routing Number" v-model="bankFormData.ach.achRoutingNumber"
                                                    :disabled="isSubmitBankForm" :placeholder="[[isSubmitBankForm ? bankFormData.ach.achRoutingNumber : 'Routing Number'],
                                                    ]" />
                                                <span v-show="errors.has('ACH Routing Number')" style="color: red">{{
                                                    errors.first("ACH Routing Number") }}</span>
                                            </div>
                                            <div class="form-group" id="ach-account-number">
                                                <label>Account Number</label><span class="required-span">*</span>
                                                <input type="text" class="form-control"
                                                    v-validate="ACHPaymentType && 'required|alpha_num'"
                                                    name="ACH Benefificary Account Number"
                                                    v-model="bankFormData.ach.achBeneficiaryAccNum"
                                                    :disabled="isSubmitBankForm" :placeholder="[[isSubmitBankForm ? bankFormData.ach.achBeneficiaryAccNum : 'Beneficiary Account Number'],
                                                    ]" />
                                                <span v-show="errors.has('ACH Benefificary Account Number')" style="color: red">{{
                                                    errors.first("ACH Benefificary Account Number") }}</span>
                                            </div>

                                            <div class="form-group" id="ach-account-type">
                                                <label>Account Type</label><span class="required-span">*</span>
                                                <select v-model="bankFormData.ach.achAccountType"
                                                    class="form-select form-control" aria-label="Default select"
                                                    name="ACH Account Type"
                                                    v-validate="ACHPaymentType && 'required|alpha_dash'"
                                                    :disabled="isSubmitBankForm">
                                                    <option disabled value="">Select type of Account</option>
                                                    <option value="business_checking">Business Checking</option>
                                                    <option value="personal_checking">Personal Checking</option>
                                                    <option value="business_savings">Business Savings</option>
                                                    <option value="personal_savings">Personal Savings</option>
                                                </select>
                                                <span v-show="errors.has('ACH Account Type')" style="color: red">{{
                                                    errors.first("ACH Account Type") }}</span>

                                            </div>
                                            <div class="form-group" id="ach-comments">
                                                <label>Comments</label>
                                                <input type="text" class="form-control" name="comment"
                                                    v-model="bankFormData.ach.achComments" :disabled="isSubmitBankForm"
                                                    :placeholder="[[isSubmitBankForm ? bankFormData.ach.achComments : 'Comments'],
                                                    ]" />
                                            </div>
                                        </b-tab>
                                        <b-tab title="Wire Payment" title-link-class="linkClass(1)">
                                            <div class="form-group">
                                                <label>Beneficiary Account Number</label><span
                                                    class="required-span">*</span>
                                                <input type="text" class="form-control"
                                                    v-validate="WirePaymentType && 'required|alpha_num'"
                                                    name="Wire Beneficiary Account Number"
                                                    v-model="bankFormData.wire.wireBeneficiaryAccNum"
                                                    :disabled="isSubmitBankForm" :placeholder="[[isSubmitBankForm ? bankFormData.wire.wireBeneficiaryAccNum : 'Beneficiary Account number'],
                                                    ]" />
                                                <span v-show="errors.has('Wire Beneficiary Account Number')" style="color: red">{{
                                                    errors.first("Wire Beneficiary Account Number") }}</span>
                                            </div>
                                            <div class="form-group">
                                                <label>Beneficiary Name</label><span class="required-span">*</span>
                                                <input type="text" class="form-control"
                                                    v-validate="WirePaymentType && 'required'"
                                                    v-model="bankFormData.wire.wireBeneficiaryName"
                                                    name="Wire Beneficiary Name" :disabled="isSubmitBankForm" :placeholder="[[isSubmitBankForm ? bankFormData.wire.wireBeneficiaryName : 'Beneficiary Name'],
                                                    ]" />
                                                <span v-show="errors.has('Wire Beneficiary Name')" style="color: red">{{
                                                    errors.first("Wire Beneficiary Name")
                                                }}</span>
                                            </div>
                                            <div class="form-group">
                                                <label>Beneficiary Bank Name</label><span class="required-span">*</span>
                                                <input type="text" class="form-control"
                                                    v-validate="WirePaymentType && 'required'"
                                                    v-model="bankFormData.wire.wireBeneficiaryBankName"
                                                    name="Wire Beneficiary Bank Name" :disabled="isSubmitBankForm"
                                                    :placeholder="[[isSubmitBankForm ? bankFormData.wire.wireBeneficiaryBankName : 'Beneficiary Bank Name'],
                                                    ]" />
                                                <span v-show="errors.has('Wire Beneficiary Bank Name')" style="color: red">{{
                                                    errors.first("Wire Beneficiary Bank Name")
                                                }}</span>
                                            </div>
                                            <div class="form-group">
                                                <label>Beneficiary Address</label><span class="required-span">*</span>
                                                <input type="text" class="form-control" name="Wire Beneficiary Address"
                                                    v-validate="WirePaymentType && 'required'"
                                                    v-model="bankFormData.wire.wireBeneficiaryAddress"
                                                    :disabled="isSubmitBankForm" :placeholder="[[isSubmitBankForm ? bankFormData.wire.wireBeneficiaryAddress : 'Beneficiary Address'],
                                                    ]" />
                                                <span v-show="errors.has('Wire Beneficiary Address')" style="color: red">{{
                                                    errors.first("Wire Beneficiary Address")
                                                }}</span>
                                            </div>
                                            <div class="form-group">
                                                <label>IFSC Code</label>
                                                <input type="text" class="form-control"
                                                    v-model="bankFormData.wire.wireIfscCode" name="Code"
                                                    :disabled="isSubmitBankForm" :placeholder="[[isSubmitBankForm ? bankFormData.wire.wireIfscCode : 'IFSC Code'],
                                                    ]" />
                                            </div>
                                            <div class="form-group">
                                                <label>ABA or SWIFT Code </label><span class="required-span">*</span>
                                                <input type="text" class="form-control"
                                                    v-validate="WirePaymentType && 'required'" name="Wire ABA or SWIFT Code"
                                                    v-model="bankFormData.wire.wireAbaOrSwiftCode"
                                                    :disabled="isSubmitBankForm" :placeholder="[[isSubmitBankForm ? bankFormData.wire.wireAbaOrSwiftCode : 'ABA or SWIFT Code'],
                                                    ]" />
                                                <span v-show="errors.has('Wire ABA or SWIFT Code')" style="color: red">{{
                                                    errors.first("Wire ABA or SWIFT Code") }}</span>
                                            </div>
                                            <div class="form-group">
                                                <label>Bank City and State</label><span class="required-span">*</span>
                                                <input type="text" class="form-control"
                                                    v-validate="WirePaymentType && 'required'" name="Wire Bank City and State"
                                                    v-model="bankFormData.wire.wireBankCityAndState"
                                                    :disabled="isSubmitBankForm" :placeholder="[[isSubmitBankForm ? bankFormData.wire.wireBankCityAndState : 'Bank City and State'],
                                                    ]" />
                                                <span v-show="errors.has('Wire Bank City and State')" style="color: red">{{
                                                    errors.first("Wire Bank City and State")
                                                }}</span>
                                            </div>
                                            <div class="form-group">
                                                <label>Bank Country</label><span class="required-span">*</span>
                                                <input type="text" class="form-control"
                                                    v-validate="WirePaymentType && 'required'" name="Wire Bank Country"
                                                    v-model="bankFormData.wire.wireBankCountry" :disabled="isSubmitBankForm"
                                                    :placeholder="[[isSubmitBankForm ? bankFormData.wire.wireBankCountry : 'Bank Country'],
                                                    ]" />
                                                <span v-show="errors.has('Wire Bank Country')" style="color: red">{{
                                                    errors.first("Wire Bank Country")
                                                }}</span>
                                            </div>
                                            <div class="form-group">
                                                <label>Comments</label>
                                                <input type="text" class="form-control" name="comment"
                                                    v-model="bankFormData.wire.wireComments" :disabled="isSubmitBankForm"
                                                    :placeholder="[[isSubmitBankForm ? bankFormData.wire.wireComments : 'Comments'],
                                                    ]" />
                                            </div>
                                        </b-tab>
                                    </b-tabs>
                                    <div class="form-group">
                                        <label class="switch btn-color-mode-switch">
                                            <label>Preferred Payment Type</label>
                                            <input name="Preferred Payment Type" type="checkbox" id="color_mode" value="1" v-model="preferredPaymentType" :disabled="isSubmitBankForm">
                                            <label for="color_mode" data-on="WIRE" data-off="ACH" class="btn-color-mode-switch-inner"></label>
                                        </label>
                                    </div>
                                    <button type="submit" class="btn btn-primary"
                                        :class="[isSubmitBankForm ? 'cursortype' : 'cursorpo']"
                                        :disabled="isSubmitBankForm">
                                        Submit
                                    </button>
                                    <span style="color: red" v-if="isSubmitBankForm">If You Want To Change Any Of
                                        Above
                                        Information,Please
                                        Contact Automatad Team</span>
                                </form>
                            </div>
                        </div>
                    </b-card-body>
                </b-card>
            </b-colxx>
        </b-row>
    </div>
</template>
<script>
// import Element from 'element-ui'
import axios from "axios";
import FileUpload from "vue-upload-component";
import { snakeCase, camelCase } from "lodash";
export default {
    components: {
        FileUpload,
    },
    data() {
        return {
            paymentType: 0,
            preferredPaymentType: false,
            files: [],
            isupload: false,
            visiblescreen: false,
            taxpdf: "",
            signpdf: "",
            isSubmitBankForm: false,
            bankFormData: {
                wire: {
                    wireBeneficiaryAccNum: "",
                    wireBeneficiaryName: "",
                    wireBeneficiaryAddress: "",
                    wireBeneficiaryBankName: "",
                    wireBankCityAndState: "",
                    wireBankCountry: "",
                    wireAbaOrSwiftCode: "",
                    wireIfscCode: "",
                    wireComments: ""
                },
                ach: {
                    achBeneficiaryAccNum: "",
                    achBeneficiaryName: "",
                    achBeneficiaryAddress: "",
                    achBeneficiaryBankName: "",
                    achAccountType: "",
                    achPhoneNumber: "",
                    achBankAddress: "",
                    achRoutingNumber: "",
                    achComments: ""
                }
            },
        };
    },
    computed: {
        ACHPaymentType: function () {
            return this.paymentType === 0 || !this.preferredPaymentType
        },
        WirePaymentType: function () {
            return this.paymentType === 1 || this.preferredPaymentType
        }
    },
    watch: {
        paymentType: function () {
            this.$validator.reset();
        }, 
        preferredPaymentType: function(){
            if(!this.preferredPaymentType)
                this.paymentType = 0
            else if (this.preferredPaymentType)
                this.paymentType = 1
            this.$validator.validateAll();
        }
    },
    created() {
        this.getcontractdetail();
        this.getBankFormInfo();
    },
    methods: {
        getBankFormInfo() {
            var api = process.env.VUE_APP_BASE_URL + 'v1/publisher-payament-details/';
            axios.get(api)
                .then(response => {
                    let obj = response.data.data;
                    if (obj.status) {
                        this.isSubmitBankForm = [336, 1].includes(obj.publisher) ? false : true;
                        for(let i in obj){
                            if(i == 'preferred_payment_type'){
                                this.paymentType = obj.preferred_payment_type == 'ach' ? 0 : 1;
                                this.preferredPaymentType = obj.preferred_payment_type == 'wire'
                            } else if(i.startsWith('ach')){
                                this.bankFormData.ach[camelCase(i)] = obj[i]
                            } else {
                                this.bankFormData.wire[camelCase(i)] = obj[i]
                            }
                        }
                    }
                })
        },
        submitbankform() {
            var api = process.env.VUE_APP_BASE_URL + 'v1/publisher-payament-details/';
            this.$validator.validateAll().then((result) => {
                if (result) {
                    let preferredPaymentType = this.preferredPaymentType ? 'wire' : 'ach';
                    let apiRequest = {}
                    for (let achKey in this.bankFormData.ach) {
                        apiRequest[snakeCase(achKey)] = this.bankFormData.ach[achKey] || "";
                    }
                    for (let wireKey in this.bankFormData.wire) {
                        apiRequest[snakeCase(wireKey)] = this.bankFormData.wire[wireKey] || "";
                    }

                    apiRequest.preferred_payment_type = preferredPaymentType

                    axios.post(api, apiRequest)
                        .then(response => {
                            this.$notify(
                                "success",
                                "success",
                                "create successful", {
                                duration: 2000,
                                permanent: false,
                            }
                            );
                            this.getBankFormInfo();
                        }).catch(error => {
                            this.$notify(
                                "error",
                                "error",
                                "Something went wrong", {
                                duration: 2000,
                                permanent: false,
                            }
                            );
                        })
                    return;
                }

                this.$notify(
                    "error",
                    "error",
                    "Please correct errors!", {
                    duration: 2000,
                    permanent: false,
                });
            }).catch(errors => {
                console.log(errors);
            });
        },
        notavailabletaxform() {
            this.$notify('error', 'error', "Tax forms for this publisher are currently not available online", {
                duration: 3000,
                permanent: false
            });
        },
        visibleclick() {
            this.files = [];
            this.$modal.show('contractupload');
            this.visiblescreen = true;
        },
        stopvisiblity() {
            this.$modal.hide('contractupload');
            this.visiblescreen = false;
        },
        uploadclick() {
            if (this.files.length == 0) {
                this.$notify('error', 'error', "please select file", {
                    duration: 3000,
                    permanent: false
                });
            }
            this.$modal.show('contractupload');

            var api = process.env.VUE_APP_BASE_URL + 'v1/upload-tax-docs-nivid/';

            let formData = new FormData();

            formData.append('taxFile', this.files[0].file);
            console.log("@@uploading", this.uploading);
            this.isupload = true;
            axios.post(api,
                formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
                .then(response => {
                    this.isupload = false;
                    this.taxpdf = response.data.tax_pdf;
                    this.$notify('success', 'success', "uploaded", {
                        duration: 3000,
                        permanent: false
                    });

                    this.$modal.hide('contractupload');
                    return;
                })
                .catch(e => {
                    this.isupload = false;
                    this.$notify('error', 'upload fail', {
                        duration: 3000,
                        permanent: false
                    });
                    this.uploading = false;

                })

        },
        getcontractdetail() {
            axios.get(process.env.VUE_APP_BASE_URL + 'v1/nivid-miscellaneous/')
                .then(response => {
                    if (response.data[0]) {
                        this.taxpdf = response.data[0].tax_pdf;
                        this.signpdf = response.data[0].signed_pdf;
                    }
                })
                .catch(e => {
                    //console.log(e);
                });
        },
        getcontractdetail() {
            axios
                .get(process.env.VUE_APP_BASE_URL + "v1/nivid-miscellaneous/")
                .then((response) => {
                    if (response.data[0]) {
                        this.taxpdf = response.data[0].tax_pdf;
                        this.signpdf = response.data[0].signed_pdf;
                    }
                })
                .catch((e) => {
                    //console.log(e);
                });
        },
    },
};
</script>