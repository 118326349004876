<template>
  <b-row class="h-100">
    <b-colxx xxs="12" md="10" class="mx-auto my-auto">
      <b-card class="auth-card" no-body>
        <div class="position-relative image-side">
          <p class="text-white h2">Your fullstack programmatic solution.</p>
          <p class="white mb-0">
            Solutions include Header Bidding, PrivateMarket Places (PMPs) and
            other proprietary products.
          </p>
        </div>
        <div class="form-side">
          <router-link tag="a" to="/"><span class="logo-single" /></router-link>
          <h6 class="mb-4">{{ $t("user.login-title") }}</h6>
          <b-form @submit.prevent="formSubmit">
            <label class="form-group has-float-label mb-4">
              <input type="email" class="form-control" v-model="email" />
              <span>{{ $t("user.email") }}</span>
            </label>
            <label class="form-group has-float-label mb-4">
              <input type="password" class="form-control" v-model="password" />
              <span>{{ $t("user.password") }}</span>
            </label>
            <div class="d-flex justify-content-between align-items-center">
              <router-link tag="a" to="/user/forgot-password">{{
                $t("user.forgot-password-question")
              }}</router-link>
              <b-button
                type="submit"
                variant="primary"
                size="lg"
                class="btn-shadow"
                :disabled="processing"
                >{{ $t("user.login-button") }}</b-button
              >
            </div>
          </b-form>
        </div>
      </b-card>
    </b-colxx>
  </b-row>
</template>
<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import { store } from "@/store";
export default {
  data() {
    return {
      email: "",
      password: "",
    };
  },
  created: function () {
    this.$nextTick(function () {
      if (this.$auth.check() == true) {
        // redirect: {path: '/app/dashboards'}
      }
    });
  },
  computed: {
    ...mapMutations(["setUser"]),
    ...mapGetters(["currentUser", "processing", "loginError"]),
  },
  methods: {
    formSubmit() {
      var redirect = this.$auth.redirect();
      var credentials = {
        email: this.email,
        password: this.password,
      };
      this.$auth.login({
        data: credentials,
        url: process.env.VUE_APP_BASE_URL + "v1/auth/login/",
        fetchUser: false,
        success: function (response) {
          if (response.status === 200) {
            if (
              response.data.hasOwnProperty("publisher") &&
              response.data.publisher
            ) {
              localStorage.setItem(
                "publisher",
                JSON.stringify(response.data.publisher)
              );
              // localStorage.setItem('user', JSON.stringify(response.data.user));
              this.$notify(
                "success",
                "Loggedin Successfully.",
                "Welcome Back.",
                { duration: 3000, permanent: false }
              );
              store.commit("setUser");
              store.commit("setUserRoles", response.data.user.roles);

              // redirect: {path: '/app/dashboards/default'}

              console.log("!!!!auth", this.$auth);
              console.log("!!!!redirect", redirect);
              console.log("!! this.$auth.user()", this.$auth.user());

              var redirectTo = "/app/dashboards/default";
              const isHinduPublisher = true;

              // if (isHinduPublisher) {
              //     redirectTo = "/app/BlockAxt/default";
              // }
              if (response.data.user.roles.indexOf("Nivid-Block-AXT") > -1)
                this.$router.push({ name: "BlockAxt" });
              else this.$router.push({ name: "dashboards" });

              console.log("!!redirectTo", redirectTo);

              // this.$router.push({ path: redirectTo });
            } else {
              this.$notify(
                "error",
                "Unauthorized Access",
                "Contact admin for help!",
                { duration: 3000, permanent: false }
              );
            }
          } else {
            this.$notify(
              "warning",
              "Wrong Credentials",
              "Unable to login, the credentials are wrong.",
              { duration: 3000, permanent: false }
            );
            redirect: {
              path: "/";
            }
          }
        },
        error: function () {
          this.$notify(
            "warning",
            "Wrong Credentials",
            "Unable to login, the credentials are wrong.",
            { duration: 3000, permanent: false }
          );
        },
      });
    },
  },
  watch: {
    currentUser(val) {
      // if(val&& val.uid && val.uid.length>0){
      // 	setTimeout(()=>{
      // 		this.$router.push('/')
      // 	},500)
      // }
    },
    loginError(val) {
      if (val != null) {
        this.$notify("error", "Login Error", val, {
          duration: 3000,
          permanent: false,
        });
      }
    },
  },
};
</script>
