// import { currentUser } from "@/constants/config";

export default {
  state: {
    currentUser:
      localStorage.getItem("publisher") != null
        ? JSON.parse(localStorage.getItem("publisher"))
        : null,
    loginError: null,
    processing: false,
    userRoles:
      localStorage.getItem("userRoles") != null
        ? JSON.parse(localStorage.getItem("userRoles"))
        : null,
  },
  getters: {
    currentUser: (state) => state.currentUser,
    processing: (state) => state.processing,
    loginError: (state) => state.loginError,
    userRoles: (state) => state.userRoles,
  },
  mutations: {
    setUser(state, payload) {
      // console.trace(payload);
      state.currentUser = payload;
      state.processing = false;
      state.loginError = null;
    },
    setLogout(state) {
      state.currentUser = null;
      state.processing = false;
      state.loginError = null;
    },
    setProcessing(state, payload) {
      state.processing = payload;
      state.loginError = null;
    },
    setError(state, payload) {
      state.loginError = payload;
      state.currentUser = null;
      state.processing = false;
    },
    clearError(state) {
      state.loginError = null;
    },
    setUserRoles(state, payload) {
      localStorage.setItem("userRoles", JSON.stringify(payload));
      state.userRoles = payload;
    },
  },
  actions: {
    login({ commit }, payload) {
      commit("clearError");
      commit("setProcessing", true);
    },
    signOut({ commit }) {
      localStorage.removeItem("user");
      commit("setLogout");
    },
  },
};
