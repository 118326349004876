<style scoped>
.chartdiv {
  width: 100%;
  height: 500px;
}
</style>
<template>
  <div class="chartdiv" ref="chartdiv"></div>
</template>
<script>
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import am4themes_dataviz from "@amcharts/amcharts4/themes/dataviz";

am4core.useTheme(am4themes_animated);

export default {
  name: "ReportsChart",
  data() {
    return {};
  },
  props: ["data"],
  computed: {
    chartData: function () {
      return this.data;
    },
  },
  mounted() {
    this.renderReportChart();
  },
  methods: {
    renderReportChart() {
      let chart = am4core.create(this.$refs.chartdiv, am4charts.XYChart);
      chart.paddingRight = 20;
      chart.data = this.data;

      chart.colors.list = [
        am4core.color("#845EC2"),
        am4core.color("#D65DB1"),
        am4core.color("#FF6F91"),
        am4core.color("#FF9671"),
        am4core.color("#FFC75F"),
        am4core.color("#F9F871"),
      ];

      var dateAxis = chart.xAxes.push(new am4charts.DateAxis());
      dateAxis.renderer.grid.template.location = 0;
      dateAxis.renderer.minGridDistance = 50;
      // dateAxis.responsive.enabled = true;

      var valueAxis1 = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis1.title.text = "Impressions";

      var valueAxis2 = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis2.title.text = "Revenue";
      valueAxis2.renderer.opposite = true;
      valueAxis2.renderer.grid.template.disabled = true;

      var series1 = chart.series.push(new am4charts.ColumnSeries());
      series1.dataFields.valueY = "impression";
      series1.dataFields.dateX = "date";
      series1.yAxis = valueAxis1;
      series1.name = "Impressions";
      series1.tooltipText = "{name}\n[bold font-size: 20]{valueY}[/]";
      series1.fill = chart.colors.getIndex(0);
      series1.strokeWidth = 0;
      series1.clustered = false;
      series1.columns.template.width = am4core.percent(40);

      var series3 = chart.series.push(new am4charts.LineSeries());
      series3.dataFields.valueY = "revenue";
      series3.dataFields.dateX = "date";
      series3.name = "Revenue";
      series3.strokeWidth = 2;
      series3.tensionX = 0.7;
      series3.yAxis = valueAxis2;
      series3.tooltipText = "{name}\n[bold font-size: 20]{currency}{valueY}[/]";

      var bullet3 = series3.bullets.push(new am4charts.CircleBullet());
      bullet3.circle.radius = 3;
      bullet3.circle.strokeWidth = 2;
      bullet3.circle.fill = am4core.color("#fff");

      chart.cursor = new am4charts.XYCursor();

      chart.legend = new am4charts.Legend();
      chart.legend.position = "top";

      // chart.scrollbarX = new am4charts.XYChartScrollbar();
      // chart.scrollbarX.series.push(series1);
      // chart.scrollbarX.series.push(series3);
      // chart.scrollbarX.parent = chart.bottomAxesContainer;
    },
  },
  beforeDestroy() {
    if (this.chart) {
      this.chart.dispose();
    }
  },
  watch: {
    data: function () {
      if (this.chart) {
        this.chart.dispose();
      }
      this.renderReportChart();
    },
  },
};
</script>
