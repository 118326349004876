<template>
  <div>
    <b-row>
      <b-colxx xxs="12" lg="12" md="12">
        <h1>ads.txt</h1>

        <hr />
        <b-card class="mt-4" no-body>
          <b-card-body>
            <div v-if="isselect">
              <pre id="divClipboard">{{ bidders }}</pre>
              <div>
                <button
                  v-if="!iscopied"
                  @click="copyClipboard()"
                  type="button"
                  class="btn btn-primary"
                >
                  Copy To Clipboard
                </button>
                <button
                  v-else
                  @click="copyClipboard()"
                  type="button"
                  class="btn btn-primary"
                >
                  Copied
                </button>
              </div>
            </div>
            <div v-else>
              <div style="color: red">
                Please select a domain to access its ads.txt entries
              </div>
            </div>
          </b-card-body>
        </b-card>
      </b-colxx>
    </b-row>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      domid: "",
      // bidders: [],
      bidders: "",
      iscopied: false,
      isselect: true,
    };
  },
  created() {
    this.domid = this.$route.params.domainId;
    this.getData();
  },
  methods: {
    getData() {
      //console.log("plz select domain");
      //console.log("plz select domain",this.domid);

      if (this.domid == undefined) {
        //console.log("plz select domain");
        this.isselect = false;
        return;
      }

      var api = process.env.VUE_APP_BASE_URL + `v1/ads-txt-publisher-nivid/domain/${this.domid}/`;
      axios.get(api).then((res) => {
        // ////console.log("res", res);
        this.bidders = res.data.data;
        //    this.bidders=this.bidders.replace(/None/g, '');
        // ////console.log(this.bidders);
      });
    },
    copyClipboard() {
      var elm = document.getElementById("divClipboard");
      // for Internet Explorer

      if (document.body.createTextRange) {
        var range = document.body.createTextRange();
        range.moveToElementText(elm);
        range.select();
        document.execCommand("Copy");
        // alert("Copied to Clipboard");
        this.iscopied = true;
      } else if (window.getSelection) {
        // other browsers

        var selection = window.getSelection();
        var range = document.createRange();
        range.selectNodeContents(elm);
        selection.removeAllRanges();
        selection.addRange(range);
        document.execCommand("Copy");
        this.iscopied = true;
        // alert("Copied to Clipboard");
      }
    },
  },
};
</script>
