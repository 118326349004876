<template>
  <b-card no-body>
    <b-card-body>
      <!-- <p class="mb-0 label" style="display: inline;">{{labelx}}</p>
        <h4 style="float:right;display: inline;">{{title}}
          <h6 class="text-small">latest report date {{latestReportDate}}</h6>
        </h4>

      <p class="lead color-theme-1 mb-1 value">{{labelPrefix}}{{labely}}</p>
      <p class="color-theme-2 mb-1 value">Impressions {{labelz}}</p>
      <div class="chart">
        <small-line-chart :data="data" :height="60" @on-chart-mouse-over="onChartMouseOver"/>
      </div> -->

      <span style="float: right; text-align: right">
        <h5>Total Revenue</h5>
        <p class="lead color-theme-1 mb-1 value" style="margin-top: -5px">
          {{ labelPrefix }} {{ totalRevenue }}
        </p>
        <p class="color-theme-2 mb-1 value" style="margin-top: -5px">
          Impressions {{ totalImpression }}
        </p>
      </span>

      <span>
        <h5 style="display: inline">
          {{ title }}
          <h6 class="text-small">latest report date {{ latestReportDate }}</h6>
        </h5>
      </span>

      <div class="chart">
        <small-line-chart
          :data="data"
          @on-chart-mouse-over="onChartMouseOver"
          style="height: 120px"
        />
      </div>

      <div style="margin-top: 99px">
        <p style="display: inline; font-weight: bold">{{ labelx }} /</p>
        <span style="display: inline">
          <p style="display: inline" class="color-theme-1">
            {{ labelPrefix }}{{ labely }} /
          </p>
          <p class="color-theme-2" style="display: inline">
            Impressions {{ labelz }}
          </p>
        </span>
      </div>
    </b-card-body>
  </b-card>
</template>
<script>
import SmallLineChart from "@/components/Charts/SmallLine";

export default {
  props: [
    "labelPrefix",
    "data",
    "title",
    "latestReportDate",
    "RevenueTotal",
    "ImpressionTotal",
  ],
  components: {
    SmallLineChart,
  },
  data() {
    return {
      labelx: "",
      labely: "",
      labelz: "",
      totalRevenue: "",
      totalImpression: "",
    };
  },
  mounted() {
    (this.totalRevenue = this.convertToFormat(this.RevenueTotal.toFixed(3))),
      (this.totalImpression = this.convertToFormat(this.ImpressionTotal));
  },
  methods: {
    onChartMouseOver({ labelx, labely, labelz }) {
      this.labelx = labelx;
      this.labely = labely.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      this.labelz = labelz.toString(10).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },

    convertToFormat(originalVal) {
      return originalVal.toString(10).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
  },
};
</script>
