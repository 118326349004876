import axios from "axios";

const state = {
  allDomains: [],
  publisherId: "",
  latestReportDate: "",
};
const getters = {
  allDomains: (state) => state.allDomains,
  publisherId: (state) => state.publisherId,
  latestReportDate: (state) =>
    state.latestReportDate == ""
      ? localStorage.getItem("ltRD")
      : state.latestReportDate,
};
const mutations = {
  allDomainsMutation(state, payload) {
    state.allDomains = payload;
  },
  publisherId(state, payload) {
    state.publisherId = payload;
  },
  latestReportDateMutation(state, payload) {
    localStorage.setItem("ltRD", payload);
    state.latestReportDate = payload;
  },
};
const actions = {
  allDomainsAction({ commit }, allDomains) {
    axios
      .post(process.env.VUE_APP_BASE_URL + "reports/v1/pub-get-dash-info/")
      .then((response) => {
        if (response.data.status) {
          commit("allDomainsMutation", response.data.data);
        }
        // if (response.data.status) {
        // 	if(domain==null){
        // 		this.allDomains = "".concat(response.data.data.map(k=>" "+k.domain_name))
        // 	}
        // 	else{
        // 		this.allDomains = "".concat(response.data.data.filter(k=>{return domain==k.domain_id})[0].domain_name)
        // 	}
        // 	return this.allDomains
        // }
      })
      .catch((e) => {
        console.log(e);
      });
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
