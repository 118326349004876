module.exports = {
  request: function (req, token) {
    this.options.http._setHeaders.call(this, req, {
      Authorization: "JWT " + token,
    });
  },
  response: function (res) {
    if (res.data.token) {
      return res.data.token;
    }
  },
};
