<style scoped>
.dashboard-line-chart {
  height: 500px;
}

.dashboard-small-chart {
  height: 280px;
}

.p-l-5 {
  padding-left: 5px !important;
}

.p-t-10 {
  padding-top: 10px !important;
}

.impression_revenue_switch {
  display: inline-block;
  position: absolute;
  top: 15px;
  left: 125px;
}

.overflow-hidden {
  /* overflow:hidden; */
}

.atd-caps {
  text-transform: capitalize;
}

.pos-absolute.loading {
  position: absolute;
}

.atd-no-data {
  /* display: flex;
		justify-content: center;
		align-items: center; */
  font-size: 20px;
}
</style>
<style>
.atd-geo-report-container rect,
.atd-geo-report-container path {
  pointer-events: none;
}
</style>
<template>
  <div>
    <b-row>
      <b-colxx lg="12" xl="12" class="mb-4">
        <b-row>
          <!-- <b-colxx xxs="12" lg="6" md="6" xl="6" class="mb-4">
						<small-line-chart-card class="dashboard-small-chart" :RevenueTotal= sixMonthRevenueTotal :ImpressionTotal= sixMonthImpressionTotal :title="'Six Months Report'" :latestReportDate="latestReportDate" v-if="sixMonthRevenueImpressionLineChart.labels.length > 0" :label-prefix="publisherCurrencyCd" :data="sixMonthRevenueImpressionLineChart"/>
							<div class="text-center d-flex justify-content-center align-items-center" v-else>
      							<div v-show="timeoutVar" class="loading pos-absolute"></div>
    						</div>
					</b-colxx>
					<b-colxx xxs="12" lg="6" md="6" xl="6" class="mb-4">
						<small-line-chart-card class="dashboard-small-chart" :title="'Seven Days Report'" :RevenueTotal= sevenDayRevenueTotal :ImpressionTotal= sevenDayImpressionTotal :latestReportDate="latestReportDate" v-if="sevenDayRevenueImpressionLineChart.labels.length > 0" :label-prefix="publisherCurrencyCd" :data="sevenDayRevenueImpressionLineChart"/>
							<div class="text-center d-flex justify-content-center align-items-center" v-else>
								<div v-show="timeoutVar" class="loading pos-absolute"></div>
							</div>
					</b-colxx> -->
          <b-colxx xxs="12" lg="4" md="4" xl="4" class="mb-4">
            <icon-card
              :maintitle="'Last Month'"
              :title="lastMonthImpRev.date"
              icon="iconsmind-Calendar-4"
              :impression="lastMonthImpRev.simpleimpression"
              :revenue="lastMonthImpRev.simplerevenue"
              :currency="publisherCurrencyCd"
              :fullrevenue="lastMonthImpRev.revenue"
              :fullimpression="lastMonthImpRev.impression"
            />
          </b-colxx>
          <b-colxx xxs="12" lg="4" md="4" xl="4" class="mb-4">
            <icon-card
              :maintitle="'Month to date'"
              :title="latestMonthImpRev.date"
              icon="iconsmind-Calendar-3"
              :impression="latestMonthImpRev.simpleimpression"
              :revenue="latestMonthImpRev.simplerevenue"
              :currency="publisherCurrencyCd"
              :fullrevenue="latestMonthImpRev.revenue"
              :fullimpression="latestMonthImpRev.impression"
            />
          </b-colxx>
          <b-colxx xxs="12" lg="4" md="4" xl="4" class="mb-4">
            <icon-card
              :maintitle="'Latest date'"
              :title="latestDateImpRev.date"
              icon="iconsmind-Calendar"
              :impression="latestDateImpRev.simpleimpression"
              :revenue="latestDateImpRev.simplerevenue"
              :currency="publisherCurrencyCd"
              :fullrevenue="latestDateImpRev.revenue"
              :fullimpression="latestDateImpRev.impression"
            />
          </b-colxx>
        </b-row>
      </b-colxx>
    </b-row>

    <b-row>
      <b-colxx xl="6" lg="6" md="12" class="mb-4">
        <b-card :title="`Products - `">
          <div class="impression_revenue_switch mb-4">
            <b-button-group v-if="productChartData.length > 0" class="mb-2">
              <b-button
                variant="primary"
                @click="
                  switchImpressionRevenue(
                    responseProductReportData,
                    'impression',
                    'product'
                  )
                "
                :pressed="selectedProductImpression == 'impression'"
                >Impressions</b-button
              >
              <b-button
                variant="primary"
                @click="
                  switchImpressionRevenue(
                    responseProductReportData,
                    'revenue',
                    'product'
                  )
                "
                :pressed="selectedProductImpression == 'revenue'"
                >Revenue</b-button
              >
            </b-button-group>
          </div>
          <div class="dashboard-donut-chart">
            <h6 class="text-small">
              latest report date: {{ latestReportDate }}
            </h6>
            <donut-pie
              v-if="productChartData.length > 0"
              :data="productChartData"
              class="atd-caps"
            ></donut-pie>
            <div
              class="text-center d-flex justify-content-center align-items-center"
              v-else
            >
              <div v-show="timeoutVar" class="loading pos-absolute"></div>
              <div v-show="!timeoutVar" class="atd-no-data">
                No Data Available
              </div>
            </div>
          </div>
        </b-card>
      </b-colxx>
      <b-colxx xl="6" lg="6" md="12" class="mb-4">
        <b-card :title="`Device - `">
          <div class="impression_revenue_switch mb-4">
            <b-button-group v-if="deviceChartData.length > 0" class="mb-2">
              <b-button
                variant="primary"
                @click="
                  switchImpressionRevenue(
                    responseDeviceReportData,
                    'impression',
                    'device'
                  )
                "
                :pressed="selectedDeviceImpression == 'impression'"
                >Impressions</b-button
              >
              <b-button
                variant="primary"
                @click="
                  switchImpressionRevenue(
                    responseDeviceReportData,
                    'revenue',
                    'device'
                  )
                "
                :pressed="selectedDeviceImpression == 'revenue'"
                >Revenue</b-button
              >
            </b-button-group>
          </div>
          <div class="dashboard-donut-chart">
            <h6 class="text-small">
              latest report date: {{ latestReportDate }}
            </h6>
            <pie-chart
              class="atd-caps"
              v-if="deviceChartData.length > 0"
              :data="deviceChartData"
            ></pie-chart>
            <div
              class="text-center d-flex justify-content-center align-items-center"
              v-else
            >
              <div v-show="timeoutVar" class="loading pos-absolute"></div>
              <div v-show="!timeoutVar" class="atd-no-data">
                No Data Available
              </div>
            </div>
          </div>
        </b-card>
      </b-colxx>
    </b-row>
  </div>
</template>
<script>
import axios from "axios";
import VueAxios from "vue-axios";
import { ThemeColors } from "@/utils";
import moment from "moment";

import { mapGetters, mapMutations, mapActions } from "vuex";

import ReportsChart from "@/components/ReportsChart.vue";
import SmallLineChartCard from "@/components/Cards/SmallLineChartCard";

// import GeoGraph from 'components/GeoReports.vue'
import DonutPie from "@/components/DonutPie.vue";
import PieChart from "@/components/PieChart.vue";
import IconCard from "@/components/Cards/IconCard.vue";

import { currencySymbol } from "@/constants/config";

const colors = ThemeColors();
var humanize = require("humanize-plus");
export default {
  components: {
    SmallLineChartCard,
    // GeoGraph,
    DonutPie,
    PieChart,
    IconCard,
  },
  data() {
    return {
      publisherId: undefined,
      domainId: this.$route.params.domainId
        ? parseInt(this.$route.params.domainId)
        : undefined,
      // geoReports : [],
      dimensionReports: [],
      responseDeviceReportData: [],
      deviceChartData: [],
      responseProductReportData: [],
      productChartData: [],
      pulisherCurrency: "USD",
      publisherCurrencyCd: undefined,
      sevenDayRevenueTotal: 0,
      sevenDayImpressionTotal: 0,
      sixMonthRevenueTotal: 0,
      sixMonthImpressionTotal: 0,
      sevenDayRevenueImpressionLineChart: {
        labels: [],
        datasets: [
          {
            label: "Revenue",
            borderColor: colors.themeColor1,
            pointBorderColor: colors.themeColor1,
            pointHoverBackgroundColor: colors.themeColor1,
            pointHoverBorderColor: colors.themeColor1,
            pointRadius: 2,
            pointBorderWidth: 3,
            pointHoverRadius: 2,
            fill: false,
            borderWidth: 2,
            data: [],
            impressionData: [],
            totalRevenueCount: [],
            totalImpressionCount: [],
            datalabels: {
              align: "end",
              anchor: "end",
            },
          },
        ],
      },
      sixMonthRevenueImpressionLineChart: {
        labels: [],
        datasets: [
          {
            label: "Revenue",
            borderColor: colors.themeColor1,
            pointBorderColor: colors.themeColor1,
            pointHoverBackgroundColor: colors.themeColor1,
            pointHoverBorderColor: colors.themeColor1,
            pointRadius: 2,
            pointBorderWidth: 3,
            pointHoverRadius: 2,
            fill: false,
            borderWidth: 2,
            data: [],
            impressionData: [],
            totalRevenueCount: [],
            totalImpressionCount: [],
            datalabels: {
              align: "end",
              anchor: "end",
            },
          },
        ],
      },
      productsPieColors: [
        "#375E97",
        "#FB6542",
        "#FFBB00",
        "#3F681C",
        "#004445",
      ],
      devicePieColors: ["#004445", "#3F681C", "#FFBB00", "#FB6542", "#375E97"],
      selectedDeviceImpression: "impression",
      selectedProductImpression: "impression",
      timeoutVar: true,
      latestDateImpRev: {
        date: "",
        impression: "0",
        revenue: "0",
        simpleimpression: "0",
        simplerevenue: "0",
      },
      latestMonthImpRev: {
        date: "",
        impression: "0",
        revenue: "0",
        simpleimpression: "0",
        simplerevenue: "0",
      },
      lastMonthImpRev: {
        date: "",
        impression: "0",
        revenue: "0",
        simpleimpression: "0",
        simplerevenue: "0",
      },
    };
  },
  beforeMount() {},
  mounted() {
    this.publisherCurrencyCd =
      currencySymbol[this.pulisherCurrency].symbol_native;
    this.publisherId = this.currentUser
      ? this.currentUser[0]["id"]
      : JSON.parse(localStorage.getItem("publisher"))[0]["id"];
    this.getDashboardData();
    this.getDashboardMonthData();
    this.getDeviceBasedReports();
    this.getProductsImpressionRevenue();
    // this.getGeoBasedReports();
    // console.log(humanize.compactInteger(13141.16, 1))
  },
  computed: {
    ...mapGetters({
      currentUser: "currentUser",
      latestReportDate: "latestReportDate",
    }),
  },
  created: function () {
    this.getUsers();
    setTimeout(() => (this.timeoutVar = false), 2000);
  },
  directives: {
    thousands: {
      // directive definition
      inserted: function (el) {},
      bind: function (el, binding, vnode) {
        // var handler = function(e) {
        // 	let old = e.target.value;
        // 	e.target.value = e.target.value.trim();
        // 	if(old != e.target.value)
        // 		vnode.elm.dispatchEvent(new CustomEvent('input')); // added this
        // };
        // console.log(el);
        // el.addEventListener("input", handler);
      },
    },
  },
  methods: {
    ...mapMutations(["latestReportDateMutation"]),
    addCommas(
      number,
      minDecimalPlaces = 0,
      maxDecimalPlaces = Math.max(3, minDecimalPlaces)
    ) {
      const options = {};
      options.maximumFractionDigits = maxDecimalPlaces;
      options.minimumFractionDigits = minDecimalPlaces;
      return Intl.NumberFormat("en-US", options).format(number);
    },
    getUsers() {
      var getUrlusers = process.env.VUE_APP_BASE_URL + "v1/publisher_user/";
      axios
        .get(getUrlusers)
        .then((res) => {
          localStorage.setItem("isMainUser", "true");
        })
        .catch((error) => {
          if (error.response.status == 403) {
            localStorage.setItem("isMainUser", "false");
          }
        });
    },
    getDashboardData() {
      axios
        .post(process.env.VUE_APP_BASE_URL + "reports/v1/domain-day-revenue/", {
          publisherId: this.publisherId,
          domainId: this.domainId,
        })
        .then((response) => {
          if (response.data.status) {
            var reportData = response.data.data.data,
              i = 0,
              idx = reportData.length;
            this.latestReportDateMutation(
              response.data.data["latest_report_date"]
            );
            this.pulisherCurrency = response.data.data["publisherCurrency"];
            this.publisherCurrencyCd =
              currencySymbol[this.pulisherCurrency].symbol_native;
            // for(; i < idx; i++){
            if (idx > 0) {
              // var dateStr = reportData[1][0]['date'],
              // 	newDt = new Date(dateStr),
              // 	reportDt = newDt.toDateString().split(' ');
              // this.sevenDayRevenueTotal = this.sevenDayRevenueTotal + reportData[i][0]['revenue'];
              // this.sevenDayImpressionTotal = this.sevenDayImpressionTotal + reportData[i][0]['impression'];
              // this.sevenDayRevenueImpressionLineChart['labels'].push(reportDt[1] + reportDt[2]);
              // this.sevenDayRevenueImpressionLineChart['datasets'][0]['data'].push(reportData[i][0]['revenue']);
              // this.sevenDayRevenueImpressionLineChart['datasets'][0]['impressionData'].push(reportData[i][0]['impression']);
              this.latestDateImpRev["date"] = this.latestReportDate.toString();
              this.latestDateImpRev["impression"] = this.addCommas(
                parseInt(reportData[1][0]["impression"])
              );
              this.latestDateImpRev["revenue"] = this.addCommas(
                parseInt(reportData[1][0]["revenue"])
              );
              this.latestDateImpRev["simpleimpression"] =
                humanize.compactInteger(reportData[1][0]["impression"], 1);
              this.latestDateImpRev["simplerevenue"] = humanize.compactInteger(
                reportData[1][0]["revenue"],
                1
              );
              // }
            }
            this.listOfDomains = response.data.data;
          }
        })
        .catch((e) => {
          // this.unauthorizedAccess();
        });
    },
    getDashboardMonthData() {
      var sixMonthRevenueCount = 0;
      var sixMonthImpressionCount = 0;
      axios
        .post(process.env.VUE_APP_BASE_URL + "reports/v1/domain-month-revenue/", {
          publisherId: this.publisherId,
          domainId: this.domainId,
        })
        .then((response) => {
          if (response.data.status) {
            var reportData = response.data.data,
              i = 0,
              idx = reportData.length;
            var cm = response.data.data[2][0].current_month;
            var pm = response.data.data[2][0].previous_month;
            // this.latestMonthImpRev = {};
            // this.lastMonthImpRev = {};
            if (idx > 1) {
              var dateStr = reportData[1][0]["date"];
              // newDt = new Date(dateStr),
              // reportDt = newDt.toDateString().split(' ');
              this.lastMonthImpRev = {
                date: pm,
                impression: this.addCommas(reportData[1][0]["impression"]),
                revenue: this.addCommas(reportData[1][0]["revenue"]),
                simplerevenue: humanize.compactInteger(
                  reportData[1][0]["revenue"],
                  1
                ),
                simpleimpression: humanize.compactInteger(
                  reportData[1][0]["impression"],
                  1
                ),
              };
              var dateStr2 = reportData[2][0]["date"];
              // newDt2 = new Date(dateStr2),
              // reportDt2 = newDt2.toDateString().split(' ');
              this.latestMonthImpRev = {
                date: cm,
                impression: this.addCommas(reportData[2][0]["impression"]),
                revenue: this.addCommas(reportData[2][0]["revenue"]),
                simplerevenue: humanize.compactInteger(
                  parseInt(reportData[2][0]["revenue"]),
                  1
                ),
                simpleimpression: humanize.compactInteger(
                  reportData[2][0]["impression"],
                  1
                ),
              };
            }
            // for(; i < idx; i++){
            // var dateStr = reportData[1][0]['date'],
            // 	newDt = new Date(dateStr),
            // 	reportDt = newDt.toDateString().split(' ');
            // this.sixMonthRevenueTotal = this.sixMonthRevenueTotal + reportData[i][0]['revenue'];
            // this.sixMonthImpressionTotal = this.sixMonthImpressionTotal + reportData[i][0]['impression'];
            // this.sixMonthRevenueImpressionLineChart['labels'].push(reportDt[1] + reportDt[3]);
            // this.sixMonthRevenueImpressionLineChart['datasets'][0]['data'].push(reportData[i][0]['revenue']);
            // this.sixMonthRevenueImpressionLineChart['datasets'][0]['impressionData'].push(reportData[i][0]['impression']);
            // }
            this.listOfDomains = response.data.data;
          }
        })
        .catch((e) => {
          // this.unauthorizedAccess();
        });
    },
    // getGeoBasedReports(){
    // 	axios.post(process.env.VUE_APP_BASE_URL + 'reports/v1/geo-impression-revenue/', {'publisherId' : this.publisherId, 'domainId' : this.domainId})
    // 	.then(response => {
    // 		if (response.data.status) {
    // 			this.geoReports = response.data.data;
    // 		}
    // 	})
    // 	.catch(e => {
    // 		// this.unauthorizedAccess();
    // 	});
    // },
    getDimensionBasedReports() {
      axios
        .post(process.env.VUE_APP_BASE_URL + "reports/v1/dimension-impression-revenue/", {
          publisherId: this.publisherId,
          domainId: this.domainId,
        })
        .then((response) => {
          if (response.data.status) {
            this.dimensionReports = response.data.data;
          }
        })
        .catch((e) => {
          // this.unauthorizedAccess();
        });
    },
    getDeviceBasedReports() {
      axios
        .post(process.env.VUE_APP_BASE_URL + "reports/v1/device-impression-revenue/", {
          publisherId: this.publisherId,
          domainId: this.domainId,
        })
        .then((response) => {
          if (response.data.status) {
            this.responseDeviceReportData = response.data.data;
            this.switchImpressionRevenue(
              response.data.data,
              "impression",
              "device"
            );
          }
        })
        .catch((e) => {
          // this.unauthorizedAccess();
        });
    },
    switchImpressionRevenue(chartData, impOrRev, type) {
      try {
        if (chartData && impOrRev) {
          var i = 0,
            idx = chartData.length;
          type === "product"
            ? (this.productChartData = [])
            : (this.deviceChartData = []);
          for (; i < idx; i++) {
            var chartThemeColor = "themeColor" + (i + 1);
            if (type === "product") {
              this.selectedProductImpression = impOrRev;
              this.productChartData.push({
                labels: chartData[i]["product"],
                data: chartData[i][impOrRev],
                // 'color': this.productsPieColors[i]
              });
            } else if (type === "device") {
              this.selectedDeviceImpression = impOrRev;
              this.deviceChartData.push({
                labels: chartData[i]["device"],
                data: chartData[i][impOrRev],
                // 'color': this.devicePieColors[i]
              });
            }
          }
        }
      } catch (e) {
        console.log(e);
      }
    },
    domainNameFormatter(domainName) {
      return domainName
        .replace("http://", "")
        .replace("https://", "")
        .replace("/", "");
    },
    getProductsImpressionRevenue() {
      axios
        .post(process.env.VUE_APP_BASE_URL + "reports/v1/products-impression-revenue/", {
          publisherId: this.publisherId,
          domainId: this.domainId,
        })
        .then((response) => {
          if (response.data.status) {
            this.responseProductReportData = response.data.data;
            this.switchImpressionRevenue(
              response.data.data,
              "impression",
              "product"
            );
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    unauthorizedAccess() {
      this.$notify("error", "Unauthorized Access", "Contact admin for help!", {
        duration: 3000,
        permanent: false,
      });
      localStorage.removeItem("default_auth_token");
      localStorage.removeItem("publisher");
      this.$router.push("/user/login");
    },
  },
};
</script>
