<template>
  <b-row class="h-100">
    <b-colxx xxs="12" md="10" class="mx-auto my-auto">
      <b-card class="auth-card" no-body>
        <div class="position-relative image-side">
          <p class="text-white h2">
            {{ $t("dashboards.magic-is-in-the-details") }}
          </p>
          <p class="white mb-0">
            Please use your e-mail to reset your password.<br />If you are not a
            member, please
            <router-link tag="a" to="/user/register" class="white"
              >register</router-link
            >.
          </p>
        </div>
        <div class="form-side">
          <router-link tag="a" to="/"><span class="logo-single" /></router-link>
          <h6 class="mb-4">{{ $t("user.forgot-password") }}</h6>
          <b-form @submit.prevent="formSubmit">
            <label class="form-group has-float-label mb-4">
              <input type="email" class="form-control" v-model="email" />
              <span>{{ $t("user.email") }}</span>
            </label>
            <div class="d-flex justify-content-end align-items-center">
              <b-button
                type="submit"
                variant="primary"
                size="lg"
                class="btn-shadow"
                >{{ $t("user.reset-password-button") }}</b-button
              >
            </div>
          </b-form>
        </div>
      </b-card>
    </b-colxx>
  </b-row>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      fullname: "",
      email: "",
      password: "",
    };
  },
  methods: {
    formSubmit() {
      console.log("@@@ calling twice?");
      console.log("email", this.email);
      var URL = process.env.VUE_APP_BASE_URL + "v1/auth/password/reset/nivid/";
      let x = {
        email: this.email,
        activateOrReset: "reset",
      };

      axios
        .post(URL, x)
        .then((response) => {
          console.log("response::", response);
          if (response.data.status) {
          }

          console.log("@@@ calling twice?");
          this.$notify(
            "success",
            "success",
            "Email will sent on your specified email id",
            { duration: 3000, permanent: false }
          );
          setTimeout(() => {
            this.$router.push("/login/");
          }, 1500);
        })
        .catch((error) => {
          this.$notify("Warning", "Warning", "some thing went wrong", {
            duration: 3000,
            permanent: false,
          });
        });
    },
  },
};
</script>
