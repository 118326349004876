import Vue from "vue";
import Vuex from "vuex";

import app from "../main";
import menu from "./modules/menu";
import user from "./modules/user";
import chat from "./modules/chat";
import dashInfo from "./modules/dashInfo";

Vue.use(Vuex);

// import {defaultLocale,localeOptions} from '@/constants/config'
export const store = new Vuex.Store({
  state: {},
  getters: {},
  mutations: {
    changeLang(state, payload) {
      app.$i18n.locale = payload;
      localStorage.setItem("currentLanguage", payload);
    },
  },
  actions: {
    setUser({ commit }, payload) {
      console.log("currentUser", payload);
    },
    setLang({ commit }, payload) {
      commit("changeLang", payload);
    },
  },
  modules: {
    menu,
    user,
    chat,
    dashInfo,
  },
});
