<style scoped>
.dropdown-items {
  padding: 10px;
  display: block;
  width: 100%;
  /* color:inherit; */
}
.dropdownitems {
  padding: 10px;
  display: block;
  width: 100%;
}
.loader {
  border: 5px solid #f3f3f3;
  border-radius: 50%;
  /* border-top: 16px solid blue; */
  /* border-right: 16px solid green; */
  border-bottom: 5px solid green;
  width: 25px;
  height: 25px;
  -webkit-animation: spin-data-v-1eb20100 2s linear infinite;
  animation: spin-data-v-1eb20100 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
<template>
  <div>
    <b-row>
      <b-colxx xxs="12" lg="12" md="12">
        <h1>Amp Setup</h1>

        <b-modal id="modal-1" title="Select Network">
          <p class="my-4">
            Are you sure you want to Select this
            <span
              ><b> '{{ networkObj.display_name }}' </b></span
            >?
          </p>
          <template v-slot:modal-footer>
            <b-button
              class="mt-3"
              variant="outline-danger"
              block
              @click="isSelectNetworkCancel()"
              >Cancel</b-button
            >
            <b-button
              class="mt-3"
              variant="outline-success"
              block
              @click="isSelectNetworkOk()"
              >OK</b-button
            >
          </template>
        </b-modal>
        <hr />
        <b-card class="mt-4" no-body>
          <b-card-body>
            <h5><b>Google Ad Manager</b></h5>
            <div>
              <div v-if="isLoadingconnected_to_dfp">
                <div class="loader"></div>
              </div>
              <div v-else>
                <label v-if="connected_to_dfp" style="color: green">
                  <b>
                    {{ publisherName }}:You have already connected the Google Ad
                    Manager.</b
                  ></label
                >
                <button
                  v-else
                  @click="getData()"
                  type="button"
                  class="btn btn-primary"
                >
                  Connect Google Ad Manager
                </button>
              </div>
            </div>
          </b-card-body>
        </b-card>
        <b-card class="mt-4" no-body v-if="connected_to_dfp">
          <b-card-body v-if="!isloadingGetNetwork">
            <h5><b>Select Network</b></h5>
            <div v-if="networks.length == 0" style="color: red">
              No networks found
            </div>
            <div v-else>
              <b-dropdown
                id="ddown5"
                :text="selectednetwork"
                size="sm"
                variant="primary"
                no-fade="true"
                :disabled="isSelctedNetwork"
              >
                <b-dropdown-item
                  v-for="(network, index) of networks"
                  :key="index"
                  @click="showModalforIselectNetwork(network)"
                  class="dropdown-items"
                >
                  {{ network.display_name }}
                </b-dropdown-item>
              </b-dropdown>
            </div>

            <div v-if="isSelctedNetwork">
              <span style="color: red"
                >If you wish to select a different network, please contact
                <a href="mailto: adops@automatad.com" style="color: blue"
                  >adops@automatad.com</a
                ></span
              >
            </div>
          </b-card-body>
          <b-card-body v-else>
            <div>
              <label style="color: red">
                <b>Next step: Select network.</b></label
              >
            </div>
            <div>
              <label style="color: red"
                >List of networks is being fetched. Please check back
                later.</label
              >
            </div>
          </b-card-body>
        </b-card>
      </b-colxx>
    </b-row>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      selecteddp: "",
      amp_ss_uuid: "",
      connected_to_dfp: false,
      isLoadingconnected_to_dfp: false,
      pubuuid: "",
      networks: [],
      selectednetwork: "select network",
      networkObj: "",
      isSelctedNetwork: false,
      publisherName: "",
      isloadingGetNetwork: false,
      is_dfp_network_fetched: false,
    };
  },
  created() {
    this.amp_ss_uuid = JSON.parse(localStorage.getItem("publisher"))[0].uuid;
    //  this.connected_to_dfp = JSON.parse(localStorage.getItem("publisher"))[0].connected_to_dfp;
    this.pubuuid = JSON.parse(localStorage.getItem("publisher"))[0].uuid;
    this.publisherName = JSON.parse(localStorage.getItem("publisher"))[0].name;
    this.publisherName = this.publisherName.toUpperCase();

    console.log(
      "localStorage.getItem",
      JSON.parse(localStorage.getItem("publisher"))[0].name
    );
    console.log("amp page", this.pubuuid);

    console.log("this.sc", this.$route.query.success);
    console.log("this.sc error", this.$route.query.error);
    this.isLoadingconnected_to_dfp = true;
    axios
      .get(
        process.env.VUE_APP_BASE_URL +
          `v1/publisher/${this.pubuuid}/amp-self-serve/`
      )
      .then((res) => {
        console.log("res amppage", res);
        this.connected_to_dfp = res.data.connected_to_dfp;
        this.isLoadingconnected_to_dfp = false;
        this.is_dfp_network_fetched = res.data.is_dfp_network_fetched;
        if (this.connected_to_dfp) {
          this.isloadingGetNetwork = true;
          console.log("isloadingGetNetwork outside ", this.isloadingGetNetwork);
          axios
            .get(
              process.env.VUE_APP_BASE_URL +
                `v1/publisher/${this.pubuuid}/dfp-network-list/`
            )
            .then((res) => {
              console.log("res of getitems", res);
              this.networks.push(...res.data);
              this.isloadingGetNetwork = false;
              console.log(
                "isloadingGetNetwork inside ",
                this.isloadingGetNetwork
              );
              for (var i = 0; i < this.networks.length; i++) {
                if (this.networks[i].selected_network == true) {
                  this.selectednetwork = this.networks[i].display_name;
                  this.isSelctedNetwork = true;
                  break;
                }
              }
            });
        }
      });
    if (this.$route.query.success && this.$route.query.success != undefined) {
      this.$notify(
        "success",
        "success",
        "Connected to Google Ad Manager account successfully",
        {
          duration: 3000,
          permanent: false,
        }
      );
    }

    if ((this.$route.query.error = 1 && this.$route.query.error != undefined)) {
      this.$notify(
        "error",
        "error",
        "Unable to connected Google Ad Manager account",
        {
          duration: 3000,
          permanent: false,
        }
      );
    }
  },
  methods: {
    isSelectNetworkCancel() {
      this.$bvModal.hide("modal-1");
    },
    showModalforIselectNetwork(networkobj) {
      this.$bvModal.show("modal-1");
      console.log("selected network", networkobj);
      this.networkObj = networkobj;
    },
    isSelectNetworkOk() {
      console.log("selected network", this.networkObj);
      this.selectednetwork = this.networkObj.display_name;
      var reqObj = {
        id: this.networkObj.id,
        selected_network: true,
      };

      axios
        .put(
          process.env.VUE_APP_BASE_URL +
            `v1/publisher/${this.pubuuid}/dfp-network-list/`,
          reqObj
        )
        .then((res) => {
          this.isSelctedNetwork = true;
          this.$bvModal.hide("modal-1");
        });
    },

    getData() {
      var api =
        process.env.VUE_APP_BASE_URL +
        `v1/publishers/${this.amp_ss_uuid}/get_dfp_auth_link/`;
      axios.get(api).then((res) => {
        console.log("amp res", res.data.authorization_url);
        window.location.href = res.data.authorization_url;
      });
    },
  },
};
</script>
