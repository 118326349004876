var render = function render(){var _vm=this,_c=_vm._self._c;return _c('nav',{staticClass:"navbar fixed-top"},[_c('a',{staticClass:"menu-button d-none d-md-block",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.toggleSideMenu()}}},[_c('menu-icon')],1),_c('a',{staticClass:"menu-button-mobile d-xs-block d-sm-block d-md-none",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.changeSideMenuForMobile(_vm.menuType)}}},[_c('mobile-menu-icon')],1),_c('div',{staticClass:"header-icons d-inline-block"},[(_vm.$route.matched[1].path != '/app/AmpSetup')?_c('div',[(_vm.$route.matched[1].path != '/app/contract')?_c('div',[(_vm.$route.matched[1].path != '/app/accountDetails')?_c('div',[_c('b-dropdown',{staticClass:"top-right-button top-right-button-single nivid-site-dropdown",attrs:{"id":"ddown5","text":_vm.selectedDomain,"size":"sm","variant":"outline-primary","no-fade":"true"},on:{"shown":_vm.dropdownOverflowAdjust}},[(
                _vm.$route.matched[1].path == '/app/revenue' && _vm.pubInfo.length > 0
              )?_c('b-dropdown-item',{on:{"click":function($event){return _vm.selectDomain('All Domain')}}},[_c('router-link',{staticClass:"dropdown-update",attrs:{"to":_vm.$route.matched[1].path}},[_vm._v(" All Domains ")])],1):_vm._e(),(
                _vm.$route.matched[1].path == '/app/dashboards' &&
                _vm.pubInfo.length > 0
              )?_c('b-dropdown-item',{on:{"click":function($event){return _vm.selectDomain('All Domain')}}},[_c('router-link',{staticClass:"dropdown-update",attrs:{"to":_vm.$route.matched[1].path}},[_vm._v(" All Domains ")])],1):_vm._e(),(_vm.pubInfo.length == 0)?_c('b-dropdown-item',{staticClass:"dropdown-update"},[_vm._v("No Domains Found")]):_vm._e(),_vm._l((_vm.pubInfo),function(pubs,index){return _c('b-dropdown-item',{key:index,on:{"click":function($event){return _vm.selectDomain(
                  pubs.domain_name,
                  pubs.domain_id,
                  pubs.assertive_yield_id
                )}}},[(pubs.assertive_yield_id != null)?_c('router-link',{staticClass:"dropdown-update",attrs:{"to":_vm.$route.matched[1].path +
                  '/default/' +
                  pubs.domain_id +
                  '/' +
                  pubs.assertive_yield_id}},[_vm._v(" "+_vm._s(pubs.domain_name)+" ")]):_c('router-link',{staticClass:"dropdown-update",attrs:{"to":_vm.$route.matched[1].path + '/default/' + pubs.domain_id}},[_vm._v(" "+_vm._s(pubs.domain_name)+" ")])],1)})],2)],1):_vm._e()]):_vm._e()]):_vm._e()]),_c('router-link',{staticClass:"navbar-logo",attrs:{"tag":"a","to":"/app"}},[_c('span',{staticClass:"logo d-none d-xs-block"}),_c('span',{staticClass:"logo-mobile d-block d-xs-none"})]),_c('div',{staticClass:"ml-auto"},[_c('div',{staticClass:"header-icons d-inline-block align-middle"},[_c('b-button',{staticClass:"header-icon d-none d-sm-inline-block",attrs:{"variant":"empty"},on:{"click":_vm.toggleFullScreen}},[_c('i',{class:{
            'd-block': true,
            'simple-icon-size-actual': _vm.fullScreen,
            'simple-icon-size-fullscreen': !_vm.fullScreen,
          }})])],1),_c('div',{staticClass:"user d-inline-block"},[_c('b-dropdown',{staticClass:"dropdown-menu-right",attrs:{"right":"","variant":"empty","toggle-class":"p-0","menu-class":"mt-3","no-caret":""}},[(_vm.userInfo)?_c('template',{slot:"button-content"},[_c('span',{staticClass:"name mr-1"},[_vm._v(_vm._s(_vm.userInfo["name"]))]),_c('span',[_c('avatar',{attrs:{"username":_vm.userInfo['name'],"size":35,"customStyle":{ display: 'inline-block' }}})],1)]):_vm._e(),_c('b-dropdown-item',{staticClass:"dropdown-update",on:{"click":_vm.logout}},[_vm._v("Sign out")])],2)],1)]),(_vm.reportStatus != undefined && _vm.reportStatus.message !== 'available')?_c('b-alert',{staticClass:"atd-alert-position",attrs:{"variant":"danger","dismissible":""},model:{value:(_vm.showDismissibleAlert),callback:function ($$v) {_vm.showDismissibleAlert=$$v},expression:"showDismissibleAlert"}},[_vm._v(" "+_vm._s(_vm.reportStatus.message)+" ")]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }