import { render, staticRenderFns } from "./contract.vue?vue&type=template&id=0547971a&scoped=true&"
import script from "./contract.vue?vue&type=script&lang=js&"
export * from "./contract.vue?vue&type=script&lang=js&"
import style0 from "./contract.vue?vue&type=style&index=0&id=0547971a&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0547971a",
  null
  
)

export default component.exports